import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {InjectableApplication} from '../../models/injectable-application';
import {PatientService} from '../../services/patient.service';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {InjectableApplicationService} from '../../services/injectable-application.service';
import {Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {MedicamentService} from '../../services/medicament.service';
import {IOption} from 'ng-select';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-injectable-application',
  templateUrl: './injectable-application.component.html',
  styleUrls: ['./injectable-application.component.scss']
})
export class InjectableApplicationComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalCobrar') modalCobrar;
  @ViewChild('modalRetorno') modalRetorno;
  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;

  injectableApplication = {
    paciente: {},
    farmaceutico: {},
    medicamento: {},
    servico: {
      fgCobrado: false
    },
  } as InjectableApplication;
  maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];

  medicamentOption = null;
  medicamentListOption: Array<IOption> = [];
  pacienteByAgenda;

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private medicamentService: MedicamentService,
    private pharmaceuticalService: PharmaceuticalService,
    private injectableApplicationService: InjectableApplicationService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
        if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
          this.patientOption = this.pacienteByAgenda;
        }
      });
    });


    this.authService.getAuthPharmaceutical().subscribe((usuario) => {this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });
    this.medicamentService.getAllActives(true).subscribe((medicamentList) => {
      this.medicamentListOption = [];
      medicamentList.forEach((medicament) => {
        this.medicamentListOption.push({
          value: medicament.idMedicamento + '',
          label: medicament.descricao
        });
      });
    });

    this.serviceTypeService.getOne(ServiceTypeEnum.APLICACAO_INJETAVEL).subscribe((tpServico) => {
      this.injectableApplication.servico.vlCobrado = tpServico.vlServico;

      this.injectableApplication.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
      this.injectableApplication.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
    });
  }

  validateOpen(form) {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {
      this.modalCobrar.show();
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  save(myForm: NgForm) {
    if (myForm.valid) {
      if (!this.pharmaceuticalOption) {
        this.showErrorToast('Informe um farmacêutico');

      } else if (!this.patientOption) {
        this.showErrorToast('Informe um paciente');

      } else if (!this.medicamentOption) {
        this.showErrorToast('Informe um medicamento');

      } else {
        this.injectableApplication.paciente.idPaciente = Number(this.patientOption);
        this.injectableApplication.medicamento.idMedicamento = Number(this.medicamentOption);
        this.injectableApplication.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);

        this.injectableApplicationService.insert(this.injectableApplication).subscribe((injectableApplication) => {
          this.injectableApplication.idAplicacaoInjetavel = injectableApplication.idAplicacaoInjetavel;
          this.modalCobrar.hide();
          this.showToast('Aplicação injetável cadastrada com sucesso');
          if (this.injectableApplication.servico.fgSugerirRetorno) {

            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + this.injectableApplication.servico.qtdDiasRetorno);
            this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
            this.modalRetorno.show({backdrop: 'static'});
          } else {
            this.redirectPaciente();
          }
        });
      }
    }
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }


  private redirectPaciente() {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: this.injectableApplication.paciente.idPaciente,
        pdf: this.injectableApplication.idAplicacaoInjetavel,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.APLICACAO_INJETAVEL,
      }
    }).then();
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.injectableApplication.farmaceutico.idFarmaceutico,
      idPaciente: this.injectableApplication.paciente.idPaciente,
      tpServico: ServiceTypeEnum.APLICACAO_INJETAVEL,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }
}
