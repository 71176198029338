import {Component, OnInit} from '@angular/core';
import {Pharmaceutical} from '../../models/pharmaceutical';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {NgForm} from '@angular/forms';
import {ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {ViacepService} from '../../services/viacep.service';
import {StateService} from '../../services/state.service';
import {ChangePassword} from '../../models/change-password';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-pharmaceutical',
  templateUrl: './pharmaceutical-form.component.html',
  styleUrls: ['./pharmaceutical-form.component.scss']
})
export class PharmaceuticalFormComponent implements OnInit {

  totalFarmaceuticos;
  id: number;
  plano;
  pharmaceutical = {flagAtivo: 1} as Pharmaceutical;
  usuarioLogado = {flagAtivo: 1} as Pharmaceutical;
  changePassword = {
    password: '',
    passwordConfirm: '',
    passwordCurrent: '',
  } as ChangePassword;
  editMode = false as boolean;
  isCurrentAuth = false as boolean;

  public phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private activatedRoute: ActivatedRoute,
    private pharmaceuticalService: PharmaceuticalService,
    private toastyService: ToastyService,
    private viacepService: ViacepService,
    private stateService: StateService,
    private authService: AuthService,
    private router: Router
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });
  }

  ngOnInit() {

    this.pharmaceuticalService.count().subscribe(qtd => {
      this.totalFarmaceuticos = qtd;
    })

    if (this.id !== undefined) {
      this.pharmaceuticalService.getById(this.id).subscribe((pharmaceutical) => {
        this.pharmaceutical = pharmaceutical;
        this.pharmaceutical.cep = pharmaceutical['endereco']['cep'];
        this.pharmaceutical.idEndereco = pharmaceutical['endereco']['idEndereco'];
        this.pharmaceutical.uf = pharmaceutical['endereco']['uf'];
        this.pharmaceutical.estado = pharmaceutical['endereco']['estado'];
        this.pharmaceutical.bairro = pharmaceutical['endereco']['bairro'];
        this.pharmaceutical.cidade = pharmaceutical['endereco']['cidade'];
        this.pharmaceutical.complemento = pharmaceutical['endereco']['complemento'];
        this.pharmaceutical.logradouro = pharmaceutical['endereco']['logradouro'];
        this.pharmaceutical.numero = pharmaceutical['endereco']['numero'];
        this.pharmaceutical.senha = '';


        this.authService.getAuthPharmaceutical().subscribe((pharmaceuticalUser) => {
          console.log(pharmaceuticalUser);
          console.log(this.pharmaceutical)
          this.usuarioLogado = pharmaceuticalUser;
          this.plano = pharmaceuticalUser.empresa.plano;
          this.isCurrentAuth = pharmaceuticalUser.idUsuario == this.pharmaceutical.cdUsuario;
          console.log(this.isCurrentAuth)
        });

      });
    } else {

      this.authService.getAuthPharmaceutical().subscribe((pharmaceuticalUser) => {
        console.log(pharmaceuticalUser);
        console.log(this.pharmaceutical)
        this.usuarioLogado = pharmaceuticalUser;
        this.plano = pharmaceuticalUser.empresa.plano;
        console.log(this.isCurrentAuth)
      });

    }

  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  onInsert() {
    this.pharmaceuticalService.insert(this.pharmaceutical).subscribe((farmaceutico) => {
      this.showToast('Farmacêutico cadastrado com sucesso');

      this.id = farmaceutico.idFarmaceutico;
      this.pharmaceutical.idFarmaceutico = farmaceutico.idFarmaceutico;
      this.pharmaceutical.idEndereco = farmaceutico['endereco']['idEndereco'];
      this.router.navigate(['/farmaceutico/form/edit'], {queryParams: {id: this.id}}).then();

    }, (error) => {
      if (error && error.error && error.error.message) {
        this.showErrorToast(error.error.message);

      } else {
        this.showErrorToast('Não foi possível concluir o cadastro');
      }
    });
  }

  onUpdate() {
    this.pharmaceutical['endereco'] = {
      cep: this.pharmaceutical.cep,
      estado: this.pharmaceutical.estado,
      uf: this.pharmaceutical.uf,
      cidade: this.pharmaceutical.cidade,
      bairro: this.pharmaceutical.bairro,
      idEndereco: this.pharmaceutical.idEndereco,
      complemento: this.pharmaceutical.complemento,
      logradouro: this.pharmaceutical.logradouro,
      numero: this.pharmaceutical.numero,
    };

    this.pharmaceuticalService.update(this.pharmaceutical).subscribe(() => {
      this.showToast('Farmacêutico alterado com sucesso');
    }, (error) => {
      if (error && error.error && error.error.message) {
        this.showErrorToast(error.error.message);

      } else {
        this.showErrorToast('Não foi possível editar o cadastro');
      }
    });
  }

  save(form: NgForm) {
    if (form.valid) {
      if (!this.editMode && this.changePassword.passwordConfirm != this.pharmaceutical.senha) {
        this.showErrorToast('Senhas não correspondem');
        return;
      }

      if (this.pharmaceutical.idFarmaceutico !== undefined) {
        this.onUpdate();

      } else {
        if (this.totalFarmaceuticos >= Number(this.plano.qtdUsuarios)) {
          this.showErrorToast('A quantidade de usuários permitidos no seu plano foi atingida');
          return;
        }
        this.onInsert();
      }
    }
  }

  setIfNotEmptyByViacepAndEmptyByForm(data, column) {
    if (data !== undefined && data.length >= 1) {
      this.pharmaceutical[column] = data;
    }
  }

  onCepChanged() {
    if (this.pharmaceutical.cep !== undefined && this.pharmaceutical.cep.length == 8) {
      this.viacepService.findByCep(this.pharmaceutical.cep).subscribe((viacep) => {
        if (viacep['erro']) {
          this.showErrorToast('CEP não encontrado');
          return false;
        }

        this.setIfNotEmptyByViacepAndEmptyByForm(this.stateService.stateByUf(viacep.uf), 'estado');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.uf, 'uf');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.localidade, 'cidade');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.bairro, 'bairro');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.logradouro, 'logradouro');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.complemento, 'complemento');
      });
    }
  }

  onChangePassword() {
    if (!this.pharmaceutical.senha || this.pharmaceutical.senha.length < 6) {
      this.showErrorToast('Senha deve possuir no mínimo 6 caracteres');
      return;
    }

    if (!this.changePassword.passwordCurrent || this.changePassword.passwordCurrent.length < 1) {
      this.showErrorToast('Informe a senha atual');
      return;
    }

    if (this.changePassword.passwordConfirm == this.pharmaceutical.senha) {
      this.changePassword.password = this.pharmaceutical.senha;
      this.changePassword.usuario = this.usuarioLogado;

      this.pharmaceuticalService.changePassword(this.changePassword).subscribe(() => {
        this.showToast('Senha alterada com sucesso');
      }, () => {
        this.showErrorToast('Senha não alterada');
      });

    } else {
      this.showErrorToast('Senhas não correspondem');
    }
  }

}
