import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CompanyService } from './services/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private companyService: CompanyService,
  ) {

  }

  ngOnInit() {
    console.log('passo aqui')
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.companyService.getOne().subscribe((company) => {

      console.log(company)
      if (!company) {
        this.router.navigate(['/empresa']).then();
      } else {

        if (company && company.flagIsDemo) {
          localStorage.setItem('isDemo', 'true')
        } else {
          localStorage.removeItem('isDemo')
        }

        if (company && company.flagIsDemo && company.flagDemoVencida) {
          this.router.navigate(['/demovencida'])
        }
      }
    });
  }
}
