import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './theme/shared/shared.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NavigationComponent} from './theme/layout/admin/navigation/navigation.component';
import {NavContentComponent} from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavGroupComponent} from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavCollapseComponent} from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavBarComponent} from './theme/layout/admin/nav-bar/nav-bar.component';
import {NavLeftComponent} from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavSearchComponent} from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavRightComponent} from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import {ConfigurationComponent} from './theme/layout/admin/configuration/configuration.component';

import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';

import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/security/login/login.component';
import {PharmaceuticalFormComponent} from './pharmaceutical/pharmaceutical-form/pharmaceutical-form.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastyModule} from 'ng2-toasty';
import {PharmaceuticalListComponent} from './pharmaceutical/pharmaceutical-list/pharmaceutical-list.component';
import {DataTablesModule} from 'angular-datatables';
import {PatientFormComponent} from './patient/patient-form/patient-form.component';
import {PatientListComponent} from './patient/patient-list/patient-list.component';
import {ArchwizardModule} from 'angular-archwizard';
import {SigninComponent} from './signin/signin.component';
import {TextMaskModule} from 'angular2-text-mask';
import {PrescriptionComponent} from './functionality/prescription/prescription.component';
import {PharmaceuticalRecordSoapComponent} from './functionality/pharmaceutical-record-soap/pharmaceutical-record-soap.component';
import {EarringFormComponent} from './complement/earring-form/earring-form.component';
import {NebulizerFormComponent} from './complement/nebulizer-form/nebulizer-form.component';
import {EarringApplicationComponent} from './functionality/earring-application/earring-application.component';
import {TemperatureStatementComponent} from './functionality/temperature-statement/temperature-statement.component';
import {EquipmentFormComponent} from './complement/equipment-form/equipment-form.component';
import {InjectableApplicationComponent} from './functionality/injectable-application/injectable-application.component';
import {BioimpedanceEvaluationComponent} from './functionality/bioimpedance-evaluation/bioimpedance-evaluation.component';
import {CholesterolDeclarationComponent} from './functionality/cholesterol-declaration/cholesterol-declaration.component';
import {GlucoseStatementComponent} from './functionality/glucose-statement/glucose-statement.component';
import {BloodPressureStatementComponent} from './functionality/blood-pressure-statement/blood-pressure-statement.component';
import {MedicationManagementComponent} from './functionality/medication-management/medication-management.component';
import {NebulizationComponent} from './functionality/nebulization/nebulization.component';
import {PharmaceuticalRecordAtfComponent} from './functionality/pharmaceutical-record-atf/pharmaceutical-record-atf.component';
import {MedicamentFormComponent} from './complement/medicament-form/medicament-form.component';
import {CompanyFormComponent} from './company/company-form/company-form.component';
import {SelectModule} from 'ng-select';
import {JwtInterceptor} from './services/jwtinterceptor';
import {ErrorInterceptor} from './services/errorinterceptor';
import {ConfigsComponent} from './configs/configs.component';
import {DemoVencidaComponent} from './demo-vencida/demo-vencida.component';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {AgendaModule} from './agenda/agenda.module';
import {LoaderModule} from './components/loader/loader.module';
import {NgxMaskModule} from "ngx-mask";
import {DndDirective} from "./directives/dnd.directive";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFabMenuModule} from "@angular-material-extensions/fab-menu";
import {FlexLayoutModule} from "@angular/flex-layout";
import {CustomFormsModule} from "ngx-custom-validators";
import {PrescricaoComplementarListComponent} from "./prescricao/prescricao-list/prescricao-complementar-list.component";
import {PrescricaoComplementarFormComponent} from "./prescricao/prescricao-form/prescricao-complementar-form.component";
import {TextInputAutocompleteModule} from "angular-text-input-autocomplete";
import { TesteCovidComponent } from './functionality/teste-covid/teste-covid.component';
import { ReportsComponent } from './reports/reports.component';
import { AtendimentoComponent } from './reports/atendimento/atendimento.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    HomeComponent,
    LoginComponent,
    PharmaceuticalFormComponent,
    PharmaceuticalListComponent,
    PatientFormComponent,
    PatientListComponent,
    SigninComponent,
    DemoVencidaComponent,
    PrescriptionComponent,
    PharmaceuticalRecordSoapComponent,
    EarringFormComponent,
    NebulizerFormComponent,
    EarringApplicationComponent,
    TemperatureStatementComponent,
    EquipmentFormComponent,
    InjectableApplicationComponent,
    BioimpedanceEvaluationComponent,
    CholesterolDeclarationComponent,
    GlucoseStatementComponent,
    BloodPressureStatementComponent,
    MedicationManagementComponent,
    NebulizationComponent,
    PharmaceuticalRecordAtfComponent,
    MedicamentFormComponent,
    CompanyFormComponent,
    ConfigsComponent,
    DndDirective,
    PrescricaoComplementarListComponent,
    PrescricaoComplementarFormComponent,
    TesteCovidComponent,
    ReportsComponent,
    AtendimentoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    ToastyModule,
    DataTablesModule,
    ArchwizardModule,
    TextMaskModule,
    SelectModule,
    AgendaModule,
    LoaderModule,
    NgxMaskModule.forRoot(),
    MatButtonModule,
    MatTooltipModule,
    MatFabMenuModule,
    FlexLayoutModule,
    CustomFormsModule,
    TextInputAutocompleteModule

  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    NavigationItem,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule {
}
