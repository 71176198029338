<form #myForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Declaração de Colesterol" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select
                [ngClass]="{'invalido': !farmaceutico.valid && myForm.submitted}"
                class="ng-select" [options]="pharmaceuticalListOption"
                [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                #farmaceutico="ngModel"></ng-select>

              <label *ngIf="!farmaceutico.valid && myForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select
                [ngClass]="{'invalido': !paciente.valid && myForm.submitted}"
                class="ng-select" required [options]="patientListOption"
                [(ngModel)]="patientOption" name="paciente"
                #paciente="ngModel"></ng-select>

              <label *ngIf="!paciente.valid && myForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label> Horário* </label>
              <input type="text" class="form-control" [(ngModel)]="cholesterolDeclaration.horario" name="horario"
                     [ngClass]="{'is-invalid': !horario.valid && (horario.dirty || myForm.submitted)}"
                     #horario="ngModel" required mask="Hh:m0" minlength="4" [dropSpecialCharacters]="false">
              <label *ngIf="!horario.valid && (horario.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Horário incorreto</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" style="margin-top: 22px; float: right;">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="fgJejum" [(ngModel)]="cholesterolDeclaration.fgJejum" name="fgJejum"
                       #fgJejum="ngModel">
                <label for="fgJejum" class="cr"></label>
              </div>
              <label>Jejum</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label> Horas em jejum </label>
              <input type="text" class="form-control" [(ngModel)]="cholesterolDeclaration.horasJejum" name="horasJejum"
                     #horasJejum="ngModel"
                     [ngClass]="{'is-invalid': !horasJejum.valid && fgJejum.value && (horasJejum.dirty || myForm.submitted)}"
                     [required]="fgJejum.value" [disabled]="!fgJejum.value" mask="Hh">
              <label *ngIf="fgJejum.value && !horasJejum.valid && (horasJejum.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Horas em jejum
                incorreto</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label> Colesterol (mg/dL)* </label>
              <input type="text" class="form-control" [(ngModel)]="cholesterolDeclaration.colesterol" name="colesterol"
                     [ngClass]="{'is-invalid': !colesterol.valid && (colesterol.dirty || myForm.submitted)}"
                     #colesterol="ngModel" required
                     (change)="cholesterolDeclaration.colesterol = colesterol.value.replace(',', '.');">
              <label *ngIf="!colesterol.valid && (colesterol.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Colesterol incorreto</label>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label> HDL-C (mg/dL) </label>
              <input type="text" class="form-control" [(ngModel)]="cholesterolDeclaration.hdlC" name="hdlC"
                     #hdlC="ngModel"
                     (change)="cholesterolDeclaration.hdlC = hdlC.value.replace(',', '.');">
              <label *ngIf="!hdlC.valid && (hdlC.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">HDL-C incorreto</label>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label> LCL-C (mg/dL) </label>
              <input type="text" class="form-control" [(ngModel)]="cholesterolDeclaration.ldlC" name="ldlC"
                     #ldlC="ngModel"
                     (change)="cholesterolDeclaration.ldlC = ldlC.value.replace(',', '.');">
              <label *ngIf="!ldlC.valid && (ldlC.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">LCL-C incorreto</label>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label> Triglicerídeos </label>
              <input type="text" class="form-control" [(ngModel)]="cholesterolDeclaration.triglicerideos"
                     name="triglicerideos" #triglicerideos="ngModel"
                     (change)="cholesterolDeclaration.triglicerideos = triglicerideos.value.replace(',', '.');">
              <label *ngIf="!triglicerideos.valid && (triglicerideos.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Triglicerídeos
                incorreto</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="cholesterolDeclaration.observacao"
                        name="observacoes"
                        id="textAreaObservacoes" rows="8"
                        [ngClass]="{'is-invalid': !observacoes.valid && (observacoes.dirty || myForm.submitted)}"
                        #observacoes="ngModel" maxlength="1024"></textarea>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
  <ng2-toasty position="top-right"></ng2-toasty>
  <button mat-fab color="primary" class="add-button" (click)="validateOpen(myForm)"
          matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Declaração de Colesterol?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="cholesterolDeclaration.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="cholesterolDeclaration.servico.vlCobrado"
               [disabled]="!cholesterolDeclaration.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || myForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="cholesterolDeclaration.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(myForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
