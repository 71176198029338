<form #companyForm="ngForm" class="editForm" novalidate (ngSubmit)="save(companyForm)">
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Empresa" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-1">
            <div class="form-group">
              <label> Código </label>
              <input type="text" class="form-control" [(ngModel)]="company.idEmpresa" name="idEmpresa" disabled>
            </div>
          </div>

          <div class="col-md-11">
            <div class="form-group">
              <label> Nome fantasia* </label>
              <input type="text" class="form-control" [(ngModel)]="company.nomeFantasia" name="nomeFantasia"
                     [ngClass]="{'is-invalid': !nomeFantasia.valid && (nomeFantasia.dirty || companyForm.submitted)}"
                     #nomeFantasia="ngModel" minlength="2"
                     maxlength="256"
                     required>
              <label *ngIf="!nomeFantasia.valid && (nomeFantasia.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Nome fantasia
                incorreto</label>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-md-3">
            <div class="form-group">
              <label> Tipo consultório</label>

              <div style="display: flex">
                <div class="custom-control custom-radio" style="padding-right: 15px; padding-top: 5px;">
                  <input type="radio" class="custom-control-input" id="customControlValidation2"
                         required [(ngModel)]="company.tpConsultorio" [ngModelOptions]="{standalone: true}"
                         [value]="'FARMACIA'">
                  <label class="custom-control-label" for="customControlValidation2">Consultório em farmácia</label>
                </div>
                <div class="custom-control custom-radio mb-3" style="padding-top: 5px;">
                  <input type="radio" [(ngModel)]="company.tpConsultorio"
                         class="custom-control-input" id="customControlValidation3"
                         name="radio-stacked" [ngModelOptions]="{standalone: true}"
                         required [value]="'AUTONOMO'">
                  <label class="custom-control-label" for="customControlValidation3">Consultório autônomo</label>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-2" *ngIf="company.tpConsultorio == 'FARMACIA'">
            <div class="form-group">
              <label> CNPJ* </label>
              <input type="text" class="form-control" [(ngModel)]="company.cnpj" name="cnpj"
                     [ngClass]="{'is-invalid': !cnpj.valid && (cnpj.dirty || companyForm.submitted)}"
                     #cnpj="ngModel" minlength="14"
                     maxlength="14"
                     pattern="[0-9]*"
                     required>
              <label *ngIf="!cnpj.valid && (cnpj.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">CNPJ incorreto</label>
            </div>
          </div>

          <div class="col-md-2" *ngIf="company.tpConsultorio == 'AUTONOMO'">
            <div class="form-group">
              <label> CPF* </label>
              <input type="text" class="form-control" [(ngModel)]="company.cnpj" name="cnpj"
                     [ngClass]="{'is-invalid': !cnpj.valid && (cnpj.dirty || companyForm.submitted)}"
                     #cnpj="ngModel" minlength="11"
                     maxlength="11"
                     pattern="[0-9]*"
                     required>
              <label *ngIf="!cnpj.valid && (cnpj.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">CPF incorreto</label>
            </div>
          </div>

          <div class="col-md-7" *ngIf="company.tpConsultorio == 'FARMACIA'">
            <div class="form-group">
              <label> Razão social* </label>
              <input type="text" class="form-control" [(ngModel)]="company.razaoSocial" name="razaoSocial"
                     [ngClass]="{'is-invalid': !razaoSocial.valid && (razaoSocial.dirty || companyForm.submitted)}"
                     #razaoSocial="ngModel" minlength="2"
                     maxlength="256"
                     required>
              <label *ngIf="!razaoSocial.valid && (razaoSocial.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Nome
                incorreto</label>
            </div>
          </div>
          <div class="col-md-7" *ngIf="company.tpConsultorio == 'AUTONOMO'">
            <div class="form-group">
              <label> Nome completo* </label>
              <input type="text" class="form-control" [(ngModel)]="company.razaoSocial" name="razaoSocial"
                     [ngClass]="{'is-invalid': !razaoSocial.valid && (razaoSocial.dirty || companyForm.submitted)}"
                     #razaoSocial="ngModel" minlength="2"
                     maxlength="256"
                     required>
              <label *ngIf="!razaoSocial.valid && (razaoSocial.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Nome
                incorreto</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> E-mail* </label>
              <input type="text" class="form-control" [(ngModel)]="company.email" name="email"
                     [ngClass]="{'is-invalid': !email.valid && (email.dirty || companyForm.submitted)}"
                     #email="ngModel" maxlength="128" minlength="5" required>
              <label *ngIf="!email.valid && (email.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">E-mail incorreto</label>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label> Telefone </label>
              <input type="text" class="form-control" [(ngModel)]="company.telefoneFixo" name="telefoneFixo"
                     [ngClass]="{'is-invalid': !telefoneFixo.valid && (telefoneFixo.dirty || companyForm.submitted)}"
                     #telefoneFixo="ngModel" [textMask]="{mask: maskTelefoneResidencial}">
              <label *ngIf="!telefoneFixo.valid && (telefoneFixo.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Telefone</label>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label> Celular </label>
              <input type="text" class="form-control" [(ngModel)]="company.telefoneCelular" name="telefoneCelular"
                     [ngClass]="{'is-invalid': !telefoneCelular.valid && (telefoneCelular.dirty || companyForm.submitted)}"
                     #telefoneCelular="ngModel" [textMask]="{mask: maskTelefoneCelular}">
              <label *ngIf="!telefoneCelular.valid && (telefoneCelular.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Celular incorreto</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Nome responsável </label>
              <input type="text" class="form-control" [(ngModel)]="company.nomeResponsavel" name="nomeResponsavel"
                     [ngClass]="{'is-invalid': !nomeResponsavel.valid && (nomeResponsavel.dirty || companyForm.submitted)}"
                     #nomeResponsavel="ngModel" minlength="5"
                     maxlength="256">
              <label *ngIf="!nomeResponsavel.valid && (nomeResponsavel.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Nome responsável
                incorreto</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label> Contato responsável </label>
              <input type="text" class="form-control" [(ngModel)]="company.contatoResponsavel" name="contatoResponsavel"
                     [ngClass]="{'is-invalid': !contatoResponsavel.valid && (contatoResponsavel.dirty || companyForm.submitted)}"
                     #contatoResponsavel="ngModel" minlength="5"
                     maxlength="256">
              <label *ngIf="!contatoResponsavel.valid && (contatoResponsavel.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Contato responsável
                incorreto</label>
            </div>
          </div>
        </div>

        <h5 class="mt-5">Endereço</h5>
        <hr>
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label> CEP* </label>
              <input type="text" class="form-control" [(ngModel)]="company.endereco.cep" name="cep"
                     (ngModelChange)="onCepChanged()"
                     [ngClass]="{'is-invalid': !cep.valid && (cep.dirty || companyForm.submitted)}"
                     #cep="ngModel" minlength="8"
                     maxlength="8" pattern="[0-9]*"
                     required>
              <label *ngIf="!cep.valid && (cep.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">CEP incorreto</label>
            </div>
          </div>

          <div class="col-md-7">
            <div class="form-group">
              <label> Estado* </label>
              <input type="text" class="form-control" [(ngModel)]="company.endereco.estado" name="estado"
                     [ngClass]="{'is-invalid': !estado.valid && (estado.dirty || companyForm.submitted)}"
                     #estado="ngModel"
                     minlength="4"
                     maxlength="19"
                     required>
              <label *ngIf="!estado.valid && (estado.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um estado</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label> Cidade* </label>
              <input type="text" class="form-control" [(ngModel)]="company.endereco.cidade" name="cidade"
                     [ngClass]="{'is-invalid': !cidade.valid && (cidade.dirty || companyForm.submitted)}"
                     #cidade="ngModel"
                     minlength="2"
                     maxlength="64"
                     required>
              <label *ngIf="!cidade.valid && (cidade.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe uma cidade</label>
            </div>
          </div>

          <div class="col-md-7">
            <div class="form-group">
              <label> Logradouro* </label>
              <input type="text" class="form-control" [(ngModel)]="company.endereco.logradouro" name="logradouro"
                     [ngClass]="{'is-invalid': !logradouro.valid && (logradouro.dirty || companyForm.submitted)}"
                     #logradouro="ngModel"
                     minlength="2"
                     maxlength="128"
                     required>
              <label *ngIf="!logradouro.valid && (logradouro.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um
                logradouro</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-9">
            <div class="form-group">
              <label> Bairro* </label>
              <input type="text" class="form-control" [(ngModel)]="company.endereco.bairro" name="bairro"
                     [ngClass]="{'is-invalid': !bairro.valid && (bairro.dirty || companyForm.submitted)}"
                     #bairro="ngModel"
                     minlength="2"
                     maxlength="128"
                     required>
              <label *ngIf="!bairro.valid && (bairro.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um bairro</label>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label> Número* </label>
              <input type="text" class="form-control" [(ngModel)]="company.endereco.numero" name="numero"
                     [ngClass]="{'is-invalid': !numero.valid && (numero.dirty || companyForm.submitted)}"
                     #numero="ngModel"
                     minlength="0"
                     maxlength="16"
                     required>
              <label *ngIf="!numero.valid && (numero.dirty || companyForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um número</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label> Complemento </label>
              <input type="text" class="form-control" [(ngModel)]="company.endereco.complemento" name="complemento"
                     [ngClass]="{'is-invalid': !complemento.valid && (complemento.dirty || companyForm.submitted)}"
                     #complemento="ngModel"
                     minlength="0"
                     maxlength="128">
            </div>
          </div>
        </div>

        <h5 class="mt-5">Logo</h5>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <img [src]="imageLogo" alt="imageLogo" style="max-width: 200px;">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <br>
            <input type="file" (change)="fileChangeEvent($event)" id="myFileInput" style="display: none"
                   accept="image/png, image/jpeg, image/jpg">
            <button class="btn btn-primary" type="button" (click)="getImage()">
              Carregar
            </button>
            <button class="btn btn-primary" type="button" (click)="removeImage()" style="margin-left: 24px;">
              Remover
            </button>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" matTooltip="Salvar"><i
    class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>


<app-ui-modal #modalCompletarCadastro [containerClick]="false">
  <div class="app-modal-header">
    <h5 class="modal-title">Completar cadastro?</h5>
  </div>
  <div class="app-modal-body">
    <p>Deseja finalizar seu cadastro inicial? Se sim, você será direcionado(a) para completar o cadastro de farmacêutico</p>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="redirect()">Sim</button>

  </div>
</app-ui-modal>
