<form #earringForm="ngForm" class="editForm" novalidate (ngSubmit)="save(earringForm)">
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Marca Brinco" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-1">
            <div class="form-group">
              <label> Código </label>
              <input type="text" class="form-control" [(ngModel)]="earring.idMarcaBrinco" name="idMarcaBrinco"
                     disabled>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" style="padding-top: 30px;">
              <button class="btn btn-primary btn-icon btn-msg-send" type="button"
                      (click)="search(); searchModal.show()">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Marca </label>
              <input type="text" class="form-control" [(ngModel)]="earring.descricaoMarca" name="descricaoMarca"
                     [ngClass]="{'is-invalid': !name.valid && (name.dirty || earringForm.submitted)}"
                     #name="ngModel" minlength="2"
                     maxlength="64"
                     required>
              <label *ngIf="!name.valid && (name.dirty || earringForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Marca Incorreta</label>
            </div>
          </div>
          <div class="custom-control custom-switch switch-ativo col-md-1">
            <input type="checkbox" class="custom-control-input" id="customswitch1" [(ngModel)]="earring.flagAtivo"
                   name="fgAtivo">
            <label class="custom-control-label" for="customswitch1">Ativo</label>
          </div>
        </div>
      </app-card>
    </div>
  </div>
  <button mat-fab color="primary" class="add-button" matTooltip="Salvar"><i
    class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>
<app-ui-modal #searchModal>
  <div class="app-modal-header">
    <h5 class="modal-title">Pesquisa</h5>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <ng-select [ngClass]="'ng-select'" [options]="earringListOption" [(ngModel)]="id" name="earringToSave"
                     #earringToSave="ngModel" required></ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="searchModal.hide()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="edit()">Editar</button>
  </div>
</app-ui-modal>
