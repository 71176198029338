<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Pacientes" cardClass="card-datatable" [options]="false">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table datatable [dtOptions]="patientListConfig"
                   class="table table-striped table-bordered table-hover"></table>
          </div>
        </div>
      </div>

    </app-card>
    <button mat-fab color="primary" class="add-button" [routerLink]="'/paciente/form'" matTooltip="Novo Paciente"><i
      class="material-icons">add</i></button>
  </div>
</div>
