import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {Equipment} from '../models/equipment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  url = GlobalConstants.apiURL + 'equipamento';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(equipment: Equipment): Observable<Equipment> {
    return this.httpClient.post<Equipment>(this.url, equipment, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  update(equipment: Equipment): Observable<Equipment> {
    return this.httpClient.put<Equipment>(this.url + '/' + equipment.idEquipamento, equipment, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(): Observable<Equipment[]> {
    return this.httpClient.get<Equipment[]>(this.url, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllActives(): Observable<Equipment[]> {
    return this.httpClient.get<Equipment[]>(this.url + '/active', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number): Observable<Equipment> {
    return this.httpClient.get<Equipment>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

}
