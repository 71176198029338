<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Farmacêuticos" cardClass="card-datatable" [options]="false">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table datatable [dtOptions]="pharmaceuticalListConfig"
                   class="table table-striped table-bordered table-hover"></table>
          </div>
        </div>
      </div>
    </app-card>

    <button mat-fab color="primary" class="add-button" [routerLink]="'/farmaceutico/form'" matTooltip="Novo Farmacêutico"><i
      class="material-icons">add</i></button>
  </div>
</div>
