import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {PharmaceuticalRecordSoap} from '../models/pharmaceutical-record-soap';

@Injectable({
  providedIn: 'root'
})
export class PharmaceuticalRecordService {

  url = GlobalConstants.apiURL + 'prontuario/farmaceutico';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  getPDFUrlById(id: number): string {
    return this.url + '/' + id + '/pdf';
  }

  insert(pharmaceuticalRecord: PharmaceuticalRecordSoap): Observable<PharmaceuticalRecordSoap> {
    return this.httpClient.post<PharmaceuticalRecordSoap>(this.url, pharmaceuticalRecord, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  findByIdPatient(id: number): Observable<PharmaceuticalRecordSoap[]> {
    return this.httpClient.get<PharmaceuticalRecordSoap[]>(this.url + '/by/paciente/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<PharmaceuticalRecordSoap> {
    return this.httpClient.get<PharmaceuticalRecordSoap>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

}
