<form #myForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card [hidHeader]="true" cardClass="tab-card">
        <h5 class="mb-3">Revisão de Farmacoterapia</h5>
        <hr>
        <ngb-tabset type="pills">
          <ngb-tab title="Dados Paciente" class="mb-3" id="1">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label> Código </label>
                    <input type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.idProtocoloATF"
                           name="idProtocoloATF" disabled>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Farmacêutico* </label>
                    <ng-select [ngClass]="{'invalido': !farmaceutico.valid && myForm.submitted}"
                               class="ng-select" required [options]="pharmaceuticalListOption"
                               [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                               #farmaceutico="ngModel"></ng-select>

                    <label *ngIf="!farmaceutico.valid && myForm.submitted"
                           class="error">Farmacêutico é obrigatório</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Paciente* </label>
                    <ng-select [ngClass]="{'invalido': !paciente.valid && myForm.submitted}"
                               class="ng-select" required [options]="patientListOption"
                               [(ngModel)]="patientOption" name="paciente" (selected)="getPacienteSelecionado()"
                               #paciente="ngModel"></ng-select>

                    <label *ngIf="!paciente.valid && myForm.submitted"
                           class="error">Paciente é obrigatório</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label> Data de nascimento</label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.dataNascimento}}" disabled>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label> Idade</label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.idade}}" disabled>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col-md-4">
                  <div class="form-group">
                    <label> Telefone Celular</label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.telefoneCelular}}" disabled>
                  </div>
                </div>
              </div>

              <h5>Endereço</h5>
              <hr>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label> CEP </label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.endereco?.cep}}" disabled>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label> Estado </label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.endereco?.estado}}" disabled>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label> Cidade </label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.endereco?.cidade}}" disabled>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label> Logradouro </label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.endereco?.cep}}" disabled>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label> Bairro</label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.endereco?.bairro}}" disabled>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label> Número </label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.endereco?.numero}}" disabled>
                  </div>
                </div>
              </div>
              <div class="row">
              </div>
              <div class="row">
                <div class="col-md-11">
                  <div class="form-group">
                    <label> Complemento </label>
                    <input type="text" class="form-control" value="{{pacienteSelecionado?.endereco?.complemento}}"
                           disabled>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Rotina" class="mb-3" id="2">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="textAreaAlimentacao">Alimentação</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.alimentacao"
                              name="alimentacao" id="textAreaAlimentacao" rows="4"
                              [ngClass]="{'is-invalid': !alimentacao.valid && (alimentacao.dirty || myForm.submitted)}"
                              #alimentacao="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="textAtvidadeFisica">Atividade física</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.atividadeFisica"
                              name="atividadeFisica" id="textAtvidadeFisica" rows="4"
                              [ngClass]="{'is-invalid': !atividadeFisica.valid && (atividadeFisica.dirty || myForm.submitted)}"
                              #atividadeFisica="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="textHorariosVagos">Horários</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.horario"
                              name="horario" id="textHorariosVagos" rows="3"
                              [ngClass]="{'is-invalid': !horario.valid && (horario.dirty || myForm.submitted)}"
                              #horario="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="textLazer">Horários de Lazer</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.lazer"
                              name="lazer" id="textLazer" rows="3"
                              [ngClass]="{'is-invalid': !lazer.valid && (lazer.dirty || myForm.submitted)}"
                              #lazer="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Dados Subjetivos" class="mb-3" id="3">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaProblemaSaudeAtual">Problemas de Saúde </label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.problemaSaude"
                              name="problemaSaude" id="textAreaProblemaSaudeAtual" rows="4"
                              [ngClass]="{'is-invalid': !problemaSaude.valid && (problemaSaude.dirty || myForm.submitted)}"
                              #problemaSaude="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaQueixas">Queixas</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.queixa"
                              name="queixa" id="textAreaQueixas" rows="4"
                              [ngClass]="{'is-invalid': !queixa.valid && (queixa.dirty || myForm.submitted)}"
                              #queixa="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaAlergias">Alergias</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.alergia"
                              name="alergia" id="textAreaAlergias" rows="4"
                              [ngClass]="{'is-invalid': !alergia.valid && (alergia.dirty || myForm.submitted)}"
                              #alergia="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaTratamento">Como esta seu tratamento</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.tratamento"
                              name="tratamento" id="textAreaTratamento" rows="4"
                              [ngClass]="{'is-invalid': !tratamento.valid && (tratamento.dirty || myForm.submitted)}"
                              #tratamento="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaSuspeita">Porque você acha que tem a doença</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.suspeita"
                              name="suspeita" id="textAreaSuspeita" rows="4"
                              [ngClass]="{'is-invalid': !suspeita.valid && (suspeita.dirty || myForm.submitted)}"
                              #suspeita="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab title="Hábitos" class="mb-3" id="4">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-3">Hábitos</div>
                <div class="col-md-4">Frequência</div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-3">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input" id="fgFuma"
                           [(ngModel)]="pharmaceuticalRecordAtf.fgFuma"
                           name="fgFuma">
                    <label class="custom-control-label" for="fgFuma">Fuma</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" name="frequenciaFuma"
                         [(ngModel)]="pharmaceuticalRecordAtf.frequenciaFuma"
                         [ngClass]="{'is-invalid': !frequenciaFuma.valid && frequenciaFuma.enabled && (frequenciaFuma.dirty || myForm.submitted)}"
                         #frequenciaFuma="ngModel" maxlength="64" [disabled]="!pharmaceuticalRecordAtf.fgFuma">
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input" id="fgIngereBebidaAlcoolica"
                           [(ngModel)]="pharmaceuticalRecordAtf.fgIngereBebidaAlcoolica"
                           name="fgIngereBebidaAlcoolica">
                    <label class="custom-control-label" for="fgIngereBebidaAlcoolica">Bebida alcoólica</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" name="frequenciaIngereBebidaAlcoolica"
                         [(ngModel)]="pharmaceuticalRecordAtf.frequenciaIngereBebidaAlcoolica"
                         [ngClass]="{'is-invalid': !frequenciaIngereBebidaAlcoolica.valid && frequenciaIngereBebidaAlcoolica.enabled && (frequenciaIngereBebidaAlcoolica.dirty || myForm.submitted)}"
                         #frequenciaIngereBebidaAlcoolica="ngModel" maxlength="64"
                         [disabled]="!pharmaceuticalRecordAtf.fgIngereBebidaAlcoolica">
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input" id="fgIngereCafe"
                           [(ngModel)]="pharmaceuticalRecordAtf.fgIngereCafe"
                           name="fgIngereCafe">
                    <label class="custom-control-label" for="fgIngereCafe">Café</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" name="frequenciaIngereCafe"
                         [(ngModel)]="pharmaceuticalRecordAtf.frequenciaIngereCafe"
                         [ngClass]="{'is-invalid': !frequenciaIngereCafe.valid && frequenciaIngereCafe.enabled && (frequenciaIngereCafe.dirty || myForm.submitted)}"
                         #frequenciaIngereCafe="ngModel" maxlength="64"
                         [disabled]="!pharmaceuticalRecordAtf.fgIngereCafe">
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input" id="fgIngereCha"
                           [(ngModel)]="pharmaceuticalRecordAtf.fgIngereCha"
                           name="fgIngereCha">
                    <label class="custom-control-label" for="fgIngereCha">Chá</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" name="frequenciaIngereCha"
                         [(ngModel)]="pharmaceuticalRecordAtf.frequenciaIngereCha"
                         [ngClass]="{'is-invalid': !frequenciaIngereCha.valid && frequenciaIngereCha.enabled && (frequenciaIngereCha.dirty || myForm.submitted)}"
                         #frequenciaIngereCha="ngModel" maxlength="64"
                         [disabled]="!pharmaceuticalRecordAtf.fgIngereCha">
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input" id="fgIngereAgua"
                           [(ngModel)]="pharmaceuticalRecordAtf.fgIngereAgua"
                           name="fgIngereAgua">
                    <label class="custom-control-label" for="fgIngereAgua">Água</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" name="frequenciaIngereAgua"
                         [(ngModel)]="pharmaceuticalRecordAtf.frequenciaIngereAgua"
                         [ngClass]="{'is-invalid': !frequenciaIngereAgua.valid && frequenciaIngereAgua.enabled && (frequenciaIngereAgua.dirty || myForm.submitted)}"
                         #frequenciaIngereAgua="ngModel" maxlength="64"
                         [disabled]="!pharmaceuticalRecordAtf.fgIngereAgua">
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input" id="fgIngereRefrigerante"
                           [(ngModel)]="pharmaceuticalRecordAtf.fgIngereRefrigerante"
                           name="fgIngereRefrigerante">
                    <label class="custom-control-label" for="fgIngereRefrigerante">Refrigerante</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" name="frequenciaIngereRefrigerante"
                         [(ngModel)]="pharmaceuticalRecordAtf.frequenciaIngereRefrigerante"
                         [ngClass]="{'is-invalid': !frequenciaIngereRefrigerante.valid && frequenciaIngereRefrigerante.enabled && (frequenciaIngereRefrigerante.dirty || myForm.submitted)}"
                         #frequenciaIngereRefrigerante="ngModel" maxlength="64"
                         [disabled]="!pharmaceuticalRecordAtf.fgIngereRefrigerante">
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaOutrasInformaoes">Outras informações</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.outrasInformacoes"
                              name="outrasInformacoes" id="textAreaOutrasInformaoes" rows="4"
                              [ngClass]="{'is-invalid': !outrasInformacoes.valid && (outrasInformacoes.dirty || myForm.submitted)}"
                              #outrasInformacoes="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>

            </ng-template>
          </ngb-tab>
          <ngb-tab title="Farmacoterapia Atual" class="mb-3" id="5">
            <ng-template ngbTabContent>
              <form #medicamentATFForm="ngForm" class="editForm" novalidate
                    (ngSubmit)="onAddMedicament(medicamentATFForm)">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label> Medicamento* </label>
                      <ng-select [ngClass]="{'invalido': !medicamento.valid && medicamentATFForm.submitted}"
                                 class="ng-select" required [options]="medicamentListOption"
                                 [(ngModel)]="medicamentOption" name="medicamento"
                                 #medicamento="ngModel"></ng-select>

                      <label *ngIf="!medicamento.valid && medicamentATFForm.submitted"
                             class="error">Medicamento é obrigatório</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Como usa </label>
                      <input type="text" class="form-control" [(ngModel)]="medicamentATF.modoUso" name="modoUso"
                             [ngClass]="{'is-invalid': !modoUso.valid && (modoUso.dirty || medicamentATFForm.submitted)}"
                             #modoUso="ngModel" maxlength="64">
                      <label *ngIf="!modoUso.valid && (modoUso.dirty || medicamentATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Quanto tempo </label>
                      <input type="text" class="form-control" [(ngModel)]="medicamentATF.tempoUso" name="tempoUso"
                             [ngClass]="{'is-invalid': !tempoUso.valid && (tempoUso.dirty || medicamentATFForm.submitted)}"
                             #tempoUso="ngModel" maxlength="64">
                      <label *ngIf="!tempoUso.valid && (tempoUso.dirty || medicamentATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Para que usa </label>
                      <input type="text" class="form-control" [(ngModel)]="medicamentATF.paraQue" name="paraQue"
                             [ngClass]="{'is-invalid': !paraQue.valid && (paraQue.dirty || medicamentATFForm.submitted)}"
                             #paraQue="ngModel" maxlength="64">
                      <label *ngIf="!paraQue.valid && (paraQue.dirty || medicamentATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Quem indicou </label>
                      <input type="text" class="form-control" [(ngModel)]="medicamentATF.quemIndicou" name="quemIndicou"
                             [ngClass]="{'is-invalid': !quemIndicou.valid && (quemIndicou.dirty || medicamentATFForm.submitted)}"
                             #quemIndicou="ngModel" maxlength="64">
                      <label *ngIf="!quemIndicou.valid && (quemIndicou.dirty || medicamentATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Problema de saúde </label>
                      <input type="text" class="form-control" [(ngModel)]="medicamentATF.problemaSaude"
                             name="problemaSaude"
                             [ngClass]="{'is-invalid': !problemaSaude.valid && (problemaSaude.dirty || medicamentATFForm.submitted)}"
                             #problemaSaude="ngModel" maxlength="64">
                      <label *ngIf="!problemaSaude.valid && (problemaSaude.dirty || medicamentATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Efetividade </label>
                      <input type="text" class="form-control" [(ngModel)]="medicamentATF.efetividade" name="efetividade"
                             [ngClass]="{'is-invalid': !efetividade.valid && (efetividade.dirty || medicamentATFForm.submitted)}"
                             #efetividade="ngModel" maxlength="64">
                      <label *ngIf="!efetividade.valid && (efetividade.dirty || medicamentATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <div class="custom-control custom-switch" style="padding-top: 28px;">
                        <input type="checkbox" class="custom-control-input" id="necessario"
                               [(ngModel)]="medicamentATF.fgNecessario" name="necessario">
                        <label class="custom-control-label" for="necessario">Necessário</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <div class="custom-control custom-switch" style="padding-top: 28px;">
                        <input type="checkbox" class="custom-control-input" id="seguro"
                               [(ngModel)]="medicamentATF.fgSeguro" name="seguro">
                        <label class="custom-control-label" for="seguro">Seguro</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-primary" style="float: right;">Adicionar</button>
                  </div>
                </div>
              </form>

              <hr *ngIf="pharmaceuticalRecordAtf.medicamentos.length >= 1">
              <div *ngFor="let medicamento of pharmaceuticalRecordAtf.medicamentos">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" class="form-control" value="{{medicamento.medicamento.descricao}}" disabled>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button class="btn btn-primary" type="button"
                              (click)="onRemoveMedication(medicamento.idMedicamentoATF)">Remover
                      </button>
                      <button class="btn btn-primary" type="button" style="margin-left: 24px;"
                              (click)="onEditMedication( medicamento )">Editar / Detalhes
                      </button>
                    </div>
                    <div class="form-group">
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Dados Objetivos" class="mb-3" id="6">
            <ng-template ngbTabContent>
              <form #attentionATFForm="ngForm" class="editForm" novalidate
                    (ngSubmit)="onAddAttention(attentionATFForm)">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Data* </label>
                      <input type="text" class="form-control" [(ngModel)]="attetionATF.dhRegistro" name="dhRegistro"
                             [ngClass]="{'is-invalid': !dhRegistro.valid && (dhRegistro.dirty || attentionATFForm.submitted)}"
                             #dhRegistro="ngModel" mask="d0/M0/0000 Hh:Mm" [leadZeroDateTime]="true"
                             [dropSpecialCharacters]="false" required>
                      <label *ngIf="!dhRegistro.valid && (dhRegistro.dirty || attentionATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Peso <span style="font-size: 10px;">(ex.: 69.2)</span> </label>
                      <input type="text" class="form-control" name="peso" [(ngModel)]="attetionATF.peso"
                             [ngClass]="{'is-invalid': !peso.valid && (peso.dirty || attentionATFForm.submitted)}"
                             #peso="ngModel"
                             (change)="attetionATF.peso = peso.value.replace(',', '.'); onIMCChange()" maxlength="7"
                             minlength="2">
                      <label *ngIf="!peso.valid && (peso.dirty || attentionATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Altura em CM <span style="font-size: 10px;">(ex.: 170)</span> </label>
                      <input type="text" class="form-control" name="altura" [(ngModel)]="attetionATF.altura"
                             [ngClass]="{'is-invalid': !altura.valid && (altura.dirty || attentionATFForm.submitted)}"
                             #altura="ngModel"
                             (change)="attetionATF.altura = altura.value.replace(',', '.'); onIMCChange()"
                             minlength="3" maxlength="3">
                      <label *ngIf="!altura.valid && (altura.dirty || attentionATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> IMC </label>
                      <input type="text" class="form-control" name="imc" [(ngModel)]="attetionATF.imc" #imc="ngModel"
                             disabled>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Cintura</label>
                      <input type="number" class="form-control" name="cintura" [(ngModel)]="attetionATF.cintura"
                             [ngClass]="{'is-invalid': !cintura.valid && (cintura.dirty || attentionATFForm.submitted)}"
                             #cintura="ngModel">
                      <label *ngIf="!cintura.valid && (cintura.dirty || attentionATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Glicose</label>
                      <input type="number" class="form-control" name="glicose" [(ngModel)]="attetionATF.glicemia"
                             [ngClass]="{'is-invalid': !glicose.valid && (glicose.dirty || attentionATFForm.submitted)}"
                             #glicose="ngModel"
                             (change)="attetionATF.glicemia = glicose.value.replace(',', '.');">
                      <label *ngIf="!glicose.valid && (glicose.dirty || attentionATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Colesterol </label>
                      <input type="text" class="form-control" name="colesterol" [(ngModel)]="attetionATF.colesterol"
                             [ngClass]="{'is-invalid': !colesterol.valid && (colesterol.dirty || attentionATFForm.submitted)}"
                             #colesterol="ngModel"
                             (change)="attetionATF.colesterol = colesterol.value.replace(',', '.');">
                      <label *ngIf="!colesterol.valid && (colesterol.dirty || attentionATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Triglicerideos </label>
                      <input type="text" class="form-control" name="trigliciredes"
                             [(ngModel)]="attetionATF.trigliciredes"
                             [ngClass]="{'is-invalid': !trigliciredes.valid && (trigliciredes.dirty || attentionATFForm.submitted)}"
                             #trigliciredes="ngModel"
                             (change)="attetionATF.trigliciredes = trigliciredes.value.replace(',', '.');">
                      <label *ngIf="!trigliciredes.valid && (trigliciredes.dirty || attentionATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="custom-control custom-switch" style="padding-top: 28px;">
                      <input type="checkbox" class="custom-control-input" id="fgJejum"
                             [(ngModel)]="attetionATF.fgJejum" name="fgJejum">
                      <label class="custom-control-label" for="fgJejum">Jejum</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label> Outras informações</label>
                      <input type="text" class="form-control" name="outrasInformacoes"
                             [(ngModel)]="attetionATF.outrasInformacoes"
                             [ngClass]="{'is-invalid': !outrasInformacoes.valid && (outrasInformacoes.dirty || attentionATFForm.submitted)}"
                             #outrasInformacoes="ngModel" maxlength="1024">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-primary" style="float: right;">Adicionar</button>
                  </div>
                </div>

                <hr *ngIf="pharmaceuticalRecordAtf.atencoes.length >= 1">
                <div *ngFor="let attention of pharmaceuticalRecordAtf.atencoes">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" class="form-control" value="{{attention.dhRegistro}}" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Peso</label>
                        <input type="text" class="form-control" value="{{attention.peso}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Altura</label>
                        <input type="text" class="form-control" value="{{attention.altura}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>IMC</label>
                        <input type="text" class="form-control" value="{{attention.imc}}" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Glicose</label>
                        <input type="text" class="form-control" value="{{attention.glicemia}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Colesterol</label>
                        <input type="text" class="form-control" value="{{attention.colesterol}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Triglicerideos</label>
                        <input type="text" class="form-control" value="{{attention.trigliciredes}}" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Outras informações</label>
                        <input type="text" class="form-control" value="{{attention.outrasInformacoes}}" disabled>
                      </div>
                    </div>
                  </div>
                  <br>
                  <hr>
                </div>
              </form>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Dados Complementares" class="mb-3" id="7">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaPrescricao">Tratamento não farmacológico</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.prescricao"
                              name="prescricao" id="textAreaPrescricao" rows="8"
                              [ngClass]="{'is-invalid': !prescricao.valid && (prescricao.dirty || myForm.submitted)}"
                              #prescricao="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaMelhoria">O que você acha que pode melhorar</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.melhoria"
                              name="melhoria" id="textAreaMelhoria" rows="8"
                              [ngClass]="{'is-invalid': !melhoria.valid && (melhoria.dirty || myForm.submitted)}"
                              #melhoria="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaAnotacao">Anotações Importantes</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.anotacao"
                              name="anotacao" id="textAreaAnotacao" rows="8"
                              [ngClass]="{'is-invalid': !anotacao.valid && (anotacao.dirty || myForm.submitted)}"
                              #anotacao="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Conduta Farmacêutica" class="mb-3" id="8">
            <ng-template ngbTabContent>
              <form #conductATFForm="ngForm" class="editForm" novalidate (ngSubmit)="onAddConduct(conductATFForm)">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Data* </label>
                      <input type="text" class="form-control" [(ngModel)]="conductATF.dhRegistro" name="dhRegistro"
                             [ngClass]="{'is-invalid': !dhRegistro.valid && (dhRegistro.dirty || conductATFForm.submitted)}"
                             #dhRegistro="ngModel" [textMask]="{mask: dateHourMask}" required>
                      <label *ngIf="!dhRegistro.valid && (dhRegistro.dirty || conductATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label> Nome* </label>
                      <input type="text" class="form-control" [(ngModel)]="conductATF.descricao" name="conduta"
                             [ngClass]="{'is-invalid': !dhRegistro.valid && (conduta.dirty || conductATFForm.submitted)}"
                             #conduta="ngModel" required>
                      <label *ngIf="!conduta.valid && (conduta.dirty || conductATFForm.submitted)"
                             class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="textAreaCondutaNaoFarmacologica">Conduta*</label>
                      <textarea class="form-control" name="prescricaoNaoFarmacologica"
                                id="textAreaCondutaNaoFarmacologica" rows="8"
                                [ngClass]="{'is-invalid': !prescricaoNaoFarmacologica.valid && (prescricaoNaoFarmacologica.dirty || conductATFForm.submitted)}"
                                [(ngModel)]="conductATF.prescricaoNaoFarmacologica"
                                #prescricaoNaoFarmacologica="ngModel" minlength="1" required></textarea>
                      <label
                        *ngIf="!prescricaoNaoFarmacologica.valid && (prescricaoNaoFarmacologica.dirty || conductATFForm.submitted)"
                        class="error jquery-validation-error small form-text invalid-feedback">Dados
                        incorretos</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-primary" style="float: right;">Adicionar</button>
                  </div>
                </div>

                <hr *ngIf="pharmaceuticalRecordAtf.condutas.length >= 1">
                <div *ngFor="let conduta of pharmaceuticalRecordAtf.condutas">
                  <div class="row">
                    <div class="form-group form-check custom-switch switch-ativo">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1"
                             [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="conduta.fgDownload">
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" class="form-control" value="{{conduta.dhRegistro}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input class="form-control" value="{{conduta.descricao}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <button class="btn btn-primary" type="button" style="float: right;"
                                (click)="onRemoveConduta(conduta)">Remover
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea class="form-control" rows="4" value="{{conduta.prescricaoNaoFarmacologica}}"
                                  disabled></textarea>
                      </div>
                    </div>
                  </div>
                  <br>
                </div>

                <div class="row"
                     *ngIf="editMode && pharmaceuticalRecordAtf.condutas != null  && pharmaceuticalRecordAtf.condutas.length >= 1">
                  <button class="btn btn-primary" type="button" style="float: right;"
                          [disabled]="shouldImprimirSelecionados(pharmaceuticalRecordAtf)"
                          (click)="this.downloadPdfCondutas(pharmaceuticalRecordAtf)">Imprimir Selecionados
                  </button>
                </div>
              </form>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Avaliação Pós Conduta" class="mb-3" id="9">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaResultadoTratamento">Como esta seu tratamento após acompanhamento?</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.resultadoTratamento"
                              name="resultadoTratamento" id="textAreaResultadoTratamento" rows="8"
                              [ngClass]="{'is-invalid': !resultadoTratamento.valid && (resultadoTratamento.dirty || myForm.submitted)}"
                              #resultadoTratamento="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaSuspeitaFinal">Suspeita-se</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.suspeitaFinal"
                              name="suspeitaFinal" id="textAreaSuspeitaFinal" rows="8"
                              [ngClass]="{'is-invalid': !suspeitaFinal.valid && (suspeitaFinal.dirty || myForm.submitted)}"
                              #suspeitaFinal="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaSugere">Sugere-se</label>
                    <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecordAtf.sugere"
                              name="sugere" id="textAreaSugere" rows="8"
                              [ngClass]="{'is-invalid': !sugere.valid && (sugere.dirty || myForm.submitted)}"
                              #sugere="ngModel" maxlength="1024"></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="validateOpen(myForm)" matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Revisão de Farmacoterapia?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="pharmaceuticalRecordAtf.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="pharmaceuticalRecordAtf.servico.vlCobrado"
               [disabled]="!pharmaceuticalRecordAtf.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || myForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="pharmaceuticalRecordAtf.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(myForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
