import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Patient} from '../models/patient';
import {GlobalConstants} from '../globals/global-constants';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {

  url = GlobalConstants.apiURL + 'agendamento';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(agendamento): Observable<any> {
    return this.httpClient.post<any>(this.url, agendamento, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  insertByReagendamento(agendamentoAux): Observable<any> {

    var dateMomentObject = moment(agendamentoAux.data, "DD/MM/YYYY hh:mm");
    const agendamento = {
      dsTitulo: 'Ainda teste',
      dtInicio: dateMomentObject.toDate(),
      color: '#a389d4',
      farmaceutico: {
        idFarmaceutico: agendamentoAux.idFarmaceutico
      },
      paciente: {
        idPaciente: agendamentoAux.idPaciente
      },
      tpServico: agendamentoAux.tpServico
    }
    return this.httpClient.post<any>(this.url, agendamento, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  update(agendamento: any): Observable<any> {
    return this.httpClient.put<any>(this.url + '/' + agendamento.id, agendamento, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(agendamento: any): Observable<any> {
    return this.httpClient.delete<any>(this.url + '/' + agendamento.id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number): Observable<Patient> {
    return this.httpClient.get<Patient>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
