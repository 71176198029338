import {Component, OnInit} from '@angular/core';
import {PatientService} from "../../services/patient.service";
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {IOption} from "ng-select";
import {PharmaceuticalService} from "../../services/pharmaceutical.service";
import {ServiceTypeService} from "../../services/service-type.service";
import * as moment from "moment";

@Component({
  selector: 'app-atendimento',
  templateUrl: './atendimento.component.html',
  styleUrls: ['./atendimento.component.scss']
})
export class AtendimentoComponent implements OnInit {


  patientListConfig: any = {};
  lista;
  filtros = {
    paciente: undefined,
    farmaceutico: undefined,
    tipoServico: undefined
  };

  pharmaceuticalListOption: Array<IOption> = [];
  patientListOption: Array<IOption> = [];
  typeServiceListOption: Array<IOption> = [];

  constructor(private patientService: PatientService, private relatorioService: PharmaceuticalServiceService,
              private pharmaceuticalService: PharmaceuticalService, private serviceTypeService: ServiceTypeService) {
  }

  ngOnInit() {
    this.serviceTypeService.getAll().subscribe((serviceTypeList) => {
      this.typeServiceListOption = [];
      serviceTypeList.forEach((servico) => {
        this.typeServiceListOption.push({
          value: servico.idTipoServico + '',
          label: servico.dsServico
        });
      });

    });
    this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
      this.pharmaceuticalListOption = [];
      pharmaceuticalList.forEach((pharmaceutical) => {
        this.pharmaceuticalListOption.push({
          value: pharmaceutical.idFarmaceutico + '',
          label: pharmaceutical.nome
        });
      });

    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });
    });


    this.patientListConfig = {
      ajax: (dataTablesParameters: any, callback) => {
        // this.patientService.getAll().subscribe(patientList => {
        //
        // });

        this.relatorioService.searchRelatorioAtendimento(this.filtros).subscribe(result => {
          this.lista = result;
          console.log(this.lista)
          callback({
            recordsTotal: result.length,
            recordsFiltered: result.length,
            data: result
          });
        });
      },

      info: false,
      paging: true,
      searching: true,
      pageLength: 25,
      pagingType: 'full_numbers',

      language: {
        emptyTable: 'Nenhum registro encontrado',
        info: '',
        infoEmpty: '',
        infoFiltered: '',
        infoPostFix: '',
        decimal: '',
        thousands: '',
        lengthMenu: '',
        loadingRecords: '',
        processing: '',
        search: 'Pesquisar',
        searchPlaceholder: '',
        zeroRecords: 'Nenhum registro encontrado',
        url: '',

        paginate: {
          last: 'Último',
          next: 'Próximo',
          first: 'Primeiro',
          previous: 'Anterior',
        }
      },

      columns: [{
        title: 'Farmacêutico',
        data: 'farmaceutico.nome'
      }, {
        title: 'Paciente',
        data: 'paciente.nome'
      }, {
        title: 'Idade Paciente',
        data: 'paciente.dataNascimento'
      }, {
        title: 'Sexo Paciente',
        searchable: false,
        render: function (data: any, type: any, full: any) {
          return full.paciente.flagGenero ? 'Feminino' : 'Masculino';
        }
      }, {
        title: 'Serviço',
        data: 'tpServico.dsServico'
      }, {
        title: 'Data e hora do Serviço',
        render: function (data: any, type: any, full: any) {
          // return new Date(full.dhServico).toLocaleString('pt-BR', {timeZone: "America/Sao_Paulo"});
          return moment(full.dhServico).format('DD/MM/YYYY HH:mm')
        }
      }, {
        title: 'Preço Original Serviço',
        // data: 'tpServico.vlServico'
        render: function (data: any, type: any, full: any) {
          // return new Date(full.dhServico).toLocaleString('pt-BR', {timeZone: "America/Sao_Paulo"});
          return 'R$ ' + full.tpServico.vlServico.toFixed(2);
        }
      }, {
        title: 'Valor Cobrado pelo Serviço',
        // data: 'vlCobrado'
        render: function (data: any, type: any, full: any) {
          // return new Date(full.dhServico).toLocaleString('pt-BR', {timeZone: "America/Sao_Paulo"});
          return 'R$ ' + full.vlCobrado.toFixed(2);
        }
      },],
    };
  }

  buscar() {
    console.log(this.filtros)
    const filtro = {paciente: undefined, farmaceutico: undefined, tipoServico: undefined};
    if (this.filtros.paciente) {
      filtro.paciente = {
        idPaciente: this.filtros.paciente
      }
    }

    if (this.filtros.farmaceutico) {
      filtro.farmaceutico = {
        idFarmaceutico: this.filtros.farmaceutico
      }
    }
    if (this.filtros.tipoServico) {
      filtro.tipoServico = {
        idTipoServico: this.filtros.tipoServico
      }
    }
    this.relatorioService.searchRelatorioAtendimento(filtro).subscribe(result => {
      this.lista = result;
      $('#testeTable').DataTable().clear().rows.add(result).draw();
    });
  }
}
