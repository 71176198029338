import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {PatientService} from '../../services/patient.service';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {EarringApplication} from '../../models/earring-application';
import {EarringApplicationService} from '../../services/earring-application.service';
import {NgForm} from '@angular/forms';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {EquipmentService} from '../../services/equipment.service';
import {EarringService} from '../../services/earring.service';
import {IOption} from 'ng-select';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {ConfigGeralService} from "../../services/config-geral.service";
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-earring-application',
  templateUrl: './earring-application.component.html',
  styleUrls: ['./earring-application.component.scss']
})
export class EarringApplicationComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalCobrar') modalCobrar;
  @ViewChild('modalRetorno') modalRetorno;
  earringApplication = {
    paciente: {},
    farmaceutico: {},
    marcaBrinco: {},
    equipamento: {},
    servico: {
      fgCobrado: false
    },
    observacao: ''
  } as EarringApplication;

  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;
  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];

  earringOption = null;
  earringListOption: Array<IOption> = [];

  equipmentOption = null;
  equipmentListOption: Array<IOption> = [];

  pacienteByAgenda = null;

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private pharmaceuticalService: PharmaceuticalService,
    private earringService: EarringService,
    private equipmentService: EquipmentService,
    private earringApplicationService: EarringApplicationService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private configGeralService: ConfigGeralService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });

    this.configGeralService.getOne().subscribe((configGeral) => {
      this.earringApplication.observacao = configGeral.dsObservacaoAplicaoBrincoDefault;
    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });
      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });

    this.earringService.getAllActives().subscribe((earringList) => {
      this.earringListOption = [];
      earringList.forEach((earring) => {
        this.earringListOption.push({
          value: earring.idMarcaBrinco + '',
          label: earring.descricaoMarca
        });
      });
    });

    this.equipmentService.getAllActives().subscribe((equipmentList) => {
      this.equipmentListOption = [];
      equipmentList.forEach((equipment) => {
        this.equipmentListOption.push({
          value: equipment.idEquipamento + '',
          label: equipment.descricaoEquipamento
        });
      });
    });

    this.serviceTypeService.getOne(ServiceTypeEnum.APLICACAO_BRINCO).subscribe((tpServico) => {
      this.earringApplication.servico.vlCobrado = tpServico.vlServico;
      this.earringApplication.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
      this.earringApplication.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
    });
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  save(form: NgForm) {

    if (form.valid) {
      if (!this.pharmaceuticalOption) {
        this.showErrorToast('Informe um farmacêutico');

      } else if (!this.patientOption) {
        this.showErrorToast('Informe um paciente');

      } else if (!this.equipmentOption) {
        this.showErrorToast('Informe um equipamento');

      } else if (!this.earringOption) {
        this.showErrorToast('Informe uma marca');

      } else {
        this.earringApplication.dhCadastro = new Date();
        this.earringApplication.paciente.idPaciente = Number(this.patientOption);
        this.earringApplication.marcaBrinco.idMarcaBrinco = Number(this.earringOption);
        this.earringApplication.equipamento.idEquipamento = Number(this.equipmentOption);
        this.earringApplication.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);

        this.earringApplicationService.insert(this.earringApplication).subscribe((earringApplication) => {
          this.modalCobrar.hide();
          this.showToast('Aplicação de brinco cadastrada com sucesso');

          this.earringApplication.idAplicacaoBrinco = earringApplication.idAplicacaoBrinco;
          if (this.earringApplication.servico.fgSugerirRetorno) {

            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + this.earringApplication.servico.qtdDiasRetorno);
            this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
            this.modalRetorno.show({backdrop: 'static'});
          } else {
            this.redirectPaciente();
          }

        }, (error) => {
          if (error && error.error && error.error.message) {
            this.showErrorToast(error.error.message);

          } else {
            this.showErrorToast('Não foi possível concluir o cadastro');
          }
        });
      }
    }
  }

  private redirectPaciente() {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: this.earringApplication.paciente.idPaciente,
        pdf: this.earringApplication.idAplicacaoBrinco,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.APLICACAO_BRINCO,
      }
    }).then();
  }

  validateOpen(form) {
    console.log(this.earringApplication.servico)
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }

  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.earringApplication.farmaceutico.idFarmaceutico,
      idPaciente: this.earringApplication.paciente.idPaciente,
      tpServico: ServiceTypeEnum.APLICACAO_BRINCO,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

}
