import {ServiceTypeEnum} from "../enums/service-type.enum";

export class ServicoUtils {

  public static nomeServico(id) {
    if (id == ServiceTypeEnum.APLICACAO_BRINCO) {
      return 'Aplicação de Brinco';
    }

    if (id == ServiceTypeEnum.SERVICO_COVID) {
      return 'Teste rápido para COVID-19';
    }

    if (id == ServiceTypeEnum.APLICACAO_INJETAVEL) {
      return 'Aplicação de Injetáveis'
    }

    if (id == ServiceTypeEnum.AVALIACAO_BIOIMPEDANCIA) {
      return 'Aplicação de Bioimpedância'
    }

    if (id == ServiceTypeEnum.DECLARACAO_TEMPERATURA) {
      return 'Declaração de Temperatura'
    }

    if (id == ServiceTypeEnum.DECLARACAO_COLESTEROL) {
      return 'Declaração de Colesterol'
    }

    if (id == ServiceTypeEnum.DECLARACAO_GLICEMIA) {
      return 'Declaração de Glicemia'
    }

    if (id == ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL) {
      return 'Declaração de Pressão Arterial'
    }

    if (id == ServiceTypeEnum.GESTAO_MEDICACAO) {
      return 'Gestão de Medicação'
    }

    if (id == ServiceTypeEnum.NEBULIZACAO) {
      return 'Nebulização'
    }

    if (id == ServiceTypeEnum.PRESCRICAO) {
      return 'Prescrição Farmaceutica'
    }

    if (id == ServiceTypeEnum.PRONTUARIO_SOAP) {
      return 'Consulta Farmacêutico/SOAP'
    }

    if (id == ServiceTypeEnum.PROTOCOLO_ATF) {
      return 'Revisão de Farmacoterapia'
    }
  }

  public static getUrlByServico(id) {
    if (id == ServiceTypeEnum.APLICACAO_BRINCO) {
      return 'aplicacao/brinco';
    }

    if (id == ServiceTypeEnum.SERVICO_COVID) {
      return '/servico/covid';
    }

    if (id == ServiceTypeEnum.APLICACAO_INJETAVEL) {
      return 'aplicacao/injetaveis'
    }

    if (id == ServiceTypeEnum.AVALIACAO_BIOIMPEDANCIA) {
      return 'avaliacao/bioimpedancia'
    }

    if (id == ServiceTypeEnum.DECLARACAO_TEMPERATURA) {
      return 'declaracao/temperatura'
    }

    if (id == ServiceTypeEnum.DECLARACAO_COLESTEROL) {
      return 'declaracao/colesterol'
    }

    if (id == ServiceTypeEnum.DECLARACAO_GLICEMIA) {
      return 'declaracao/glicemia'
    }

    if (id == ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL) {
      return 'declaracao/pressao-arterial'
    }

    if (id == ServiceTypeEnum.GESTAO_MEDICACAO) {
      return 'gestao/medicacao'
    }

    if (id == ServiceTypeEnum.NEBULIZACAO) {
      return 'nebulizacao'
    }

    if (id == ServiceTypeEnum.PRESCRICAO) {
      return 'prescricao'
    }

    if (id == ServiceTypeEnum.PRONTUARIO_SOAP) {
      return 'prontuario-soap'
    }

    if (id == ServiceTypeEnum.PROTOCOLO_ATF) {
      return 'prontuario-atf'
    }
  }
}
