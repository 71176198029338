<form #temperatureStatementForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Declaração de Temperatura" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                         [ngClass]="{'invalido': !farmaceutico.valid && temperatureStatementForm.submitted}"
                         class="ng-select"
                         #farmaceutico="ngModel"></ng-select>
              <label *ngIf="!farmaceutico.valid && temperatureStatementForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select
                [ngClass]="{'invalido': !paciente.valid && temperatureStatementForm.submitted}"
                class="ng-select" [options]="patientListOption" required
                [(ngModel)]="patientOption" name="paciente"
                #paciente="ngModel"></ng-select>
              <label *ngIf="!paciente.valid && temperatureStatementForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Temperatura em Cº* </label>
              <input type="text" class="form-control" [(ngModel)]="temperatureStatement.temperatura" name="temperatura"
                     [ngClass]="{'is-invalid': !temperatura.valid && (temperatura.dirty || temperatureStatementForm.submitted)}"
                     #temperatura="ngModel" required [textMask]="{mask: temperatureMask}">
              <label *ngIf="!temperatura.valid && (temperatura.dirty || temperatureStatementForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Temperatura
                incorreta</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label> Horário* </label>
              <input type="text" class="form-control" [(ngModel)]="temperatureStatement.horario" name="horario"
                     minlength="4"
                     [ngClass]="{'is-invalid': !horario.valid && (horario.dirty || temperatureStatementForm.submitted)}"
                     #horario="ngModel" required mask="Hh:m0" [dropSpecialCharacters]="false">

              <label *ngIf="!horario.valid && temperatureStatementForm.submitted"
                     class="error">Horário está inválido</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="temperatureStatement.observacao"
                        name="observacoes"
                        id="textAreaObservacoes" rows="14"
                        #observacoes="ngModel" maxlength="1024"></textarea>
            </div>
          </div>

          <div class="col-md-4">
            <table class="table table-striped table-bordered nowrap table-hover" style="margin-top: 30px;">
              <thead>
              <tr>
                <th>Situação</th>
                <th>Temperatura</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Normal</th>
                <th>36,0 ºC a 37,0 ºC</th>
              </tr>
              <tr>
                <th>Febre baixa</th>
                <th>37,6 ºC a 38,0 ºC</th>
              </tr>
              <tr>
                <th>Febre moderada</th>
                <th>38,1 ºC a 39,0 ºC</th>
              </tr>
              <tr>
                <th>Febre alta</th>
                <th>Acima de 39,1 ºC;</th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </app-card>
    </div>
  </div>
<button mat-fab color="primary" class="add-button" (click)="validateOpen(temperatureStatementForm)" matTooltip="Salvar">
  <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Declaração de Temperatura?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="temperatureStatement.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="temperatureStatement.servico.vlCobrado"
               [disabled]="!temperatureStatement.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || temperatureStatementForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="temperatureStatement.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(temperatureStatementForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
