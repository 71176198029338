import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Patient} from '../models/patient';
import {GlobalConstants} from '../globals/global-constants';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  url = GlobalConstants.apiURL + 'paciente';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(patient: Patient): Observable<Patient> {
    return this.httpClient.post<Patient>(this.url, patient, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  update(patient: Patient): Observable<Patient> {
    return this.httpClient.put<Patient>(this.url + '/' + patient.idPaciente, patient, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(): Observable<Patient[]> {
    return this.httpClient.get<Patient[]>(this.url, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllActives(): Observable<Patient[]> {
    return this.httpClient.get<Patient[]>(this.url + '/active', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number): Observable<Patient> {
    return this.httpClient.get<Patient>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPDFDeclaracaoComparecimentoUrl() {
    return this.url + '/declaracao/pdf';
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
