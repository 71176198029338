<form #myForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Nebulização" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select [ngClass]="{'invalido': !farmaceutico.valid && myForm.submitted}"
                         class="ng-select" required [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                         #farmaceutico="ngModel"></ng-select>
              <label *ngIf="!farmaceutico.valid && myForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select [ngClass]="{'invalido': !paciente.valid && myForm.submitted}"
                         class="ng-select" required [options]="patientListOption"
                         [(ngModel)]="patientOption" name="paciente"
                         #paciente="ngModel"></ng-select>
              <label *ngIf="!paciente.valid && myForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Nebulizador* </label>
              <ng-select [ngClass]="{'invalido': !nebulizador.valid && myForm.submitted}"
                         class="ng-select" required [options]="nebulizerListOption"
                         [(ngModel)]="nebulizerOption" name="nebulizador"
                         #nebulizador="ngModel"></ng-select>
              <label *ngIf="!nebulizador.valid && myForm.submitted"
                     class="error">Nebulizador é obrigatório</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label> Prescritor </label>
              <input type="text" class="form-control" [(ngModel)]="nebulization.prescritor" name="prescritor"
                     #prescritor="ngModel">
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label> Registro/UF </label>
              <input type="text" class="form-control" [(ngModel)]="nebulization.registroUf" name="registroUf"
                     #registroUf="ngModel">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaMedicamentos">Medicamentos*</label>
              <textarea type="text" class="form-control" [(ngModel)]="nebulization.medicamentos"
                        name="medicamentos" id="textAreaMedicamentos" rows="8" #medicamentos="ngModel"
                        [ngClass]="{'is-invalid': !medicamentos.valid && (medicamentos.dirty || myForm.submitted)}"
                        maxlength="1024" minlength="2" required></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="nebulization.observacao"
                        name="observacoes" id="textAreaObservacoes" rows="8" #observacoes="ngModel"
                        maxlength="1024"></textarea>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="validateOpen(myForm)" matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Nebulização?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="nebulization.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="nebulization.servico.vlCobrado"
               [disabled]="!nebulization.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || myForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="nebulization.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(myForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
