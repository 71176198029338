import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TemperatureStatement} from '../../models/temperature-statement';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {PatientService} from '../../services/patient.service';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {TemperatureStatementService} from '../../services/temperature-statement.service';
import {Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {IOption} from 'ng-select';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-temperature-statement',
  templateUrl: './temperature-statement.component.html',
  styleUrls: ['./temperature-statement.component.scss']
})
export class TemperatureStatementComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalCobrar') modalCobrar;
  @ViewChild('modalRetorno') modalRetorno;
  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;
  temperatureStatement = {
    paciente: {},
    farmaceutico: {},
    servico: {
      fgCobrado: false
    },
  } as TemperatureStatement;

  hourMask = [/\d/, /\d/, ':', /\d/, /\d/];
  temperatureMask = [/\d/, /\d/, '.', /\d/, /\d/];

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];
  pacienteByAgenda;

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private pharmaceuticalService: PharmaceuticalService,
    private temperatureStatementService: TemperatureStatementService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {

    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });
      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });

    this.serviceTypeService.getOne(ServiceTypeEnum.DECLARACAO_TEMPERATURA).subscribe((tpServico) => {
      this.temperatureStatement.servico.vlCobrado = tpServico.vlServico;
      this.temperatureStatement.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
      this.temperatureStatement.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
    });
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  save(form: NgForm) {
    if (form.valid) {
      if (!this.pharmaceuticalOption) {
        this.showErrorToast('Informe um farmacêutico');

      } else if (!this.patientOption) {
        this.showErrorToast('Informe um paciente');

      } else {
        this.temperatureStatement.paciente.idPaciente = Number(this.patientOption);
        this.temperatureStatement.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);

        this.temperatureStatementService.insert(this.temperatureStatement).subscribe((temperatureStatement) => {
          this.modalCobrar.hide();
          this.showToast('Declaração de temperatura cadastrada com sucesso');
          this.temperatureStatement.idDeclaracaoTemperatura = temperatureStatement.idDeclaracaoTemperatura;
          if (this.temperatureStatement.servico.fgSugerirRetorno) {

            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + this.temperatureStatement.servico.qtdDiasRetorno);
            this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
            this.modalRetorno.show({backdrop: 'static'});
          } else {
            this.redirectPaciente();
          }

        });
      }
    }
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  validateOpen(form) {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }
  private redirectPaciente() {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: this.temperatureStatement.paciente.idPaciente,
        pdf: this.temperatureStatement.idDeclaracaoTemperatura,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.DECLARACAO_TEMPERATURA,
      }
    }).then();
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.temperatureStatement.farmaceutico.idFarmaceutico,
      idPaciente: this.temperatureStatement.paciente.idPaciente,
      tpServico: ServiceTypeEnum.DECLARACAO_TEMPERATURA,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }


}
