import {Nebulizer} from '../../models/nebulizer';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NebulizerService} from 'src/app/services/nebulizer.service';
import {ToastyService} from 'ng2-toasty';
import {NgForm} from '@angular/forms';
import {IOption} from 'ng-select';

@Component({
  selector: 'app-nebulizer-form',
  templateUrl: './nebulizer-form.component.html',
  styleUrls: ['./nebulizer-form.component.scss']
})
export class NebulizerFormComponent implements OnInit {

  nebulizer = {flagAtivo: 1} as Nebulizer;
  editMode = false as boolean;

  id: number;
  nebulizerListOption: Array<IOption> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private nebulizerService: NebulizerService,
    private toastyService: ToastyService,
    private router: Router
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });
  }

  ngOnInit() {
    if (this.id !== undefined) {
      this.nebulizerService.getById(this.id).subscribe((nebulizer) => {
        this.nebulizer = nebulizer;
      });
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  onInsert() {
    this.nebulizerService.insert(this.nebulizer).subscribe((nebulizador) => {
      this.showToast('Nebulizador cadastrado com sucesso!');

      this.id = nebulizador.idNebulizador;
      this.nebulizer.idNebulizador = nebulizador.idNebulizador;
      this.router.navigate(['/nebulizador/form/edit'], {queryParams: {id: this.id}}).then();
    });
  }

  onUpdate() {
    this.nebulizerService.update(this.nebulizer).subscribe(() => {
      this.showToast('Nebulizador alterado com sucesso');
    });
  }

  save(form: NgForm) {
    if (form.valid) {
      if (this.nebulizer.idNebulizador !== undefined) {
        this.onUpdate();
      } else {
        this.onInsert();
      }
    }
  }

  edit() {
    if (this.id) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/nebulizador/form/edit'], {queryParams: {id: this.id}}).then();
      });

    } else {
      this.showErrorToast('Selecione um nebulizador');
    }
  }

  search() {
    this.nebulizerService.getAll().subscribe((nebulizers) => {
      this.nebulizerListOption = [];
      nebulizers.forEach((medicament) => {
        this.nebulizerListOption.push({
          value: medicament.idNebulizador + '',
          label: medicament.descricaoNebulizador
        });
      });
    });
  }
}
