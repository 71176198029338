<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="teste-login" >
      <form #authForm="ngForm" style="background-color: white;    margin: 20px;
    border-radius: 20px;" class="editForm" novalidate (ngSubmit)="doLogin(authForm)">
        <div class=" auth-content" style="    max-width: 330px;">
          <h3 class="mb-4 f-w-400">Login</h3>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="feather icon-mail"></i></span>
            </div>
            <input type="email" class="form-control" [(ngModel)]="pharmaceutical.email" name="email"
                   [ngClass]="{'is-invalid': !email.valid && (email.dirty || authForm.submitted)}"
                   #email="ngModel" minlength="2"
                   maxlength="64"
                   required>
            <label *ngIf="!email.valid && (email.dirty || authForm.submitted)"
                   class="error jquery-validation-error small form-text invalid-feedback">E-mail incorreto</label>
          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="feather icon-lock"></i></span>
            </div>
            <input type="password" class="form-control" [(ngModel)]="pharmaceutical.senha" name="senha"
                   [ngClass]="{'is-invalid': !senha.valid && (senha.dirty || authForm.submitted)}"
                   #senha="ngModel" minlength="2"
                   maxlength="64"
                   required>
            <label *ngIf="!senha.valid && (senha.dirty || authForm.submitted)"
                   class="error jquery-validation-error small form-text invalid-feedback">Senha incorreta</label>
          </div>
          <div class="form-group text-left mt-2">
            <div class="checkbox checkbox-primary d-inline">
              <input type="checkbox" name="checkbox-p-1" id="checkbox-p-1" checked="">
              <label for="checkbox-p-1" class="cr">Manter conectado</label>
            </div>
          </div>
          <button class="btn btn-block btn-primary mb-0" type="submit">Entrar</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng2-toasty position="top-right"></ng2-toasty>
