import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SharedModule } from '../theme/shared/shared.module';
import { AgendaComponent } from './agenda.component';
import { BrowserModule } from '@angular/platform-browser';
import { SelectModule } from 'ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    SelectModule,
    TextMaskModule,
    NgbDropdownModule,
  ],
  declarations: [AgendaComponent],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA
    
  ]
})
export class AgendaModule { }
