export enum ServiceTypeEnum {
  PRESCRICAO = 0,
  PRONTUARIO_SOAP = 1,
  DECLARACAO_TEMPERATURA = 2,
  APLICACAO_BRINCO = 3,
  DECLARACAO_GLICEMIA = 4,
  GESTAO_MEDICACAO = 5,
  DECLARACAO_COLESTEROL = 6,
  DECLARACAO_PRESSAO_ARTERIAL = 7,
  NEBULIZACAO = 8,
  APLICACAO_INJETAVEL = 9,
  PROTOCOLO_ATF = 10,
  AVALIACAO_BIOIMPEDANCIA = 11,
  SERVICO_COVID = 14,
}
