<form [formGroup]="myForm" class="editForm" novalidate (ngSubmit)="checkIsValid()">
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Avaliação de Pressão Arterial" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select
                [ngClass]="{'invalido': !myForm.controls.farmaceutico.valid && myForm.controls.farmaceutico.touched}"
                class="ng-select" required [options]="pharmaceuticalListOption" [(ngModel)]="pharmaceuticalOption"
                formControlName="farmaceutico"></ng-select>

              <label *ngIf="!myForm.controls.farmaceutico.valid && myForm.controls.farmaceutico.touched"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select [ngClass]="{'invalido': !myForm.controls.paciente.valid && myForm.controls.paciente.touched}"
                         class="ng-select" required [options]="patientListOption"
                         [(ngModel)]="patientOption" formControlName="paciente"></ng-select>
              <label *ngIf="!myForm.controls.paciente.valid && myForm.controls.paciente.touched"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="bloodPressureStatement.observacao"
                        name="observacoes" formControlName="observacao"
                        id="textAreaObservacoes" rows="8"
                        [ngClass]="{'is-invalid': !myForm.controls.observacao.valid && myForm.controls.observacao.touched}"
                        maxlength="1024"></textarea>
            </div>
          </div>

          <div class="col-md-5">
            <table class="table table-striped table-bordered nowrap table-hover" style="margin-top: 30px;">
              <thead>
              <tr>
                <th>Classificação</th>
                <th>Pressão Sistólica</th>
                <th>Pressão Diastólica</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Normal</th>
                <th>menor que <strong>120</strong> mmHg</th>
                <th>menor que <strong>80</strong> mmHg</th>
              </tr>
              <tr>
                <th>Pré-hipertensão</th>
                <th><strong>121-139</strong> mmHg</th>
                <th><strong>81-89</strong> mmHg</th>
              </tr>
              <tr>
                <th>Hipertensão 1</th>
                <th><strong>140-159</strong> mmHg</th>
                <th><strong>90-99</strong> mmHg</th>
              </tr>
              <tr>
                <th>Hipertensão 2</th>
                <th><strong>160-179</strong> mmHg</th>
                <th><strong>100-109</strong> mmHg</th>
              </tr>
              <tr>
                <th>Hipertensão 3</th>
                <th><strong>maior ou igual a 180</strong> mmHg</th>
                <th><strong>maior ou igual a 110</strong> mmHg</th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <form #measurementForm="ngForm" class="editForm" style="display: flex" novalidate
              (ngSubmit)="onAddMeasurement(measurementForm)">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label> Data* </label>
                  <input type="text" class="form-control" [(ngModel)]="measurement.dtMedicao" name="dtMedicao"
                         [ngClass]="{'is-invalid': !dtMedicao.valid && (dtMedicao.dirty || measurementForm.submitted)}"
                         #dtMedicao="ngModel" mask="d0/M0/0000" [leadZeroDateTime]="true"
                         [dropSpecialCharacters]="false"
                         required>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label> Horário* </label>
                  <input type="text" class="form-control" [(ngModel)]="measurement.horario" name="horario"
                         [dropSpecialCharacters]="false"
                         [ngClass]="{'is-invalid': !horario.valid && (horario.dirty || measurementForm.submitted)}"
                         #horario="ngModel" required mask="Hh:m0" [dropSpecialCharacters]="false">

                  <label *ngIf="!horario.valid && (horario.dirty || measurementForm.submitted)"
                         class="error jquery-validation-error small form-text invalid-feedback">Horário
                    inválido</label>
                </div>
              </div>


              <div class="col-md-4">
                <div class="form-group">
                  <label> Braço Medição</label>

                  <div style="display: flex">
                    <div class="custom-control custom-radio" style="padding-right: 15px; padding-top: 5px;">
                      <input type="radio" class="custom-control-input" id="customControlValidation2"
                             required [(ngModel)]="measurement.bracoMedicao" [ngModelOptions]="{standalone: true}"
                             [value]="'DIREITO'">
                      <label class="custom-control-label" for="customControlValidation2">Direito</label>
                    </div>
                    <div class="custom-control custom-radio mb-3" style="padding-top: 5px;">
                      <input type="radio" [(ngModel)]="measurement.bracoMedicao"
                             class="custom-control-input" id="customControlValidation3"
                             name="radio-stacked" [ngModelOptions]="{standalone: true}"
                             required [value]="'ESQUERDO'">
                      <label class="custom-control-label" for="customControlValidation3">Esquerdo</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label> Sistólica (PAS) mmHg*</label>
                  <input type="number" class="form-control" [(ngModel)]="measurement.minima" name="minima"
                         #minima="ngModel" min="60" max="400"
                         [ngClass]="{'is-invalid': !minima.valid && (minima.dirty || measurementForm.submitted)}"
                         (change)="measurement.minima = minima.value.replace(',', '.');"
                         required minlength="3" maxlength="5">

                  <label
                    *ngIf="!minima.valid && minima.hasError('required')  && (minima.dirty || measurementForm.submitted)"
                    class="error jquery-validation-error small form-text invalid-feedback">Sistólica é
                    obrigatória</label>
                  <label *ngIf="!minima.valid && minima.hasError('min') && (minima.dirty || measurementForm.submitted)"
                         class="error jquery-validation-error small form-text invalid-feedback">Sistólica mínima é
                    60 mmHg</label>
                  <label *ngIf="!minima.valid && minima.hasError('max') && (minima.dirty || measurementForm.submitted)"
                         class="error jquery-validation-error small form-text invalid-feedback">Sistólica máxima é
                    400 mmHg</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label> Diastólica (PAD) mmHg* </label>
                  <input type="number" class="form-control" [(ngModel)]="measurement.maxima" name="maxima"
                         #maxima="ngModel" min="40" max="200"
                         [ngClass]="{'is-invalid': !maxima.valid && (maxima.dirty || measurementForm.submitted)}"
                         (change)="measurement.maxima = maxima.value.replace(',', '.');"
                         required minlength="3" maxlength="5">

                  <label
                    *ngIf="!maxima.valid && maxima.hasError('required')  && (maxima.dirty || measurementForm.submitted)"
                    class="error jquery-validation-error small form-text invalid-feedback">Diastólica é
                    obrigatória</label>
                  <label *ngIf="!maxima.valid && maxima.hasError('min') && (maxima.dirty || measurementForm.submitted)"
                         class="error jquery-validation-error small form-text invalid-feedback">Diastólica mínima é
                    40 mmHg</label>
                  <label *ngIf="!maxima.valid && maxima.hasError('max') && (maxima.dirty || measurementForm.submitted)"
                         class="error jquery-validation-error small form-text invalid-feedback">Diastólica máxima é
                    200 mmHg</label>
                </div>
              </div>

              <div class="custom-control custom-switch switch-ativo col-md-4">
                <input type="checkbox" class="custom-control-input" id="fgMedia"
                       [(ngModel)]="measurement.fgConsideraNaMedia"
                       name="fgConsideraNaMedia" #fgConsideraNaMedia="ngModel">
                <label class="custom-control-label" for="fgMedia">Considera para Média</label>
              </div>
            </div>
          </div>

          <div class="col-md-4" style="margin-top: 24px;">
            <div class="form-group">
              <button class="btn btn-primary" style="float: right;">Adicionar</button>

            </div>
          </div>
        </form>
        <hr *ngIf="bloodPressureStatement.medicoes.length >= 1">
        <div class="row" *ngFor="let medicao of bloodPressureStatement.medicoes">
          <div class="form-group form-check custom-switch switch-ativo" style="padding-top: 18px;">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="medicao.fgDownload">
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label> Data </label>
              <input type="text" class="form-control" value="{{medicao.dtMedicao}}" disabled>
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label> Horário </label>
              <input type="text" class="form-control" value="{{medicao.horario}}" disabled>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label> Sistólica (PAS) mmHg* </label>
              <input type="text" class="form-control" value="{{medicao.minima}}" disabled>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label> Diastólica (PAD) mmHg* </label>
              <input type="text" class="form-control" value="{{medicao.maxima}}" disabled>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label> Braço Medição</label>
              <div style="display: flex">
                <div class="custom-control custom-radio" style="padding-right: 15px; padding-top: 5px;">
                  <input type="radio" class="custom-control-input" disabled
                         required [(ngModel)]="medicao.bracoMedicao" [ngModelOptions]="{standalone: true}"
                         [value]="'DIREITO'">
                  <label class="custom-control-label" for="customControlValidation2">Direito</label>
                </div>
                <div class="custom-control custom-radio mb-3" style="padding-top: 5px;">
                  <input type="radio" [(ngModel)]="medicao.bracoMedicao"
                         class="custom-control-input" disabled
                         name="radio-stacked" [ngModelOptions]="{standalone: true}"
                         required [value]="'ESQUERDO'">
                  <label class="custom-control-label" for="customControlValidation3">Esquerdo</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3" style="margin-top: 24px;">
            <div class="form-group">
              <button class="btn btn-primary" type="button" style="float: right;"
                      (click)="onRemoveMeasurement(medicao)">Remover
              </button>
              <label *ngIf="medicao.fgConsideraNaMedia" style="float: right; padding-right: 24px; padding-top: 6px;">(Considera
                para Média)</label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="editMode && bloodPressureStatement.medicoes != null  && bloodPressureStatement.medicoes.length > 0">
          <button class="btn btn-primary" type="button" style="float: right;" [disabled]="shouldImprimirSelecionados(bloodPressureStatement)"
                  (click)="this.downloadPdf(bloodPressureStatement)">Imprimir Selecionados
          </button>
        </div>
      </app-card>
    </div>
  </div>
  <mat-fab-menu color="primary" class="add-button" direction="top"
                [fabButtons]="fabButtonsRandom" icon="check"
                (onFabMenuItemSelected)="$event == 2 ?  save() : validateOpen()">
  </mat-fab-menu>

</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Declaração de Pressão Arterial?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="bloodPressureStatement.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="bloodPressureStatement.servico.vlCobrado"
               [disabled]="!bloodPressureStatement.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || myForm.controls.paciente.touched)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="bloodPressureStatement.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="onEncerrar()">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
