import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-vencida',
  templateUrl: './demo-vencida.component.html',
  styleUrls: ['./demo-vencida.component.scss']
})
export class DemoVencidaComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

}
