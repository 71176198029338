import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Pharmaceutical} from '../models/pharmaceutical';
import {NgForm} from '@angular/forms';
import {ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import {CompanyService} from '../services/company.service';

@Component({
  selector: 'app-auth',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  pharmaceutical = {} as Pharmaceutical;

  constructor(private authService: AuthService, private empresaService: CompanyService, private toastyService: ToastyService, private router: Router, private companyService: CompanyService) {
    sessionStorage.removeItem('jwttoken');
    sessionStorage.removeItem('schema');
  }

  ngOnInit() {

  }

  doLogin(authForm: NgForm) {
    if (authForm.valid) {
      this.authService.doLogin(this.pharmaceutical).subscribe(async (auth) => {
        sessionStorage.setItem('jwttoken', auth.jwttoken);

        console.log(auth)
        this.empresaService.getOneByOauth().subscribe((userLogado) => {

          console.log(userLogado)

          if (userLogado.empresa.flagIsDemo && userLogado.empresa.flagDemoVencida) {

            this.router.navigate(['/demovencida'])

            setTimeout(() => {
              sessionStorage.removeItem('jwttoken');
            }, 2000)
          } else {
            sessionStorage.setItem('schema', userLogado.empresa.schema);

            this.companyService.getOne().subscribe((company) => {

              console.log(company)
              if (!company) {
                this.router.navigate(['/empresa']).then();
              } else {

                this.router.navigate(['/dashboard']);
              }
            });
          }
        }, error => {
          console.log(error)
        })
        // const empresa = (await this.empresaService.getOneByOauth().toPromise() as any).empresa;


      }, error => {
        this.showErrorToast(error);
      });
    }
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

}
