<form #myForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Gestão de Medicação" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select [ngClass]="{'invalido': !farmaceutico.valid && myForm.submitted}"
                         class="ng-select" required [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                         #farmaceutico="ngModel"></ng-select>

              <label *ngIf="!farmaceutico.valid && myForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select [ngClass]="{'invalido': !paciente.valid && myForm.submitted}"
                         class="ng-select" required [options]="patientListOption"
                         [(ngModel)]="patientOption" name="paciente"
                         #paciente="ngModel"></ng-select>

              <label *ngIf="!paciente.valid && myForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="medicationManagement.observacao"
                        name="observacoes"
                        id="textAreaObservacoes" rows="8"
                        [ngClass]="{'is-invalid': !observacoes.valid && (observacoes.dirty || myForm.submitted)}"
                        #observacoes="ngModel" maxlength="1024"></textarea>
            </div>
          </div>
        </div>

        <form #medicineConsumedForm="ngForm" class="editForm" novalidate
              (ngSubmit)="onAddMedication(medicineConsumedForm)">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label> Medicamento* </label>
                <ng-select [ngClass]="{'invalido': !medicamento.valid && myForm.submitted}"
                           class="ng-select" required [options]="medicamentListOption"
                           [(ngModel)]="medicineConsumed.medicamento.idMedicamento" name="medicamento"
                           #medicamento="ngModel"></ng-select>

                <label *ngIf="!medicamento.valid && myForm.submitted"
                       class="error">Medicamento é obrigatório</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <button class="btn btn-primary" style="margin-top: 28px;">Adicionar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Manhã</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.manhaAntes" name="manhaAntes"
                       #manhaAntes="ngModel">
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.manhaDepois" name="manhaDepois"
                       #manhaDepois="ngModel">
              </div>
            </div>

            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Almoço</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.almocoAntes" name="almocoAntes"
                       #almocoAntes="ngModel">
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.almocoDepois" name="almocoDepois"
                       #almocoDepois="ngModel">
              </div>
            </div>

            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Tarde</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.tardeAntes" name="tardeAntes"
                       #tardeAntes="ngModel">
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.tardeDepois" name="tardeDepois"
                       #tardeDepois="ngModel">
              </div>
            </div>

            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Noite</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.noiteAntes" name="noiteAntes"
                       #noiteAntes="ngModel">
                <input type="text" class="form-control" [(ngModel)]="medicineConsumed.noiteDepois" name="noiteDepois"
                       #noiteDepois="ngModel">
              </div>
            </div>
          </div>
        </form>

        <hr *ngIf="medicationManagement.medicamentos.length >= 1">

        <div *ngFor="let medicamento of medicationManagement.medicamentos">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label> Medicamento </label>
                <input type="text" class="form-control" value="{{medicamento.medicamento.descricao}}" disabled>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <button class="btn btn-primary" type="button" style="margin-top: 28px;"
                        (click)="onRemoveMedication(medicamento.idMedicamentoConsumido)">
                  Remover
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Manhã</span>
                </div>
                <input type="text" class="form-control" value="{{medicamento.manhaAntes}}" disabled>
                <input type="text" class="form-control" value="{{medicamento.manhaDepois}}" disabled>
              </div>
            </div>

            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Almoço</span>
                </div>
                <input type="text" class="form-control" value="{{medicamento.almocoAntes}}" disabled>
                <input type="text" class="form-control" value="{{medicamento.almocoDepois}}" disabled>
              </div>
            </div>

            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Tarde</span>
                </div>
                <input type="text" class="form-control" value="{{medicamento.tardeAntes}}" disabled>
                <input type="text" class="form-control" value="{{medicamento.tardeDepois}}" disabled>
              </div>
            </div>

            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Noite</span>
                </div>
                <input type="text" class="form-control" value="{{medicamento.noiteAntes}}" disabled>
                <input type="text" class="form-control" value="{{medicamento.noiteDepois}}" disabled>
              </div>
            </div>
          </div>
          <br>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="validateOpen(myForm)" matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Gestão de Medicação?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="medicationManagement.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="medicationManagement.servico.vlCobrado"
               [disabled]="!medicationManagement.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || myForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="medicationManagement.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(myForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
