import {CompanyFormComponent} from './company/company-form/company-form.component';
import {NebulizerFormComponent} from './complement/nebulizer-form/nebulizer-form.component';
import {EarringFormComponent} from './complement/earring-form/earring-form.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {PharmaceuticalFormComponent} from './pharmaceutical/pharmaceutical-form/pharmaceutical-form.component';
import {PharmaceuticalListComponent} from './pharmaceutical/pharmaceutical-list/pharmaceutical-list.component';
import {PatientListComponent} from './patient/patient-list/patient-list.component';
import {PatientFormComponent} from './patient/patient-form/patient-form.component';
import {SigninComponent} from './signin/signin.component';
import {PrescriptionComponent} from './functionality/prescription/prescription.component';
import {
  PharmaceuticalRecordSoapComponent
} from './functionality/pharmaceutical-record-soap/pharmaceutical-record-soap.component';
import {EarringApplicationComponent} from './functionality/earring-application/earring-application.component';
import {TemperatureStatementComponent} from './functionality/temperature-statement/temperature-statement.component';
import {EquipmentFormComponent} from './complement/equipment-form/equipment-form.component';
import {InjectableApplicationComponent} from './functionality/injectable-application/injectable-application.component';
import {
  BioimpedanceEvaluationComponent
} from './functionality/bioimpedance-evaluation/bioimpedance-evaluation.component';
import {
  CholesterolDeclarationComponent
} from './functionality/cholesterol-declaration/cholesterol-declaration.component';
import {GlucoseStatementComponent} from './functionality/glucose-statement/glucose-statement.component';
import {
  BloodPressureStatementComponent
} from './functionality/blood-pressure-statement/blood-pressure-statement.component';
import {MedicationManagementComponent} from './functionality/medication-management/medication-management.component';
import {NebulizationComponent} from './functionality/nebulization/nebulization.component';
import {
  PharmaceuticalRecordAtfComponent
} from './functionality/pharmaceutical-record-atf/pharmaceutical-record-atf.component';
import {MedicamentFormComponent} from './complement/medicament-form/medicament-form.component';
import {ConfigsComponent} from './configs/configs.component';
import {DemoVencidaComponent} from './demo-vencida/demo-vencida.component';
import {AgendaComponent} from './agenda/agenda.component';
import {PrescricaoComplementarListComponent} from "./prescricao/prescricao-list/prescricao-complementar-list.component";
import {PrescricaoComplementarFormComponent} from "./prescricao/prescricao-form/prescricao-complementar-form.component";
import {TesteCovidComponent} from "./functionality/teste-covid/teste-covid.component";
import {AtendimentoComponent} from "./reports/atendimento/atendimento.component";

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        component: PatientFormComponent,
        path: 'paciente/form'
      },
      {
        component: PatientFormComponent,
        path: 'paciente/form/edit'
      },
      {
        component: PatientListComponent,
        path: 'paciente'
      },
      {
        component: PharmaceuticalFormComponent,
        path: 'farmaceutico/form'
      },
      {
        component: PharmaceuticalFormComponent,
        path: 'farmaceutico/form/edit'
      },
      {
        component: PrescriptionComponent,
        path: 'prescricao'
      },
      {
        component: EarringApplicationComponent,
        path: 'aplicacao/brinco'
      },
      {
        component: TesteCovidComponent,
        path: 'teste-covid'
      },
      {
        component: InjectableApplicationComponent,
        path: 'aplicacao/injetaveis'
      },
      {
        component: BioimpedanceEvaluationComponent,
        path: 'avaliacao/bioimpedancia'
      },
      {
        component: CholesterolDeclarationComponent,
        path: 'declaracao/colesterol'
      },
      {
        component: GlucoseStatementComponent,
        path: 'declaracao/glicemia'
      },
      {
        component: BloodPressureStatementComponent,
        path: 'declaracao/pressao-arterial'
      },
      {
        component: MedicationManagementComponent,
        path: 'gestao/medicacao'
      },
      {
        component: NebulizationComponent,
        path: 'nebulizacao'
      },
      {
        component: TemperatureStatementComponent,
        path: 'declaracao/temperatura'
      },
      {
        component: PharmaceuticalRecordSoapComponent,
        path: 'prontuario-soap'
      },
      {
        component: PharmaceuticalRecordSoapComponent,
        path: 'prontuario-soap/edit/:id'
      },
      {
        component: PharmaceuticalRecordAtfComponent,
        path: 'prontuario-atf'
      },
      {
        component: PharmaceuticalRecordAtfComponent,
        path: 'prontuario-atf/edit/:id'
      },
      {
        component: CompanyFormComponent,
        path: 'empresa'
      },
      {
        component: PharmaceuticalListComponent,
        path: 'farmaceutico'
      },
      {
        component: EarringFormComponent,
        path: 'brinco/form'
      },
      {
        component: EarringFormComponent,
        path: 'brinco/form/edit'
      },
      {
        component: EquipmentFormComponent,
        path: 'equipamento/form'
      },
      {
        component: EquipmentFormComponent,
        path: 'equipamento/form/edit'
      },
      {
        component: MedicamentFormComponent,
        path: 'medicamento/form'
      },
      {
        component: MedicamentFormComponent,
        path: 'medicamento/form/edit'
      },
      {
        component: NebulizerFormComponent,
        path: 'nebulizador/form'
      },
      {
        component: NebulizerFormComponent,
        path: 'nebulizador/form/edit'
      },
      {
        component: ConfigsComponent,
        path: 'configs'
      },
      {
        component: AgendaComponent,
        path: 'agenda'
      },
      {
        component: PrescricaoComplementarListComponent,
        path: 'prescricao-complementar'
      },
      {
        component: PrescricaoComplementarFormComponent,
        path: 'prescricao-complementar/form'
      },
      {
        component: PrescricaoComplementarFormComponent,
        path: 'prescricao-complementar/form/edit'
      },
      {
        component: AtendimentoComponent,
        path: 'relatorio/atendimento'
      },
    ]
  },

  {
    path: '',
    component: AuthComponent,
    children: [
      {
        component: SigninComponent,
        path: 'auth'
      },
      {
        component: DemoVencidaComponent,
        path: 'demovencida'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
