<form #myForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Declaração de Glicemia" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select
                [ngClass]="{'invalido': !farmaceutico.valid && myForm.submitted}"
                class="ng-select" required [options]="pharmaceuticalListOption"
                [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                #farmaceutico="ngModel"></ng-select>

              <label *ngIf="!farmaceutico.valid && myForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select [ngClass]="{'invalido': !paciente.valid && myForm.submitted}"
                         class="ng-select" required [options]="patientListOption"
                         [(ngModel)]="patientOption" name="paciente"
                         #paciente="ngModel"></ng-select>
              <label *ngIf="!paciente.valid && myForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Horário* </label>
              <input type="text" class="form-control" [(ngModel)]="glucoseStatement.horario" name="horario"
                     [ngClass]="{'is-invalid': !horario.valid && (horario.dirty || myForm.submitted)}"
                     #horario="ngModel" required mask="Hh:m0" minlength="4" [dropSpecialCharacters]="false">
              <label *ngIf="!horario.valid && (horario.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Horário incorreto</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label> mg/dL* </label>
              <input type="number" class="form-control" [(ngModel)]="glucoseStatement.mgDl" name="mgDl" #mgDl="ngModel"
                     [ngClass]="{'is-invalid': !mgDl.valid && (mgDl.dirty || myForm.submitted)}"
                     required
                     (change)="glucoseStatement.mgDl = mgDl.value.replace(',', '.');">
              <label *ngIf="!mgDl.valid && (mgDl.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">mg/dL incorreto</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" style="margin-top: 22px; float: right;">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="fgJejum" [(ngModel)]="glucoseStatement.fgJejum" name="fgJejum"
                       #fgJejum="ngModel">
                <label for="fgJejum" class="cr"></label>
              </div>
              <label>Jejum</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label> Horas em jejum </label>
              <input type="text" class="form-control" [(ngModel)]="glucoseStatement.horasJejum" name="horasJejum"
                     #horasJejum="ngModel"
                     [ngClass]="{'is-invalid': !horasJejum.valid && fgJejum.value && (horasJejum.dirty || myForm.submitted)}"
                     [required]="fgJejum.value" [disabled]="!fgJejum.value" mask="Hh">
              <label *ngIf="fgJejum.value && !horasJejum.valid && (horasJejum.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Horas em jejum
                incorreto</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="glucoseStatement.observacao"
                        name="observacoes" id="textAreaObservacoes" rows="8" #observacoes="ngModel"
                        maxlength="1024"></textarea>
            </div>
          </div>
          <div class="col-md-5">
            <table class="table table-striped table-bordered nowrap table-hover" style="margin-top: 30px;">
              <thead>
              <tr>
                <th>Categoria</th>
                <th>Jejum</th>
                <th>Pós-prandial</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Normal</th>
                <th>até 99 mg/dL</th>
                <th>menor que 126 mg/dL</th>
              </tr>
              <tr>
                <th>Tolerância</th>
                <th>100 a 110 mg/dL</th>
                <th>menor que 140 mg/dL</th>
              </tr>
              <tr>
                <th>Pré-diabetes</th>
                <th>111 a 126 mg/dL</th>
                <th>entre 140 e 199 mg/dL</th>
              </tr>
              <tr>
                <th>Diabetes Melitus</th>
                <th>maior 126 mg/dL</th>
                <th>maior que 200 mg/dL</th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="validateOpen(myForm)"
          matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Declaração de Glicemia?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="glucoseStatement.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="glucoseStatement.servico.vlCobrado"
               [disabled]="!glucoseStatement.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || myForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="glucoseStatement.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(myForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
