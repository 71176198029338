<form #medicamentForm="ngForm" class="editForm" novalidate (ngSubmit)="save(medicamentForm)">
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Medicamento" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-2" style="padding-right: 8px">
            <div class="form-group">
              <label> Código </label>
              <input type="text" class="form-control" [(ngModel)]="medicament.idMedicamento" name="idMedicamento"
                     disabled>
            </div>
          </div>
          <div class="col-md-2" style="padding-left: 0">
            <div class="form-group" style="padding-top: 30px;">
              <button class="btn btn-primary btn-icon btn-msg-send" type="button"
                      (click)="search(); searchModal.show()">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-11">
            <div class="form-group">
              <label> Descrição* </label>
              <input type="text" class="form-control" [(ngModel)]="medicament.descricao" name="descricao"
                     [ngClass]="{'is-invalid': !descricao.valid && (descricao.dirty || medicamentForm.submitted)}"
                     #descricao="ngModel" minlength="2"
                     maxlength="64"
                     required>
              <label *ngIf="!descricao.valid && (descricao.dirty || medicamentForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Medicamento
                Incorreto </label>
            </div>
          </div>
          <div class="custom-control custom-switch col-md-1 switch-ativo">
            <input type="checkbox" class="custom-control-input" id="customswitch1" [(ngModel)]="medicament.flagAtivo"
                   name="fgAtivo">
            <label class="custom-control-label" for="customswitch1">Ativo</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label> Registro </label>
              <input type="text" class="form-control" [(ngModel)]="medicament.registro" name="registro"
                     [ngClass]="{'is-invalid': !registro.valid && (registro.dirty || medicamentForm.submitted)}"
                     #registro="ngModel" minlength="0"
                     maxlength="40">
              <label class="error jquery-validation-error small form-text invalid-feedback">Registro Incorreto </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label> Código de Barras </label>
              <input type="text" class="form-control" [(ngModel)]="medicament.codigoBarras" name="codigoBarras"
                     [ngClass]="{'is-invalid': !codigoBarras.valid && (codigoBarras.dirty || medicamentForm.submitted)}"
                     #codigoBarras="ngModel" minlength="1"
                     maxlength="15">
              <label *ngIf="!codigoBarras.valid && (codigoBarras.dirty || medicamentForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Código de Barras
                Incorreto </label>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" matTooltip="Salvar"><i
    class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>
<app-ui-modal #searchModal>
  <div class="app-modal-header">
    <h5 class="modal-title">Pesquisa</h5>
  </div>
  <div class="app-modal-body">
    <div class="row" style="margin-top: -12px;">
      <div class="col-sm-12">
        <div class="custom-control custom-switch" style="float: right; padding-top: 6px; padding-bottom: 12px">
          <input type="checkbox" class="custom-control-input" id="customswitch12" [(ngModel)]="buscarAtivos"
                 name="fgAtivo" (ngModelChange)="search()">
          <label class="custom-control-label" for="customswitch12">Buscar somente ativos</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <ng-select [ngClass]="'ng-select'" [options]="medicamentListOption" [(ngModel)]="id" name="medicamentToSave"
                     #medicamentToSave="ngModel" required></ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="searchModal.hide()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="edit()">Editar</button>
  </div>
</app-ui-modal>
