<form #prescriptionForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Prescrição" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select [ngClass]="{'invalido': !farmaceutico.valid && prescriptionForm.submitted}"
                         class="ng-select" required [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                         #farmaceutico="ngModel"></ng-select>

              <label *ngIf="!farmaceutico.valid && prescriptionForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select [ngClass]="{'invalido': !paciente.valid && prescriptionForm.submitted}"
                         class="ng-select" required [options]="patientListOption"
                         [(ngModel)]="patientOption" name="paciente"
                         #paciente="ngModel"></ng-select>

              <label *ngIf="!paciente.valid && prescriptionForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaPrescricao">
                Prescrição Farmacológica
                <br>
                <span style="font-size: 12px">
                                Formulação, concentração/dinamização, forma farmacêutica e via de administração, dose, frequência de administração do medicamento e duração do tratamento
                              </span>
              </label>
              <mwl-text-input-autocomplete-container>
                <textarea
                  id="textAreaPrescricao"
                  placeholder="Digite / para pesquisar prescrições farmacológicas pré-cadastradas..."
                  class="form-control"
                  rows="20" required maxlength="1999"
                  [(ngModel)]="prescription.prescricao"
                  #prescricao="ngModel"
                  [triggerCharacter]="'/'"
                  [ngClass]="{'is-invalid': !prescricao.valid && (prescricao.dirty || prescriptionForm.submitted)}"
                  mwlTextInputAutocomplete
                  [findChoices]="findChoices(prescricaoComplementarFarmacologicaList)"
                  [getChoiceLabel]="getChoiceLabel(prescricaoComplementarFarmacologicaList)"
                  [ngModelOptions]="{standalone: true}">
                </textarea>
              </mwl-text-input-autocomplete-container>
              <label *ngIf="!prescricao.valid && prescriptionForm.submitted"
                     class="error"> Prescrição Farmacológica é obrigatória</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaOutrasRecomendacoes">Prescrição não Farmacológica</label>
              <mwl-text-input-autocomplete-container>
              <textarea type="text" class="form-control" [(ngModel)]="prescription.outrasRecomendacoes"
                        name="outrasRecomendacoes"
                        placeholder="Digite / para pesquisar prescrições não farmacológicas pré-cadastradas..."
                        mwlTextInputAutocomplete
                        [triggerCharacter]="'/'"
                        [findChoices]="findChoices(prescricaoComplementarNaoFarmacologicaList)"
                        [getChoiceLabel]="getChoiceLabel(prescricaoComplementarNaoFarmacologicaList)"
                        [ngModelOptions]="{standalone: true}"
                        id="textAreaOutrasRecomendacoes" rows="8"
                        [ngClass]="{'is-invalid': !outrasRecomendacoes.valid && (outrasRecomendacoes.dirty || prescriptionForm.submitted)}"
                        #outrasRecomendacoes="ngModel" maxlength="1999" required></textarea>

              </mwl-text-input-autocomplete-container>
              <label *ngIf="!outrasRecomendacoes.valid && prescriptionForm.submitted"
                     class="error"> Prescrição não Farmacológica é obrigatória</label>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="validateOpen(prescriptionForm)" matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Prescrição?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="prescription.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="prescription.servico.vlCobrado"
               [disabled]="!prescription.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || prescriptionForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="prescription.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(prescriptionForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
