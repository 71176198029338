<ul class="navbar-nav ml-auto">

  <li *ngIf="isDemo && !diasRestantes">
    <div class="drp-user" placement="auto">
      <span style="color: white;">Período de demonstração ativo!</span>
    </div>
  </li>
  <li *ngIf="isDemo && diasRestantes">
    <div class="drp-user" placement="auto">
      <span style="color: white; margin-right: 10px">Você possui mais <span
        style="font-weight: bold">{{diasRestantes}}</span> dias de teste! Após esse periodo seu acesso será bloqueado.</span>
      <a type="button" class="btn btn-primary" href="https://evoxgestao.com.br/#assine" target="_blank"
         style="margin-right: 8px; background-color: white; border-color: #0000; color: #73b4ff; ">Adquira um plano aqui
      </a>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <span style="padding-right: 25px; color: white">{{name}}</span>
      <a class="icon-user-header" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <i class="feather icon-user"></i>
          <a href="auth" class="dud-logout" title="Sair">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
      </div>
    </div>
  </li>
</ul>
