<form #pharmaceuticalRecordForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <a class="btn btn-outline-primary btn-sm"
         style="margin-left: calc(100% - 212px); position: absolute; z-index: 1; margin-top: 20px; color: #4099ff"
         *ngIf="view"
         (click)="openPDF()">Segunda via do PDV</a>
      <app-card cardTitle="Consulta Farmacêutico/SOAP" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label> Código </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceuticalRecord.idPronturarioFarmaceutico"
                     name="idProntuarioFarmaceutico" disabled>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select [ngClass]="{'invalido': !farmaceutico.valid && pharmaceuticalRecordForm.submitted}"
                         class="ng-select" required [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                         #farmaceutico="ngModel"></ng-select>

              <label *ngIf="!farmaceutico.valid && pharmaceuticalRecordForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select [ngClass]="{'invalido': !paciente.valid && pharmaceuticalRecordForm.submitted}"
                         class="ng-select" required [options]="patientListOption"
                         [(ngModel)]="patientOption" name="paciente"
                         #paciente="ngModel"></ng-select>
              <label *ngIf="!paciente.valid && pharmaceuticalRecordForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaDadosSubjetivos">
                Dados subjetivos
                <br>
                <span style="font-size: 12px">
                  Sinais e sintomas – história clinica – tratamentos realizados
                </span>
              </label>
              <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecord.dadosSubjetivos"
                        name="dadosSubjetivos" id="textAreaDadosSubjetivos" rows="8"
                        [ngClass]="{'is-invalid': !dadosSubjetivos.valid && (dadosSubjetivos.dirty || pharmaceuticalRecordForm.submitted)}"
                        #dadosSubjetivos="ngModel" maxlength="1999" required [disabled]="view"></textarea>
              <label *ngIf="!dadosSubjetivos.valid && pharmaceuticalRecordForm.submitted"
                     class="error"> Dados subjetivos são obrigatórios</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaDadosObjetivos">
                Dados objetivos
                <br>
                <span style="font-size: 12px">
                  Dados mensurados
                </span>
              </label>
              <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecord.dadosObjetivos"
                        name="dadosObjetivos" id="textAreaDadosObjetivos" rows="8"
                        [ngClass]="{'is-invalid': !dadosObjetivos.valid && (dadosObjetivos.dirty || pharmaceuticalRecordForm.submitted)}"
                        #dadosObjetivos="ngModel" maxlength="1999" required [disabled]="view"></textarea>
              <label *ngIf="!dadosObjetivos.valid && pharmaceuticalRecordForm.submitted"
                     class="error"> Dados objetivos são obrigatórios</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaAvaliacao">Avaliação (Análise dos dados objetivos e subjetivos, necessidade de saúde, intervenções e fatores modificadores, sinais de alerta)</label>
              <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecord.avaliacao"
                        name="avaliacao" id="textAreaAvaliacao" rows="8"
                        [ngClass]="{'is-invalid': !avaliacao.valid && (avaliacao.dirty || pharmaceuticalRecordForm.submitted)}"
                        #avaliacao="ngModel" maxlength="1999" required [disabled]="view"></textarea>
              <label *ngIf="!avaliacao.valid && pharmaceuticalRecordForm.submitted"
                     class="error"> Avaliação é obrigatória</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaResultadoExame">Resultado Exames</label>
              <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecord.resultadoExame"
                        name="resultadoExame" id="textAreaResultadoExame" rows="8"
                        [ngClass]="{'is-invalid': !resultadoExame.valid && (resultadoExame.dirty || pharmaceuticalRecordForm.submitted)}"
                        #resultadoExame="ngModel" maxlength="1999" [disabled]="view"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaPlano">Plano Farmacológico (Formulação, concentração/dinamização, forma farmacêutica e via de administração, dose, frequência de administração do medicamento e duração do tratamento)</label>
              <mwl-text-input-autocomplete-container>
              <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecord.planoCuidado"
                        name="plano" id="textAreaPlano" rows="8"
                        [ngClass]="{'is-invalid': !plano.valid && (plano.dirty || pharmaceuticalRecordForm.submitted)}"
                        #plano="ngModel" maxlength="1999" required [disabled]="view"
                        mwlTextInputAutocomplete
                        placeholder="Digite / para pesquisar prescrições farmacológicas pré-cadastradas..."
                        [triggerCharacter]="'/'"
                        [findChoices]="findChoices(prescricaoComplementarFarmacologicaList)"
                        [getChoiceLabel]="getChoiceLabel(prescricaoComplementarFarmacologicaList)"></textarea>
              </mwl-text-input-autocomplete-container>
              <label *ngIf="!plano.valid && pharmaceuticalRecordForm.submitted"
                     class="error">Plano Farmacológico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaObservacao">Plano não Farmacológico</label>
              <mwl-text-input-autocomplete-container>
              <textarea type="text" class="form-control" [(ngModel)]="pharmaceuticalRecord.observacao"
                        name="observacao" id="textAreaObservacao" rows="8"
                        [ngClass]="{'is-invalid': !observacao.valid && (observacao.dirty || pharmaceuticalRecordForm.submitted)}"
                        #observacao="ngModel" maxlength="1999" required [disabled]="view"
                        mwlTextInputAutocomplete
                        placeholder="Digite / para pesquisar prescrições não farmacológicas pré-cadastradas..."
                        [triggerCharacter]="'/'"
                        [findChoices]="findChoices(prescricaoComplementarNaoFarmacologicaList)"
                        [getChoiceLabel]="getChoiceLabel(prescricaoComplementarNaoFarmacologicaList)"></textarea>
              </mwl-text-input-autocomplete-container>
              <label *ngIf="!observacao.valid && pharmaceuticalRecordForm.submitted"
                     class="error">Plano não Farmacológico é obrigatório</label>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="validateOpen(pharmaceuticalRecordForm)"
          matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Consulta Farmacêutico/SOAP?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="pharmaceuticalRecord.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="pharmaceuticalRecord.servico.vlCobrado"
               [disabled]="!pharmaceuticalRecord.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || pharmaceuticalRecordForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="pharmaceuticalRecord.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(pharmaceuticalRecordForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
