<form #configsForm="ngForm" class="editForm" novalidate (ngSubmit)="save(configsForm)">
  <div class="row">
    <div class="col-sm-12">
      <app-card [hidHeader]="true" cardClass="tab-card">
        <h5 class="mb-3">Configurações</h5>
        <hr>
        <ngb-tabset type="pills">
          <ngb-tab title="Geral" class="mb-3" id="2">
            <ng-template ngbTabContent>
              <hr>
              <h6 class="mb-3">PDF</h6>
              <div class="row">
                <div class="col-md-6">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input" id="fgImprimeViaFarmaceutico"
                           [(ngModel)]="configGeral.fgImprimeViaFarmaceutico"
                           name="fgImprimeViaFarmaceutico">
                    <label class="custom-control-label" for="fgImprimeViaFarmaceutico">Imprimir Via Farmacêutico</label>
                  </div>
                </div>
              </div>
              <hr>
              <h6 class="mb-3">Cálculo valores dos serviços</h6>
              <div class="row">
                <div class="col-md-6">
                  <div class="custom-control custom-switch" style="padding-bottom: 28px;">
                    <input type="checkbox" class="custom-control-input"
                           id="fgDesconsideraServicoAbonadoCalculoTicketMedio"
                           [(ngModel)]="configGeral.fgDesconsideraServicoAbonadoCalculoTicketMedio"
                           name="fgDesconsideraServicoAbonadoCalculoTicketMedio">
                    <label class="custom-control-label" for="fgDesconsideraServicoAbonadoCalculoTicketMedio">Desconsiderar
                      serviço abonado no cálculo de ticket médio</label>
                  </div>
                </div>
              </div>
              <hr>
              <h6 class="mb-3">Descrição da Declaração de Comparecimento</h6>
              <div class="row">
                <div class="col-md-6">
                  <textarea type="text" class="form-control"
                            [(ngModel)]="configGeral.dsDescricaoDeclaracaoComparecimentoDefault"
                            name="descricaoDeclaracaoComparecimento" id="descricaoDeclaracaoComparecimento" rows="10"
                            #descricaoDeclaracaoComparecimento="ngModel"
                            maxlength="1024"></textarea>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Serviços" class="mb-3" id="1">
            <ng-template ngbTabContent>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table datatable [dtOptions]="servicetListConfig"
                           class="table table-striped table-bordered table-hover"></table>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </app-card>
    </div>
    <button mat-fab color="primary" class="add-button" matTooltip="Salvar"><i
      class="material-icons">check</i></button>
  </div>
</form>

<app-ui-modal #modalPrecificacao>
  <div class="app-modal-header">
    <h5 class="modal-title">Editando configurações de {{editingService?.dsServico}}</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label> Preço* </label>
        <input type="text" class="form-control"
               name="vlServico_{{editingService?.idTipoServico}}" [(ngModel)]="editingService.vlServico"
               [ngClass]="{'is-invalid': !vlServico.valid && (vlServico.dirty || configsForm.submitted)}"
               #vlServico="ngModel" required
               (change)="editingService.vlServico = vlServico.value.replace(',', '.');"
               minlength="0" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">
        <label *ngIf="!vlServico.valid && (vlServico.dirty || configsForm.submitted)"
               class="error jquery-validation-error small form-text invalid-feedback">Campo obrigatório</label>
      </div>
    </div>

    <hr>

    <h6 class="mb-3">Sugerir retorno</h6>
    <div class="row">
      <div class="col-md-12">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input"
                 id="fgSugerirRetorno"
                 [(ngModel)]="editingService.fgSugerirRetorno"
                 name="fgSugerirRetorno">
          <label class="custom-control-label" for="fgSugerirRetorno">Sugerir retorno automaticamente</label>
        </div>
      </div>
    </div>
    <div class="row" style="padding-top: 8px">
      <div class="col-md-12">
        <label> Dias para retorno </label>
        <input type="text" class="form-control"
               name="vlServico_{{editingService?.idTipoServico}}" [(ngModel)]="editingService.qtdDiasRetorno"
               [ngClass]="{'is-invalid': !qtdDiasRetorno.valid && (qtdDiasRetorno.dirty || configsForm.submitted)}"
               #qtdDiasRetorno="ngModel"  [disabled]="!editingService.fgSugerirRetorno"
               minlength="0" maxlength="7">
        <label *ngIf="!qtdDiasRetorno.valid && (qtdDiasRetorno.dirty || configsForm.submitted)"
               class="error jquery-validation-error small form-text invalid-feedback">Campo obrigatório</label>
      </div>
    </div>

    <hr *ngIf="editingService.idTipoServico === 3 || editingService.idTipoServico === 14">
    <h6 *ngIf="editingService.idTipoServico === 3 || editingService.idTipoServico === 14" class="mb-3">Configuração
      adicional</h6>
    <div class="row" *ngIf="editingService.idTipoServico === 3" style="padding-top: 15px">

      <div class="col-md-12">
        <div class="form-group">
          <label for="textAreaObservacoes">Observações</label>
          <textarea type="text" class="form-control" [(ngModel)]="configGeral.dsObservacaoAplicaoBrincoDefault"
                    name="observacoes" id="textAreaObservacoes" rows="10" #observacoes="ngModel"
                    maxlength="1024"></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="editingService.idTipoServico === 14" style="padding-top: 15px">
      <div class="col-md-12">
        <div class="form-group">
          <label for="textAreaOrientacao">Orientação</label>
          <textarea type="text" class="form-control" [(ngModel)]="configGeral.dsOrientacaoCovid19Default"
                    name="orientacao" id="textAreaOrientacao" rows="10" #orientacao="ngModel"
                    maxlength="1024"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="updateServico(editingService)">Salvar</button>

  </div>
</app-ui-modal>
<ng2-toasty position="top-right"></ng2-toasty>
