import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {PharmaceuticalServiceData} from '../models/pharmaceutical-service';
import {PrescricaoComplementar} from "../models/prescricao";

@Injectable({
  providedIn: 'root'
})
export class PharmaceuticalServiceService {

  url = GlobalConstants.apiURL + 'servico';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  findByIdPatient(id: number): Observable<PharmaceuticalServiceData[]> {
    return this.httpClient.get<PharmaceuticalServiceData[]>(this.url + '/by/paciente/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  searchRelatorioAtendimento(filtros: any): Observable<any[]> {

    return this.httpClient.post<any>(this.url + '/relatorio/atendimento', filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  findIndicadores(): Observable<Map<String, String>> {
    return this.httpClient.get<Map<String, String>>(this.url + '/indicador', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCountServiceList(): Observable<Map<String, String>> {
    return this.httpClient.get<Map<String, String>>(this.url + '/count', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  countByMonth(): Observable<Map<String, String>> {
    return this.httpClient.get<Map<String, String>>(this.url + '/count/month', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    console.log(error)
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

}
