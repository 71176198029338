import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Equipment} from '../../models/equipment';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {EquipmentService} from '../../services/equipment.service';
import {IOption} from 'ng-select';

@Component({
  selector: 'app-equipament-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.scss']
})
export class EquipmentFormComponent implements OnInit {

  equipment = {
    flagAtivo: true
  } as Equipment;
  editMode = false as boolean;

  id: number;
  equipmentListOption: Array<IOption> = [];

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private activatedRoute: ActivatedRoute,
    private equipmentService: EquipmentService,
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });
  }

  ngOnInit() {
    if (this.id !== undefined) {
      this.equipmentService.getById(this.id).subscribe((equipment) => {
        this.equipment = equipment;
      });
    }
  }

  onInsert() {
    this.equipmentService.insert(this.equipment).subscribe((equipment) => {
      this.showToast('Equipamento cadastrado com sucesso!');

      this.id = equipment.idEquipamento;
      this.equipment.idEquipamento = equipment.idEquipamento;
      this.router.navigate(['/equipamento/form/edit'], {queryParams: {id: this.id}}).then();
    });
  }

  onUpdate() {
    this.equipmentService.update(this.equipment).subscribe(() => {
      this.showToast('Equipamento alterado com sucesso');
    });
  }

  save(form: NgForm) {
    if (form.valid) {
      if (this.equipment.idEquipamento !== undefined) {
        this.onUpdate();

      } else {
        this.onInsert();
      }
    }
  }

  search() {
    this.equipmentService.getAll().subscribe((equipments) => {
      this.equipmentListOption = [];
      equipments.forEach((equipment) => {
        this.equipmentListOption.push({
          value: equipment.idEquipamento + '',
          label: equipment.descricaoEquipamento
        });
      });
    });
  }

  edit() {
    if (this.id) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/equipamento/form/edit'], {queryParams: {id: this.id}}).then();
      });

    } else {
      this.showErrorToast('Selecione um equipamento');
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

}
