import {Component, OnInit} from '@angular/core';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';

@Component({
  selector: 'app-pharmaceutical-list',
  templateUrl: './pharmaceutical-list.component.html',
  styleUrls: ['./pharmaceutical-list.component.scss']
})
export class PharmaceuticalListComponent implements OnInit {

  pharmaceuticalListConfig: any = {};

  constructor(private pharmaceuticalService: PharmaceuticalService) {
  }

  ngOnInit() {
    this.pharmaceuticalListConfig = {
      ajax: (dataTablesParameters: any, callback) => {
        this.pharmaceuticalService.getAll().subscribe(pharmaceuticalList => {
          callback({
            recordsTotal: pharmaceuticalList.length,
            recordsFiltered: pharmaceuticalList.length,
            data: pharmaceuticalList,
          });
        });
      },

      info: false,
      paging: true,
      searching: true,
      pageLength: 20,
      pagingType: 'full_numbers',

      language: {
        emptyTable: 'Nenhum farmacêutico cadastrado',
        info: '',
        infoEmpty: '',
        infoFiltered: '',
        infoPostFix: '',
        decimal: '',
        thousands: '',
        lengthMenu: '',
        loadingRecords: '',
        processing: '',
        search: 'Pesquisar',
        searchPlaceholder: '',
        zeroRecords: 'Nenhum farmacêutico encontrado',
        url: '',

        paginate: {
          last: 'Último',
          next: 'Próximo',
          first: 'Primeiro',
          previous: 'Anterior',
        }
      },

      columns: [{
        title: 'Código',
        data: 'idFarmaceutico'
      }, {
        title: 'Nome',
        data: 'nome'
      }, {
        title: 'CRF',
        data: 'crf'
      }, {
        title: 'Telefone',
        searchable: false,
        data: 'contato'
      }, {
        title: 'Status',
        searchable: false,
        render: function(data: any, type: any, full: any) {
          return full.flagAtivo ? 'Ativo' : 'Inativo';
        }
      }, {
        title: 'Ações',
        searchable: false,
        render: function(data: any, type: any, full: any) {
          return '<a class="btn btn-outline-primary btn-sm" href="farmaceutico/form/edit?id=' + full.idFarmaceutico + '">Editar</a>';
        }
      }],
    };
  }

}
