<form #patientForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card [hidHeader]="true" cardClass="tab-card">
        <h5 class="mb-3">Paciente</h5>
        <hr>
        <ngb-tabset type="pills" [activeId]="activeId">
          <ngb-tab title="Principal" class="mb-3" id="1">
            <ng-template ngbTabContent>
              <h5>Dados pessoais</h5>
              <hr>
              <div class="row">
                <div class="col-md-1">
                  <div class="form-group">
                    <label> Código </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.idPaciente" name="idPaciente" disabled>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Nome* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.nome" name="name"
                           [ngClass]="{'is-invalid': !name.valid && (name.dirty || patientForm.submitted)}"
                           #name="ngModel" minlength="2"
                           maxlength="64"
                           required>
                    <label *ngIf="!name.valid && (name.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Nome incorreto</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label> Data de nascimento* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.dataNascimento" name="dataNascimento"
                           [ngClass]="{'is-invalid': ( !dataNascimento.valid || !checkDataIsValid(dataNascimento.value) || !checkDataIsAfterToday(dataNascimento.value) ) && (dataNascimento.dirty || patientForm.submitted)}"
                           mask="d0/M0/0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false"
                           #dataNascimento="ngModel" [hiddenInput]="true"
                           required>
                    <label
                      *ngIf="( !dataNascimento.valid || !checkDataIsValid(dataNascimento.value) )  && (dataNascimento.dirty || patientForm.submitted)"
                      class="error jquery-validation-error small form-text invalid-feedback">Data de nascimento
                      incorreta</label>
                    <label
                      *ngIf="( !dataNascimento.valid || !checkDataIsAfterToday(dataNascimento.value) )  && (dataNascimento.dirty || patientForm.submitted)"
                      class="error jquery-validation-error small form-text invalid-feedback">Data de nascimento
                      não pode ser maior que hoje</label>
                  </div>
                </div>

                <div class="custom-control custom-switch switch-ativo col-md-1" >
                  <input type="checkbox" class="custom-control-input" id="customswitch1" [(ngModel)]="patient.flagAtivo"
                         name="fgAtivo">
                  <label class="custom-control-label" for="customswitch1">Ativo</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> CPF </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.cpf" name="cpf"
                           [ngClass]="{'is-invalid': !cpf.valid && (cpf.dirty || patientForm.submitted)}"
                           #cpf="ngModel" minlength="11" mask="CPF_CNPJ"
                           maxlength="14" pattern="[0-9]*">
                    <label *ngIf="!cpf.valid && (cpf.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">CPF incorreto</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label> RG </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.rg" name="rg"
                           [ngClass]="{'is-invalid': !rg.valid && (rg.dirty || patientForm.submitted)}"
                           #rg="ngModel" maxlength="12" pattern="[0-9]*">
                    <label *ngIf="!rg.valid && (rg.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">RG incorreto</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label> E-mail </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.email" name="email"
                           [ngClass]="{'is-invalid': !email.valid && (email.dirty || patientForm.submitted)}"
                           #email="ngModel" maxlength="128" minlength="5">
                    <label *ngIf="!email.valid && (email.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">E-mail
                      incorreto</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Telefone residencial </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.telefone" name="telefone"
                           [ngClass]="{'is-invalid': !telefone.valid && (telefone.dirty || patientForm.submitted)}"
                           #telefone="ngModel" [textMask]="{mask: maskTelefoneResidencial}">
                    <label *ngIf="!telefone.valid && (telefone.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Telefone
                      incorreto</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label> Telefone celular* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.telefoneCelular" name="telefoneCelular"
                           [ngClass]="{'is-invalid': !telefoneCelular.valid && (telefoneCelular.dirty || patientForm.submitted)}"
                           #telefoneCelular="ngModel" minlength="10"
                           mask="(00) 0 0000-0000"
                           required>
                    <label *ngIf="!telefoneCelular.valid && (telefoneCelular.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Telefone celular
                      incorreto</label>
                  </div>
                </div>
              </div>

              <h5>Endereço</h5>
              <hr>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label> CEP* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.cep" name="cep"
                           (ngModelChange)="onCepChanged()"
                           [ngClass]="{'is-invalid': !cep.valid && (cep.dirty || patientForm.submitted)}"
                           #cep="ngModel" minlength="8"
                           maxlength="8" pattern="[0-9]*"
                           required>
                    <label *ngIf="!cep.valid && (cep.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">CEP incorreto</label>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label> Estado* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.estado" name="estado"
                           [ngClass]="{'is-invalid': !estado.valid && (estado.dirty || patientForm.submitted)}"
                           #estado="ngModel"
                           minlength="4"
                           maxlength="19"
                           required>
                    <label *ngIf="!estado.valid && (estado.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Informe um
                      estado</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label> Cidade* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.cidade" name="cidade"
                           [ngClass]="{'is-invalid': !cidade.valid && (cidade.dirty || patientForm.submitted)}"
                           #cidade="ngModel"
                           minlength="2"
                           maxlength="64"
                           required>
                    <label *ngIf="!cidade.valid && (cidade.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Informe uma
                      cidade</label>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label> Logradouro* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.logradouro" name="logradouro"
                           [ngClass]="{'is-invalid': !logradouro.valid && (logradouro.dirty || patientForm.submitted)}"
                           #logradouro="ngModel"
                           minlength="2"
                           maxlength="128"
                           required>
                    <label *ngIf="!logradouro.valid && (logradouro.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Informe um
                      logradouro</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group">
                    <label> Bairro* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.bairro" name="bairro"
                           [ngClass]="{'is-invalid': !bairro.valid && (bairro.dirty || patientForm.submitted)}"
                           #bairro="ngModel"
                           minlength="2"
                           maxlength="128"
                           required>
                    <label *ngIf="!bairro.valid && (bairro.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Informe um
                      bairro</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label> Número* </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.numero" name="numero"
                           [ngClass]="{'is-invalid': !numero.valid && (numero.dirty || patientForm.submitted)}"
                           #numero="ngModel"
                           minlength="0"
                           maxlength="16"
                           required>
                    <label *ngIf="!numero.valid && (numero.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Informe um
                      número</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label> Complemento </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.complemento" name="complemento"
                           [ngClass]="{'is-invalid': !complemento.valid && (complemento.dirty || patientForm.submitted)}"
                           #complemento="ngModel"
                           minlength="0"
                           maxlength="128">
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Complementar" id="2" [disabled]="!editMode">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Escolaridade </label>
                    <select class="form-control form-control-default" [(ngModel)]="patient.escolaridade"
                            [ngClass]="{'is-invalid': !escolaridade.valid && (escolaridade.dirty || patientForm.submitted)}"
                            name="escolaridade" #escolaridade="ngModel">
                      <option value="Analfabeto">Analfabeto</option>
                      <option value="Lê e escreve">Lê e escreve</option>
                      <option value="Ensino Fundamental Incompleto">Ensino Fundamental Incompleto</option>
                      <option value="Ensino Fundamento Completo">Ensino Fundamento Completo</option>
                      <option value="Ensino Médio Incompleto">Ensino Médio Incompleto</option>
                      <option value="Ensino Médio Completo">Ensino Médio Completo</option>
                      <option value="Superior Incompleto">Superior Incompleto</option>
                      <option value="Superior Completo">Superior Completo</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Etnia </label>
                    <select class="form-control form-control-default" [(ngModel)]="patient.raca"
                            [ngClass]="{'is-invalid': !raca.valid && (raca.dirty || patientForm.submitted)}"
                            name="raca" #raca="ngModel">
                      <option value="Branco">Branco</option>
                      <option value="Negro">Negro</option>
                      <option value="Pardo">Pardo</option>
                      <option value="Amarelo">Amarelo</option>
                      <option value="Indígena">Indígena</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Médico paciente </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.medicoPaciente" name="medicoPaciente"
                           [ngClass]="{'is-invalid': !medicoPaciente.valid && (medicoPaciente.dirty || patientForm.submitted)}"
                           #medicoPaciente="ngModel" maxlength="255">
                    <label *ngIf="!medicoPaciente.valid && (medicoPaciente.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Telefone médico paciente </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.foneMedicoPaciente"
                           name="foneMedicoPaciente"
                           [ngClass]="{'is-invalid': !foneMedicoPaciente.valid && (foneMedicoPaciente.dirty || patientForm.submitted)}"
                           #foneMedicoPaciente="ngModel" [textMask]="{mask: maskTelefoneCelular}">
                    <label *ngIf="!foneMedicoPaciente.valid && (foneMedicoPaciente.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Cuidador </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.cuidador" name="medicoPaciente"
                           [ngClass]="{'is-invalid': !cuidador.valid && (cuidador.dirty || patientForm.submitted)}"
                           #cuidador="ngModel" maxlength="255">
                    <label *ngIf="!cuidador.valid && (cuidador.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Telefone do cuidador </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.foneCuidador" name="foneCuidador"
                           [ngClass]="{'is-invalid': !foneCuidador.valid && (foneCuidador.dirty || patientForm.submitted)}"
                           #foneCuidador="ngModel" [textMask]="{mask: maskTelefoneCelular}">
                    <label *ngIf="!foneCuidador.valid && (foneCuidador.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
              </div>

              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <label> Profissão </label>
                    <input type="text" class="form-control" [(ngModel)]="patient.profissao" name="profissao"
                           [ngClass]="{'is-invalid': !profissao.valid && (profissao.dirty || patientForm.submitted)}"
                           #profissao="ngModel" maxlength="255">
                    <label *ngIf="!profissao.valid && (profissao.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Empresa que trabalha</label>
                    <input type="text" class="form-control" [(ngModel)]="patient.empresa" name="empresa"
                           [ngClass]="{'is-invalid': !empresa.valid && (empresa.dirty || patientForm.submitted)}"
                           #empresa="ngModel" maxlength="255">
                    <label *ngIf="!empresa.valid && (empresa.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Plano de Saúde</label>
                    <input type="text" class="form-control" [(ngModel)]="patient.planoSaude" name="planoSaude"
                           [ngClass]="{'is-invalid': !planoSaude.valid && (planoSaude.dirty || patientForm.submitted)}"
                           #planoSaude="ngModel" maxlength="255">
                    <label *ngIf="!planoSaude.valid && (planoSaude.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label> Estado civíl </label>
                    <select class="form-control form-control-default" [(ngModel)]="patient.estCivil"
                            [ngClass]="{'is-invalid': !estCivil.valid && (estCivil.dirty || patientForm.submitted)}"
                            name="estCivil" #estCivil="ngModel">
                      <option value="Solteiro">Solteiro</option>
                      <option value="Casado">Casado</option>
                      <option value="Separado">Separado</option>
                      <option value="Viúvo">Viúvo</option>
                      <option value="Outro">Outro</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label> Sexo </label>
                    <select class="form-control form-control-default" [(ngModel)]="tpGenero"
                            [ngClass]="{'is-invalid': !tpGeneroField.valid && (tpGeneroField.dirty || patientForm.submitted)}"
                            name="tpGeneroField" #tpGeneroField="ngModel">
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaAlergia">Alergias</label>
                    <textarea type="text" class="form-control" [(ngModel)]="patient.alergias" name="alergias"
                              id="textAreaAlergia" rows="3"
                              [ngClass]="{'is-invalid': !alergias.valid && (alergias.dirty || patientForm.submitted)}"
                              #alergias="ngModel" maxlength="1024"></textarea>
                    <label *ngIf="!alergias.valid && (alergias.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="textAreaOutrasInformacoes">Outras informações</label>
                    <textarea type="text" class="form-control" [(ngModel)]="patient.outrasInformacoes"
                              name="outrasInformacoes" id="textAreaOutrasInformacoes" rows="3"
                              [ngClass]="{'is-invalid': !outrasInformacoes.valid && (outrasInformacoes.dirty || patientForm.submitted)}"
                              #outrasInformacoes="ngModel" maxlength="1024"></textarea>
                    <label *ngIf="!outrasInformacoes.valid && (outrasInformacoes.dirty || patientForm.submitted)"
                           class="error jquery-validation-error small form-text invalid-feedback">Dados
                      incorretos</label>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Serviços" id="3" [disabled]="!editMode">
            <ng-template ngbTabContent>
              <h5 style="text-align: center;" *ngIf="pharmaceuticalServiceList.length <= 0">Nenhum serviço
                realizado</h5>
              <app-card *ngFor="let pharmaceuticalService of pharmaceuticalServiceList" [hidHeader]="true"
                        cardClass="tab-card">
                <div>
                  <b>{{pharmaceuticalService.dsServico}}</b>
                  <a class="btn btn-outline-primary btn-sm" style="float: right; color: #4099ff"
                     (click)="openPDF(pharmaceuticalService)">{{getHrefLabelByPharmaceticalService(pharmaceuticalService)}}</a>
                  <br><br>
                </div>
                <div>
                  Farmacêutico: {{pharmaceuticalService.farmaceutico.nome}}
                </div>
                <div>
                  Data e hora: {{pharmaceuticalService.dhCadastro | date: 'dd/MM/yyyy HH:mm:ss'}}
                </div>
              </app-card>

            </ng-template>
          </ngb-tab>
          <ngb-tab title="Anexos" id="4" [disabled]="!editMode">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-12" style="padding: 12px">
                  <a class="btn btn-outline-primary btn-sm" style="float: right;"
                     (click)="openModal()">+ Incluir Anexo</a>
                </div>
              </div>
              <h5 style="text-align: center;" *ngIf="!patient.anexos || patient.anexos.length <= 0">Nenhum arquivo anexado</h5>
              <app-card *ngFor="let anexo of patient.anexos" [hidHeader]="true"
                        cardClass="tab-card">
                <div>
                  <b>{{anexo.descricao}}</b>
                  <a class="btn   btn-sm" style="float: right; color: #4099ff"
                     (click)="downloadPDF(anexo.nmArquivo, anexo.dado)"><i style="padding-right: 5px"
                                                                           class="icon feather icon-download f-16"></i>Baixar
                    Anexo </a>
                  <br><br>
                </div>
                <div>
                  Nome do arquivo: {{anexo.nmArquivo}}
                  <a class="btn text-c-red btn-sm" style="float: right; color:#FF5370"
                     (click)="selectedToDelete = anexo; confirmarDeleteModal.show()"><i style="padding-right: 5px"
                                                                                        class="icon feather icon-trash-2 text-c-red f-16"></i>Remover</a>
                </div>
                <div>
                  Data anexo: {{anexo.dhCadastro | date: 'dd/MM/yyyy'}}
                </div>
                <div>
                  Usuário: {{anexo.farmaceutico}}
                </div>
              </app-card>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </app-card>
    </div>

    <button *ngIf="!editMode" mat-fab color="primary" class="add-button" matTooltip="Salvar" (click)="save(patientForm)"><i
      class="material-icons">check</i></button>
    <mat-fab-menu *ngIf="editMode" color="primary" class="add-button" direction="top"
                  [fabButtons]="fabButtonsRandom" icon="check"
                  (onFabMenuItemSelected)="$event == 1 ?  save(patientForm) : generatePdfDeclaracao()">
    </mat-fab-menu>
  </div>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #anexoModal>
  <div class="app-modal-header">
    <h5 class="modal-title">Incluir Anexo</h5>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label> Descrição* </label>
          <input type="text" class="form-control" [(ngModel)]="anexo.descricao" name="descricao"
                 [ngClass]="{'is-invalid': !descricao.valid && anexoSubmitted}"
                 #descricao="ngModel" minlength="1"
                 maxlength="250"
                 required>
          <label *ngIf="!descricao.valid && anexoSubmitted"
                 class="error jquery-validation-error small form-text invalid-feedback">Descrição é obrigatório</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label> Localizar </label></div>
    </div>
    <div class="row">
      <div class="teste" appDnd (fileDropped)="handleFileInput($event)">
        <input type="file" #fileDropRef id="fileDropRef" (change)="handleFileInput($event.target.files)"/>
        <img src="assets/images/ic-upload-file.svg" alt="">
        <h3>Arraste e solte o arquivo aqui</h3>
        <h3>ou</h3>
        <label for="fileDropRef">Busque o arquivo</label>
      </div>
      <div class="col-md-12">
        <label *ngIf="files.length < 1 && anexoSubmitted"
               class="label-error-arquivo ">Selecione pelo menos um arquivo</label>
      </div>

      <div class="col-md-12">
        <div class="files-list">
          <div class="single-file" *ngFor="let file of files; let i = index">
            <div class="info">
              <h4 class="name">
                {{ file?.nmArquivo }}
              </h4>
            </div>
            <a class="btn text-c-red btn-sm" style="float: right; color:#FF5370"
               (click)="deleteFile(i)"><i style="padding-right: 5px"
                                          class="icon feather icon-trash-2 text-c-red f-16"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary" (click)="gravarAnexo()">Salvar</button>
  </div>
</app-ui-modal>

<app-ui-modal #confirmarDeleteModal>
  <div class="app-modal-header">
    <h5 class="modal-title">Deletar Anexo</h5>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <p>{{getDescrModalDelete()}}</p>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="this.selectedToDelete = null; confirmarDeleteModal.hide()"
            style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="remover(selectedToDelete)">Confirmar</button>
  </div>
</app-ui-modal>
