import {Component, DoCheck, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import {GradientConfig} from '../../../../../app-config';
import {AuthService} from '../../../../../services/auth.service';
import {isNullOrUndefined} from 'util';
import {Router} from "@angular/router";
import {PharmaceuticalService} from "../../../../../services/pharmaceutical.service";

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;

  constructor(private authService: AuthService, private router: Router, private pharmaceuticalService: PharmaceuticalService) {
    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;
  }

  name = '';
  isDemo = false;
  diasRestantes = null;

  ngOnInit() {
    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            console.log(pharmaceutical)
            this.name = pharmaceutical.nome;
            const empresa = usuario.empresa;
            this.isDemo = empresa.flagIsDemo;
            const createDate = new Date(empresa.dtCadastro);
            createDate.setDate(createDate.getDate() + 91);

            const today = new Date();

            // @ts-ignore
            this.diasRestantes = parseInt((createDate - today) / (1000 * 60 * 60 * 24), 10);

            if(this.diasRestantes < 1){

              this.router.navigate(['/demovencida'])

              setTimeout(() => {
                sessionStorage.removeItem('jwttoken');
              }, 2000)
            }
            console.log(this.diasRestantes)
          }
        });

      });
    });
    this.authService.getAuthPharmaceutical().subscribe((pharmaceutical) => {


    });


  }


  ngDoCheck() {
    this.gradientConfig['rtl-layout'] = document.querySelector('body').classList.contains('elite-rtl');
  }
}
