import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrigPlugin from '@fullcalendar/timegrid';
import * as moment from 'moment';
import { IOption } from 'ng-select';
import { ServiceTypeEnum } from '../enums/service-type.enum';
import { AgendamentoService } from '../services/agendamento.service';
import { AuthService } from '../services/auth.service';
import { PatientService } from '../services/patient.service';
import { PharmaceuticalService } from '../services/pharmaceutical.service';
import { ServicoUtils } from '../services/servico-utils.service';
import { ToastService } from '../theme/shared/components/toast/toast.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgendaComponent implements OnInit {
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent; // the #calendar in the template
  @ViewChild('gridSystemModal') gridSystemModal;

  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  locales = [ptLocale];

  dataArg = null;

  enviado = false;

  farmaceuticoLogado = null;
  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];
  serviceListOption: Array<IOption> = [];

  id;
  public notSupported = false;

  constructor(private agendamentoService: AgendamentoService, private patientService: PatientService,
    private pharmaceuticalService: PharmaceuticalService, public toastEvent: ToastService,
    private authService: AuthService, private router: Router) {
  }

  eventos = [];

  dateObj = new Date();

  calendarEvents: EventInput[] = [
  ];

  serviceOption;

  colorVencido = '#79a1d0';
  colorAtual = '#4099ff'

  ngOnInit() {

    this.serviceListOption = [
      {
        value: ServiceTypeEnum.APLICACAO_BRINCO + "",
        label: 'Aplicação de Brinco'
      },
      {
        value: ServiceTypeEnum.APLICACAO_INJETAVEL + "",
        label: 'Aplicação de Injetáveis'
      },
      {
        value: ServiceTypeEnum.AVALIACAO_BIOIMPEDANCIA + "",
        label: 'Avaliação de Bioimpedância'
      },
      {
        value: ServiceTypeEnum.DECLARACAO_TEMPERATURA + "",
        label: 'Declaração de Temperatura'
      },
      {
        value: ServiceTypeEnum.DECLARACAO_COLESTEROL + "",
        label: 'Declaração de Colesterol'
      },
      {
        value: ServiceTypeEnum.DECLARACAO_GLICEMIA + "",
        label: 'Declaração de Glicemia'
      },
      {
        value: ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL + "",
        label: 'Declaração de Pressão Arterial'
      },
      {
        value: ServiceTypeEnum.GESTAO_MEDICACAO + "",
        label: 'Gestão de Medicação'
      },
      {
        value: ServiceTypeEnum.NEBULIZACAO + "",
        label: 'Nebulização'
      },
      {
        value: ServiceTypeEnum.PRESCRICAO + "",
        label: 'Prescrição Farmaceutica'
      },
      {
        value: ServiceTypeEnum.PRONTUARIO_SOAP + "",
        label: 'Consulta Farmacêutico/SOAP'
      },
      {
        value: ServiceTypeEnum.PROTOCOLO_ATF + "",
        label: 'Revisão de Farmacoterapia'
      },
      {
        value: ServiceTypeEnum.SERVICO_COVID + "",
        label: 'Teste rápido para COVID-19'
      },
    ]

    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });
    });

    this.authService.getAuthPharmaceutical().subscribe((pharmaceutical) => {
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });
        this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
        this.farmaceuticoLogado = pharmaceutical;
      });
    });

    this.getEventos();

    const isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIE) {
      this.notSupported = true;
    }
  }

  getEventos() {
    this.agendamentoService.getAll().subscribe(list => {
      this.calendarEvents = []
      this.eventos = list;
      if (list.length > 0) {

        list.forEach((agendamento) => {
          this.calendarEvents = this.calendarEvents.concat(
            {
              id: agendamento.id,
              title: agendamento.paciente.nome + " - " + ServicoUtils.nomeServico(agendamento.tpServico),
              start: agendamento.dtInicio,
              // end: formatDate(agendamento.dtFim, 'yyyy-MM-dd hh:mm:ss', 'pt-BR'),
              borderColor: new Date(agendamento.dtInicio) < new Date() ? this.colorVencido : this.colorAtual,
              backgroundColor:  new Date(agendamento.dtInicio) < new Date() ? this.colorVencido : this.colorAtual,
              textColor: '#fff',
            }
          );
        });
      }

    });
  }

  remove(){
    this.agendamentoService.delete({
      id: this.item.id

    }).subscribe(agendamento => {
      this.patientOption = null;
      this.serviceOption = null;
      this.pharmaceuticalOption = this.farmaceuticoLogado.idFarmaceutico + '';
      this.getEventos();
      this.gridSystemModal.hide();
    }, (error) => {
      if (error && error.error && error.error.message) {
        // this.showErrorToast(error.error.message);

      } else {
        // this.showErrorToast('Não foi possível concluir o cadastro');
      }
    });

  }

  dataHora;

  isUpdate = false;

  handleDateClick(arg) {

    this.isUpdate = false;
    this.dataHora = formatDate(arg.date, 'dd/MM/yyy hh:mm', 'pt-BR');
    this.dataArg = arg;
    this.gridSystemModal.show()
  }

  item;

  updateDateClick(arg) {
    this.isUpdate = true;
    this.item = this.eventos.find(x => x.id == (arg.event.id));
    console.log(arg)
    console.log(this.serviceListOption.find(x => x.value == this.item.tpServico).value)
    this.dataHora = formatDate(this.item.dtInicio, 'dd/MM/yyy hh:mm', 'pt-BR');
    this.patientOption = this.patientListOption.find(x => x.value == this.item.paciente.idPaciente).value
    this.serviceOption = this.serviceListOption.find(x => x.value == this.item.tpServico).value
    this.pharmaceuticalOption = this.pharmaceuticalListOption.find(x => x.value == this.item.farmaceutico?.idFarmaceutico).value

    this.gridSystemModal.show();

  }

  addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  cancel() {
    this.patientOption = null;
    this.serviceOption = null;
    this.pharmaceuticalOption = this.farmaceuticoLogado.idFarmaceutico + '';
    this.item = null;
    this.gridSystemModal.hide();
  }

  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];
  save() {
    this.enviado = true;
    if (!this.pharmaceuticalOption) {
      // this.showErrorToast('Informe um farmacêutico');

    } else if (!this.patientOption) {
      // this.showErrorToast('Informe um paciente');

    } else {
      if (!this.isUpdate) {
        var dateMomentObject = moment(this.dataHora, "DD/MM/YYYY hh:mm");
        this.agendamentoService.insert({
          dsTitulo: 'Ainda teste',
          dtInicio: dateMomentObject.toDate(),
          color: '#a389d4',
          farmaceutico: {
            idFarmaceutico: this.pharmaceuticalOption
          },
          paciente: {
            idPaciente: this.patientOption
          },
          tpServico: this.serviceOption
        }).subscribe(agendamento => {
          this.calendarEvents = this.calendarEvents.concat({
            id: agendamento.id,
            title: this.pacienteNome(this.patientOption) + " - " + ServicoUtils.nomeServico(agendamento.tpServico),
            start: formatDate(agendamento.dtInicio, 'yyyy-MM-dd hh:mm:ss', 'pt-BR'),
            borderColor: new Date(agendamento.dtInicio) < new Date() ? this.colorVencido : this.colorAtual,
            backgroundColor: new Date(agendamento.dtInicio) < new Date() ? this.colorVencido : this.colorAtual,
            textColor: '#fff',
            displayEventTime : false
          });
          this.patientOption = null;
          this.serviceOption = null;
          this.pharmaceuticalOption = this.farmaceuticoLogado.idFarmaceutico + '';
          this.getEventos();
          this.gridSystemModal.hide();
        }, (error) => {
          if (error && error.error && error.error.message) {
            // this.showErrorToast(error.error.message);

          } else {
            // this.showErrorToast('Não foi possível concluir o cadastro');
          }
        });
      } else {
        var dateMomentObject = moment(this.dataHora, "DD/MM/YYYY hh:mm"); // 1st argument - string, 2nd argument - format
        this.agendamentoService.update({
          id: this.item.id,
          dsTitulo: 'Ainda teste',
          dtInicio: dateMomentObject.toDate(),
          color: '#a389d4',
          farmaceutico: {
            idFarmaceutico: this.pharmaceuticalOption
          },
          paciente: {
            idPaciente: this.patientOption
          },
          tpServico: this.serviceOption,

        }).subscribe(agendamento => {
          this.calendarEvents = this.calendarEvents.concat({
            id: agendamento.id,
            title: this.pacienteNome(this.patientOption) + " - " + ServicoUtils.nomeServico(agendamento.tpServico),
            start: formatDate(agendamento.dtInicio, 'yyyy-MM-dd hh:mm:ss', 'pt-BR'),
            borderColor: new Date(agendamento.dtInicio) < new Date() ? this.colorVencido : this.colorAtual,
            backgroundColor: new Date(agendamento.dtInicio) < new Date() ? this.colorVencido : this.colorAtual,
            textColor: '#fff',
            displayEventTime : false
          });
          this.patientOption = null;
          this.serviceOption = null;

          this.pharmaceuticalOption = this.farmaceuticoLogado.idFarmaceutico + '';
          this.getEventos();
          this.gridSystemModal.hide();
        }, (error) => {
          if (error && error.error && error.error.message) {
            // this.showErrorToast(error.error.message);

          } else {
            // this.showErrorToast('Não foi possível concluir o cadastro');
          }
        });
      }
    }
  }

  redirect() {
    this.gridSystemModal.hide();
    this.router.navigate([ServicoUtils.getUrlByServico(this.serviceOption)], { state: { paciente: this.patientOption, farmaceutico: this.pharmaceuticalOption } })
  }

  pacienteNome(id) {
    id = id + "";
    return this.patientListOption.find(x => x.value == id).label;
  }
}
