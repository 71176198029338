import {Component, OnInit, ViewChild} from '@angular/core';
import {IOption} from "ng-select";
import {Router} from "@angular/router";
import {ToastyService} from "ng2-toasty";
import {PatientService} from "../../services/patient.service";
import {PharmaceuticalService} from "../../services/pharmaceutical.service";
import {TesteCovidService} from "../../services/teste-covid.service";
import {AuthService} from "../../services/auth.service";
import {ServiceTypeService} from "../../services/service-type.service";
import {ConfigGeralService} from "../../services/config-geral.service";
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {ServiceTypeEnum} from "../../enums/service-type.enum";
import {NgForm} from "@angular/forms";
import {PatientTabEnum} from "../../enums/patient-tab.enum";
import * as moment from "moment";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-teste-covid',
  templateUrl: './teste-covid.component.html',
  styleUrls: ['./teste-covid.component.scss']
})
export class TesteCovidComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalCobrar') modalCobrar;
  @ViewChild('modalRetorno') modalRetorno;
  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;
  testeCovid = {
    paciente: {},
    farmaceutico: {},
    servico: {
      fgCobrado: false
    },
    observacao: ''
  } as any;

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];

  pacienteByAgenda = null;

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private pharmaceuticalService: PharmaceuticalService,
    private testeCovidService: TesteCovidService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private configGeralService: ConfigGeralService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });

    this.configGeralService.getOne().subscribe((configGeral) => {
      this.testeCovid.orientacao = configGeral.dsOrientacaoCovid19Default;
    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });
      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });


    this.serviceTypeService.getOne(ServiceTypeEnum.SERVICO_COVID).subscribe((tpServico) => {
      this.testeCovid.servico.vlCobrado = tpServico.vlServico;
      this.testeCovid.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
      this.testeCovid.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;

    });
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  save(form: NgForm) {

    if (form.valid) {
      if (!this.pharmaceuticalOption) {
        this.showErrorToast('Informe um farmacêutico');

      } else if (!this.patientOption) {
        this.showErrorToast('Informe um paciente');

      } else {
        this.testeCovid.dhCadastro = new Date();
        this.testeCovid.paciente.idPaciente = Number(this.patientOption);
        this.testeCovid.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);
        this.testeCovid.validade = moment(this.testeCovid.validade, "DD/MM/YYYY").toDate();
        this.testeCovid.inicioSintoma = moment(this.testeCovid.inicioSintoma, "DD/MM/YYYY").toDate();

        this.testeCovidService.insert(this.testeCovid).subscribe((testeCovid) => {
          this.modalCobrar.hide();
          this.testeCovid.id = testeCovid.id;
          this.showToast('Teste de COVID-19 cadastrado com sucesso');
          if (this.testeCovid.servico.fgSugerirRetorno) {

            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + this.testeCovid.servico.qtdDiasRetorno);
            this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
            this.modalRetorno.show({backdrop: 'static'});
          } else {
            this.redirectPaciente();
          }
        }, (error) => {
          if (error && error.error && error.error.message) {
            this.showErrorToast(error.error.message);

          } else {
            this.showErrorToast('Não foi possível concluir o cadastro');
          }
        });
      }
    }
  }

  checkDataIsValid(value: any) {

    if (value != null) {
      let myList = [];

      if (value.includes("/")) {
        myList = value.split('/')
      } else {
        myList.push(value.substring(0, 2));
        myList.push(value.substring(2, 4));
        myList.push(value.substring(4, 10));
      }

      if (myList.length == 3) {
        let dt = new Date(myList[2], myList[1], myList[0]);
        return !isNaN(dt.getTime());
      }

    }
    return false;

  }

  validateOpen(form) {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private redirectPaciente() {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: this.testeCovid.paciente.idPaciente,
        pdf: this.testeCovid.id,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.SERVICO_COVID,
      }
    }).then();
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.testeCovid.farmaceutico.idFarmaceutico,
      idPaciente: this.testeCovid.paciente.idPaciente,
      tpServico: ServiceTypeEnum.SERVICO_COVID,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }

}
