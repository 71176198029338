import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {BloodPressureStatement} from '../../models/blood-pressure-statement';
import {PatientService} from '../../services/patient.service';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {BloodPressureMeasurement} from '../../models/blood-pressure-measurement';
import {ToastyService} from 'ng2-toasty';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {BloodPressureStatementService} from '../../services/blood-pressure-statement.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IOption} from 'ng-select';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {MatFabMenu} from "@angular-material-extensions/fab-menu";
import {PrescriptionService} from "../../services/prescription.service";
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-blood-pressure-statement',
  templateUrl: './blood-pressure-statement.component.html',
  styleUrls: ['./blood-pressure-statement.component.scss']
})
export class BloodPressureStatementComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalRetorno') modalRetorno;
  @ViewChild('modalCobrar') modalCobrar;
  myForm: FormGroup;
  id: number;
  editMode = false as boolean;
  bloodPressureStatement = {
    paciente: {},
    farmaceutico: {},
    medicoes: [],
    fgEncerrado: false,
    servico: {
      fgCobrado: false
    },
  } as BloodPressureStatement;
  measurement = {
    dtMedicao: this.getDateFormat(new Date()),
    fgConsideraNaMedia: false,
    bracoMedicao: 'DIREITO'
  } as BloodPressureMeasurement;
  hourMask = [/\d/, /\d/, ':', /\d/, /\d/];
  dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];


  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;
  fabButtonsRandom: MatFabMenu[] = [
    {
      id: 1,
      icon: 'check',
      tooltip: 'Concluir',
      tooltipPosition: 'left',
    },
    {
      id: 2,
      icon: 'label_important',
      tooltip: 'Gravar',
      tooltipPosition: 'left'
    },
  ];

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];
  pacienteByAgenda;

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private pharmaceuticalService: PharmaceuticalService,
    private bloodPressureStatementService: BloodPressureStatementService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private formBuilder: FormBuilder,
    private prescriptionService: PrescriptionService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });

    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  createForm() {
    this.myForm = this.formBuilder.group(
      {
        farmaceutico: [null, Validators.required],
        paciente: [null, Validators.required],
        observacao: [null]
      }
    );
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });
    this.createForm();
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });
      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });
    if (this.id !== undefined) {
      this.bloodPressureStatementService.findById(this.id).subscribe((bloodPressureStatement) => {
        if (bloodPressureStatement.fgEncerrado) {
          this.showErrorToast('Declaração de pressão alterial já concluída');
          this.goToPDF(bloodPressureStatement);

        } else {
          this.bloodPressureStatement = bloodPressureStatement;
          this.patientOption = bloodPressureStatement.paciente.idPaciente + '';
          this.pharmaceuticalOption = bloodPressureStatement.farmaceutico.idFarmaceutico + '';

          this.bloodPressureStatement.servico.fgSugerirRetorno = bloodPressureStatement.servico.tpServico.fgSugerirRetorno;
          this.bloodPressureStatement.servico.qtdDiasRetorno = bloodPressureStatement.servico.tpServico.qtdDiasRetorno;
        }
      });

    } else {
      this.serviceTypeService.getOne(ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL).subscribe((tpServico) => {
        this.bloodPressureStatement.servico.vlCobrado = tpServico.vlServico;

        this.bloodPressureStatement.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
        this.bloodPressureStatement.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
      });
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  checkIsValid() {
    this.myForm.markAllAsTouched();
    if (this.myForm.valid) {
      if (this.bloodPressureStatement.medicoes.length >= 1) {
        if (!this.pharmaceuticalOption) {
          this.showErrorToast('Informe um farmacêutico');

        } else if (!this.patientOption) {
          this.showErrorToast('Informe um paciente');

        } else {
          return true;
        }

      } else {
        this.showErrorToast('Informe no mínimo uma medição');
      }
    }

    return false;
  }

  save() {
    if (this.checkIsValid()) {
      this.bloodPressureStatement.fgEncerrado = false;
      if (this.id !== undefined) {
        this.onUpdate();

      } else {
        if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
          this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
          return;
        }
        this.onInsert();
      }
    }
  }

  goToPDF(bloodPressureStatement) {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: bloodPressureStatement.paciente.idPaciente,
        pdf: bloodPressureStatement.idDeclaracaoPressaoArterial,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL,
      }
    }).then();
  }

  downloadPdf(bloodPressureStatement) {
    const _bloodPressureStatement = Object.assign({}, bloodPressureStatement);
    _bloodPressureStatement.medicoes = _bloodPressureStatement.medicoes.filter(item => item.fgDownload);
    this.prescriptionService.pdfByUrl(this.bloodPressureStatementService.getPDFUrl(), _bloodPressureStatement);
  }

  onInsert() {
    this.bloodPressureStatement.paciente.idPaciente = Number(this.patientOption);
    this.bloodPressureStatement.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);

    this.bloodPressureStatementService.insert(this.bloodPressureStatement).subscribe((bloodPressureStatement) => {
      this.showToast('Declaração de pressão alterial cadastrada com sucesso');
      this.bloodPressureStatement = bloodPressureStatement;

      if (this.bloodPressureStatement.servico.fgSugerirRetorno || bloodPressureStatement.servico.tpServico.fgSugerirRetorno) {

        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() +  bloodPressureStatement.servico.tpServico.qtdDiasRetorno);
        this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
        this.modalRetorno.show({backdrop: 'static'});
      } else {
        this.redirectPaciente();
      }


    });
  }

  onUpdate() {
    this.bloodPressureStatementService.update(this.bloodPressureStatement).subscribe((bloodPressureStatement) => {
      this.showToast('Declaração de pressão alterial alterada com sucesso');
      this.bloodPressureStatement = bloodPressureStatement;
      console.log('bbbbbb')
      if (this.bloodPressureStatement.servico.fgSugerirRetorno || bloodPressureStatement.servico.tpServico.fgSugerirRetorno) {

        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() +  bloodPressureStatement.servico.tpServico.qtdDiasRetorno);
        this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
        this.modalRetorno.show({backdrop: 'static'});
      } else {
        this.redirectPaciente();
      }
    });
  }

  onAddMeasurement(measurementForm: NgForm) {
    if (measurementForm.valid) {
      this.measurement.idMedicaoPressaoAterial = Math.random() * -1;
      this.bloodPressureStatement.medicoes.push(this.measurement);

      this.measurement = {
        dtMedicao: this.getDateFormat(new Date()),
        fgConsideraNaMedia: false
      } as BloodPressureMeasurement;

      measurementForm.resetForm({
        dtMedicao: this.measurement.dtMedicao,
        fgConsideraNaMedia: this.measurement.fgConsideraNaMedia
      });
    }
  }

  onRemoveMeasurement(medicao) {
    const index = this.bloodPressureStatement.medicoes.indexOf(medicao);
    if (index > -1) {
      this.bloodPressureStatement.medicoes.splice(index, 1);
    }
  }

  getDateFormat(data: Date) {
    const dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(),
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();

    return diaF + '/' + mesF + '/' + anoF;
  }

  onEncerrar() {
    if (this.checkIsValid()) {
      this.bloodPressureStatement.fgEncerrado = true;

      if (this.id !== undefined) {
        this.onUpdate();

      } else {
        this.onInsert();
      }
    }
  }

  validateOpen() {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (this.checkIsValid()) {
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }

  shouldImprimirSelecionados(bloodPressureStatement: BloodPressureStatement) {
    const _bloodPressureStatement = Object.assign({}, bloodPressureStatement);
    _bloodPressureStatement.medicoes = _bloodPressureStatement.medicoes.filter(item => item.fgDownload);
    return _bloodPressureStatement.medicoes.length == 0;
  }



  private redirectPaciente() {
    if (this.bloodPressureStatement.fgEncerrado) {
      this.goToPDF(this.bloodPressureStatement);

    } else {
      this.id = this.bloodPressureStatement.idDeclaracaoPressaoArterial;
      this.router.navigate(['/declaracao/pressao-arterial'], {queryParams: {id: this.id}}).then();
    }
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.bloodPressureStatement.farmaceutico.idFarmaceutico,
      idPaciente: this.bloodPressureStatement.paciente.idPaciente,
      tpServico: ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }
}
