import {Component, OnInit} from '@angular/core';
import {PrescricaoComplementar} from "../../models/prescricao";
import {NgForm} from "@angular/forms";
import {PrescricaoComplementarService} from "../../services/prescricao-complementar.service";
import {ActivatedRoute} from "@angular/router";
import {ToastyService} from "ng2-toasty";

@Component({
  selector: 'app-prescricao-form',
  templateUrl: './prescricao-complementar-form.component.html',
  styleUrls: ['./prescricao-complementar-form.component.scss']
})
export class PrescricaoComplementarFormComponent implements OnInit {

  id;
  editMode = false;
  prescricao: PrescricaoComplementar = {
    id: null,
    nome: '',
    descricao: '',
    tipoPrescricao: 'FARMACOLOGICA'
  }

  constructor(private prescricaoComplementarService: PrescricaoComplementarService, private activatedRoute: ActivatedRoute,
              private toastyService: ToastyService) {

    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });
  }

  ngOnInit(): void {

    if (this.editMode) {
      this.prescricaoComplementarService.getById(this.id).subscribe(result => {
        this.prescricao = result;
      });
    }
  }

  save(prescriptionForm: NgForm) {

    if (!prescriptionForm.valid) {
      return;
    }

    if (this.editMode) {
      this.prescricaoComplementarService.update(this.prescricao).subscribe(result => {
        this.showToast("Prescrição complementar alterada com sucesso!")
      })
    } else {
      this.prescricaoComplementarService.insert(this.prescricao).subscribe(result => {
        this.showToast("Prescrição complementar gravada com sucesso!")
      })
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }
}
