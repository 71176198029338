import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalConstants } from '../globals/global-constants';
import { LoaderService } from '../components/loader/loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('a')
    this.loaderService.show();
    if (this.authService.hasToken() && (request.url.startsWith(GlobalConstants.apiURL) || request.url.startsWith(GlobalConstants.oauthURL))) {

      if (request.url.startsWith(GlobalConstants.apiURL) || !request.url.includes('/me')) {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${this.authService.getToken()}`,
          'schema': this.authService.getSchema()
        });

        request = request.clone({ headers });
      } else if (request.url.startsWith(GlobalConstants.oauthURL)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`
          }
        });
      }
    }

    return next.handle(request).pipe(finalize(() => this.loaderService.hide()));
  }
}
