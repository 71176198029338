import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {Prescription} from '../models/prescription';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  url = GlobalConstants.apiURL + 'prescricao/farmaceutica';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  getPDF(url: string): Observable<any> {
    return this.httpClient.get(url);
  }

  insert(prescription: Prescription): Observable<Prescription> {
    return this.httpClient.post<Prescription>(this.url, prescription, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  getPDFUrlById(id: number) {
    return this.url + '/' + id + '/pdf';
  }

  downloadPDF(name, pdf) {
    const byteArray = new Uint8Array(atob(pdf).split('').map(char => char.charCodeAt(0)))
    const file = new Blob([byteArray], {type: 'application/pdf'});
    saveAs(file, name)
  }

  pdfById(url: string, name: string, id: number) {
    this.getPDF(url).subscribe((response) => {
      response.pdfs.forEach((element) => {
        this.downloadPDF(element.name, element.content);
      });
    });
  }

  pdfByUrl(url: string, data) {
    return this.httpClient.post(url, data).subscribe((response) => {
      (response as any).pdfs.forEach((element) => {
        this.downloadPDF(element.name, element.content);
      });
    });
  }

}
