import {Injectable, OnInit} from '@angular/core';
import {AuthService} from "../../../../services/auth.service";

export interface NavigationItem {
  disabled: boolean;
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Operação',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'home',
        title: 'Principal',
        type: 'item',
        icon: 'feather icon-home',
        url: '/dashboard',
        breadcrumbs: false
      },

      {
        id: 'register',
        title: 'Cadastros',
        type: 'collapse',
        icon: 'feather icon-box',

        children: [
          {
            id: 'patient-register',
            title: 'Pacientes',
            type: 'collapse',
            children: [
              {
                id: 'patient-list',
                title: 'Consultar',
                type: 'item',
                url: '/paciente',
                target: false,
              },
              {
                id: 'patient-form',
                title: 'Cadastrar',
                type: 'item',
                url: '/paciente/form',
                target: false,
              }
            ]
          },
          {
            id: 'pharmaceutical-register',
            title: 'Farmacêuticos',
            type: 'collapse',
            children: [
              {
                id: 'pharmaceutical-list',
                title: 'Consultar',
                type: 'item',
                url: '/farmaceutico',
                target: false,
              },
              {
                id: 'pharmaceutical-form',
                title: 'Cadastrar',
                type: 'item',
                url: '/farmaceutico/form',
                target: false,
              },
            ]
          },
          {
            id: 'complement-register',
            title: 'Complementares',
            type: 'collapse',
            children: [
              {
                id: 'medicament-form',
                title: 'Medicamento',
                type: 'item',
                url: '/medicamento/form',
                target: false,
              },
              {
                id: 'earring-form',
                title: 'Marca De Brinco',
                type: 'item',
                url: '/brinco/form',
                target: false,
              },
              {
                id: 'nebulizer-form',
                title: 'Nebulizador',
                type: 'item',
                url: '/nebulizador/form',
                target: false,
              },
              {
                id: 'equipment-form',
                title: 'Equipamento',
                type: 'item',
                url: '/equipamento/form',
                target: false,
              },
              {
                id: 'prescricao-list',
                title: 'Prescrição',
                type: 'item',
                url: '/prescricao-complementar',
                target: false,
              },
            ]
          },
        ]
      },

      {
        id: 'widget',
        title: 'Serviços',
        type: 'collapse',
        icon: 'feather icon-briefcase',
        children: [
          {
            id: 'earring-application',
            type: 'item',
            title: 'Aplicação De Brinco',
            url: '/aplicacao/brinco'
          },
          {
            id: 'injectables',
            type: 'item',
            title: 'Aplicação de Injetáveis',
            url: '/aplicacao/injetaveis'
          },
          {
            id: 'bioimpedance',
            type: 'item',
            title: 'Avaliação de Bioimpedância',
            url: '/avaliacao/bioimpedancia'
          },
          {
            id: 'declarations-menu',
            title: 'Declarações',
            type: 'collapse',
            children: [
              {
                id: 'temperature-steatement',
                type: 'item',
                title: 'Declaração de temperatura',
                url: '/declaracao/temperatura',
              },
              {
                id: 'cholesterol-steatement',
                type: 'item',
                title: 'Declaração de Colesterol',
                url: '/declaracao/colesterol'
              },
              {
                id: 'glucose-steatement',
                type: 'item',
                title: 'Declaração de Glicemia',
                url: '/declaracao/glicemia'
              },
              {
                id: 'blood-pressure-steatement',
                type: 'item',
                title: 'Declaração de Pressão Arterial',
                url: '/declaracao/pressao-arterial'
              },
            ]
          },
          {
            id: 'medication',
            type: 'item',
            title: 'Gestão de Medicação',
            url: '/gestao/medicacao'
          },
          {
            id: 'nebulization',
            type: 'item',
            title: 'Nebulização',
            url: '/nebulizacao'
          },
          {
            id: 'statistic',
            type: 'item',
            title: 'Prescrição Farmaceutica',
            url: '/prescricao'
          },

          {
            id: 'statistic',
            type: 'item',
            title: 'Consulta Farmacêutico/SOAP',
            url: '/prontuario-soap'
          },
          {
            id: 'atf',
            type: 'item',
            title: 'Revisão de Farmacoterapia',
            url: '/prontuario-atf'
          },
          {
            id: 'testCovid',
            type: 'item',
            title: 'Teste rápido para COVID-19',
            url: '/teste-covid'
          },
        ]
      }
    ]
  },
  {
    id: 'ui-element',
    title: 'Gerencial',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'basic',
        title: 'Agenda',
        type: 'item',
        icon: 'feather icon-calendar',
        disabled: false,
        url: '/agenda'
      },
      {
        id: 'advance',
        title: 'Relatórios',
        icon: 'feather icon-pie-chart',
        disabled: false,
        type: 'collapse',
        children: [
          {
            id: 'relatorio-servicos',
            type: 'collapse',
            title: 'Serviços',
            children: [
              {
                id: 'relatorio-servicos-atendimentos',
                type: 'item',
                title: 'Relatório de Atendimento',
                url: '/relatorio/atendimento',
              },
            ]
          },
        ]
      }
    ]
  },
  {
    id: 'forms',
    title: 'Diversos',
    type: 'group',
    icon: 'feather icon-layout',
    children: [
      {
        id: 'menu-configs',
        title: 'Configurações',
        type: 'item',
        icon: 'feather icon-sliders',
        url: '/configs'
      },
      {
        id: 'forms-element',
        title: 'Empresa',
        type: 'item',
        icon: 'feather icon-file-text',
        url: '/empresa'
      }
    ]
  },

];

@Injectable()
export class NavigationItem implements OnInit {

  plano;

  constructor(private authService: AuthService,) {
    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      if (this.plano?.codEduzz === '1110806') {
        // @ts-ignore
        NavigationItems[1].children[0].disabled = true
      }
    });
  }


  public get() {
    return NavigationItems;
  }

  ngOnInit(): void {

  }
}
