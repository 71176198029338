import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Patient} from '../../models/patient';
import {ViacepService} from '../../services/viacep.service';
import {NgForm} from '@angular/forms';
import {PatientService} from '../../services/patient.service';
import {ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {PrescriptionService} from '../../services/prescription.service';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {StateService} from '../../services/state.service';
import {PharmaceuticalServiceData} from '../../models/pharmaceutical-service';
import {PharmaceuticalServiceService} from '../../services/pharmaceutical-service.service';
import {TemperatureStatementService} from '../../services/temperature-statement.service';
import {EarringApplicationService} from '../../services/earring-application.service';
import {GlucoseStatementService} from '../../services/glucose-statement.service';
import {CholesterolDeclarationService} from '../../services/cholesterol-declaration.service';
import {BloodPressureStatementService} from '../../services/blood-pressure-statement.service';
import {MedicationManagementService} from '../../services/medication-management.service';
import {NebulizationService} from '../../services/nebulization.service';
import {InjectableApplicationService} from '../../services/injectable-application.service';
import {BioimpedanceService} from '../../services/bioimpedance.service';
import {AuthService} from "../../services/auth.service";
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {MatFabMenu} from "@angular-material-extensions/fab-menu";
import {ConfigGeralService} from "../../services/config-geral.service";
import {ConfigGeral} from "../../models/config-geral";
import {TesteCovidService} from "../../services/teste-covid.service";

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit {
  @ViewChild("fileDropRef", {static: false}) fileDropEl: ElementRef;
  @ViewChild('anexoModal') anexoModal;
  @ViewChild('confirmarDeleteModal') confirmarDeleteModal;
  files: any[] = [];
  selectedToDelete = null;

  id: number;
  editMode = false as boolean;
  patient = {flagAtivo: true} as Patient;
  activeId = PatientTabEnum.PRINCIPAL as string;
  tpGenero: string;

  farmaceuticoLogado;
  anexoSubmitted = false;
  anexo = {
    descricao: null,
    farmaceutico: null,
    nmArquivo: null,
    dado: null
  };

  fabButtonsRandom: MatFabMenu[] = [
    {
      id: 1,
      icon: 'check',
      tooltip: 'Salvar',
      tooltipPosition: 'left',
    },
    {
      id: 2,
      icon: 'assignment',
      tooltip: 'Gerar Declaração de Comparecimento',
      tooltipPosition: 'left',
    },

  ];
  configGeral: ConfigGeral = {
    fgDesconsideraServicoAbonadoCalculoTicketMedio: true,
    fgImprimeViaFarmaceutico: false,
    dsObservacaoAplicaoBrincoDefault: '',
    dsDescricaoDeclaracaoComparecimentoDefault: '',
    dsOrientacaoCovid19Default: ''
  };

  pharmaceuticalServiceList = [] as PharmaceuticalServiceData[];
  public maskNascimento = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public maskTelefoneResidencial = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskTelefoneCelular = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private toastyService: ToastyService,
    private viacepService: ViacepService,
    private prescriptionService: PrescriptionService,
    private glucoseStatementService: GlucoseStatementService,
    private temperatureStatementService: TemperatureStatementService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private earringApplicationService: EarringApplicationService,
    private cholesterolDeclarationService: CholesterolDeclarationService,
    private bloodPressureStatementService: BloodPressureStatementService,
    private medicationManagementService: MedicationManagementService,
    private nebulizationService: NebulizationService,
    private injectableApplicationService: InjectableApplicationService,
    private bioimpedanceService: BioimpedanceService,
    private covidService: TesteCovidService,
    private stateService: StateService,
    private router: Router,
    private authService: AuthService,
    private pharmaceuticalService: PharmaceuticalService,
    private configGeralService: ConfigGeralService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;

      if (params.tab) {
        this.activeId = params.tab;
      }

      if (params.pdf && params.servico) {
        this.goToPDF(params.pdf, params.servico);
      }
    });
  }

  goToPDF(pdf: any, servico: any) {
    switch (Number.parseInt(servico)) {
      case ServiceTypeEnum.PRESCRICAO:
      case ServiceTypeEnum.PRONTUARIO_SOAP:
        this.prescriptionService.pdfById(this.prescriptionService.getPDFUrlById(pdf), 'prescricao-farmaceutica', pdf);
        break;
      case ServiceTypeEnum.APLICACAO_BRINCO:
        this.prescriptionService.pdfById(this.earringApplicationService.getPDFUrlById(pdf), 'aplicacao-brinco', pdf);
        break;
      case ServiceTypeEnum.DECLARACAO_TEMPERATURA:
        this.prescriptionService.pdfById(this.temperatureStatementService.getPDFUrlById(pdf), 'declaracao-temperatura', pdf);
        break;
      case ServiceTypeEnum.DECLARACAO_GLICEMIA:
        this.prescriptionService.pdfById(this.glucoseStatementService.getPDFUrlById(pdf), 'declaracao-glicemia', pdf);
        break;
      case ServiceTypeEnum.DECLARACAO_COLESTEROL:
        this.prescriptionService.pdfById(this.cholesterolDeclarationService.getPDFUrlById(pdf), 'declaracao-colesterol', pdf);
        break;
      case ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL:
        this.prescriptionService.pdfById(this.bloodPressureStatementService.getPDFUrlById(pdf), 'declaracao-pressao-arterial', pdf);
        break;
      case ServiceTypeEnum.GESTAO_MEDICACAO:
        this.prescriptionService.pdfById(this.medicationManagementService.getPDFUrlById(pdf), 'gestao-medicacao', pdf);
        break;
      case ServiceTypeEnum.NEBULIZACAO:
        this.prescriptionService.pdfById(this.nebulizationService.getPDFUrlById(pdf), 'nebulizacao', pdf);
        break;
      case ServiceTypeEnum.APLICACAO_INJETAVEL:
        this.prescriptionService.pdfById(this.injectableApplicationService.getPDFUrlById(pdf), 'aplicacao-injetavel', pdf);
        break;
      case ServiceTypeEnum.AVALIACAO_BIOIMPEDANCIA:
        this.prescriptionService.pdfById(this.bioimpedanceService.getPDFUrlById(pdf), 'avaliacao-bioimpedancia', pdf);
        break;
      case ServiceTypeEnum.SERVICO_COVID:
        this.prescriptionService.pdfById(this.covidService.getPDFUrlById(pdf), 'teste-rapido-covid', pdf);
        break;
      default:
        break;
    }
  }

  getHrefLabelByPharmaceticalService(pharmaceuticalService: PharmaceuticalServiceData) {
    switch (pharmaceuticalService.tpServico) {
      case ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL:
        return pharmaceuticalService['fgEncerrado'] ? 'Segunda via do PDF' : 'Ver mais';
      case ServiceTypeEnum.PRONTUARIO_SOAP:
      case ServiceTypeEnum.PROTOCOLO_ATF:
        return 'Ver mais';
      default:
        return 'Segunda via do PDF';
    }
  }

  openPDF(pharmaceuticalService: PharmaceuticalServiceData) {
    switch (pharmaceuticalService.tpServico) {
      case ServiceTypeEnum.PRESCRICAO:
        this.goToPDF(pharmaceuticalService['idPrescricaoFarmaceutica'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.DECLARACAO_TEMPERATURA:
        this.goToPDF(pharmaceuticalService['idDeclaracaoTemperatura'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.APLICACAO_BRINCO:
        this.goToPDF(pharmaceuticalService['idAplicacaoBrinco'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.DECLARACAO_GLICEMIA:
        this.goToPDF(pharmaceuticalService['idDeclaracaoGlicemia'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.DECLARACAO_COLESTEROL:
        this.goToPDF(pharmaceuticalService['idDeclaracaoColesterol'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.DECLARACAO_PRESSAO_ARTERIAL:
        if (pharmaceuticalService['fgEncerrado']) {
          this.goToPDF(pharmaceuticalService['idDeclaracaoPressaoArterial'], pharmaceuticalService.tpServico);

        } else {
          window.open('declaracao/pressao-arterial?id=' + pharmaceuticalService['idDeclaracaoPressaoArterial'], '_self');
        }
        break;
      case ServiceTypeEnum.GESTAO_MEDICACAO:
        this.goToPDF(pharmaceuticalService['idGestaoMedicacao'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.NEBULIZACAO:
        this.goToPDF(pharmaceuticalService['idNebulizacao'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.APLICACAO_INJETAVEL:
        this.goToPDF(pharmaceuticalService['idAplicacaoInjetavel'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.AVALIACAO_BIOIMPEDANCIA:
        this.goToPDF(pharmaceuticalService['idBioimpedancia'], pharmaceuticalService.tpServico);
        break;
      case ServiceTypeEnum.PRONTUARIO_SOAP:
        // window.open('prontuario-soap?id=' + pharmaceuticalService['idPronturarioFarmaceutico'], '_self');

        this.router.navigate(['/prontuario-soap/edit/' + pharmaceuticalService['idPronturarioFarmaceutico']]);
        break;
      case ServiceTypeEnum.PROTOCOLO_ATF:
        this.router.navigate(['/prontuario-atf/edit/' + pharmaceuticalService['idProtocoloATF']]);
        break;
      case ServiceTypeEnum.SERVICO_COVID:
        this.goToPDF(pharmaceuticalService['id'], pharmaceuticalService.tpServico);
        break;
      default:
        break;
    }

    return '';
  }

  ngOnInit() {

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.farmaceuticoLogado = pharmaceutical;
            console.log(pharmaceutical)
          }
        });

      });
    });

    this.configGeralService.getOne().subscribe((configGeral) => {
      this.configGeral = configGeral;
    });
    if (this.id !== undefined) {
      this.pharmaceuticalServiceService.findByIdPatient(this.id).subscribe((pharmaceuticalRecordList) => {
        this.pharmaceuticalServiceList = pharmaceuticalRecordList;
      });

      this.patientService.getById(this.id).subscribe((patient) => {
        this.patient = patient;
        this.patient.idEndereco = patient['endereco']['idEndereco'];
        this.patient.cep = patient['endereco']['cep'];
        this.patient.estado = patient['endereco']['estado'];
        this.patient.uf = patient['endereco']['uf'];
        this.patient.cidade = patient['endereco']['cidade'];
        this.patient.bairro = patient['endereco']['bairro'];
        this.patient.complemento = patient['endereco']['complemento'];
        this.patient.logradouro = patient['endereco']['logradouro'];
        this.patient.numero = patient['endereco']['numero'];
        if(this.patient.flagGenero !== null) {
          this.tpGenero = this.patient.flagGenero ? 'Feminino' : 'Masculino';
        }
        this.patient.anexos = patient['anexos'];

        this.patient.idInfoComplementarPaciente = patient['infoComplementarPaciente']['idInfoComplementarPaciente'];
        this.patient.escolaridade = patient['infoComplementarPaciente']['escolaridade'];
        this.patient.raca = patient['infoComplementarPaciente']['raca'];
        this.patient.cuidador = patient['infoComplementarPaciente']['cuidador'];
        this.patient.foneCuidador = patient['infoComplementarPaciente']['foneCuidador'];
        this.patient.medicoPaciente = patient['infoComplementarPaciente']['medicoPaciente'];
        this.patient.foneMedicoPaciente = patient['infoComplementarPaciente']['foneMedicoPaciente'];
        this.patient.profissao = patient['infoComplementarPaciente']['profissao'];
        this.patient.empresa = patient['infoComplementarPaciente']['empresa'];
        this.patient.planoSaude = patient['infoComplementarPaciente']['planoSaude'];
        this.patient.estCivil = patient['infoComplementarPaciente']['estCivil'];
        this.patient.alergias = patient['infoComplementarPaciente']['alergias'];
        this.patient.outrasInformacoes = patient['infoComplementarPaciente']['outrasInformacoes'];
      });
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  onInsert() {
    this.patient['endereco'] = {
      idEndereco: this.patient.idEndereco,
      cep: this.patient.cep,
      estado: this.patient.estado,
      uf: this.patient.uf,
      cidade: this.patient.cidade,
      bairro: this.patient.bairro,
      complemento: this.patient.complemento,
      logradouro: this.patient.logradouro,
      numero: this.patient.numero,
    };

    this.patient['infoComplementarPaciente'] = {
      idInfoComplementarPaciente: this.patient.idInfoComplementarPaciente,
      escolaridade: this.patient.escolaridade,
      raca: this.patient.raca,
      cuidador: this.patient.cuidador,
      foneCuidador: this.patient.foneCuidador,
      medicoPaciente: this.patient.medicoPaciente,
      foneMedicoPaciente: this.patient.foneMedicoPaciente,
      profissao: this.patient.profissao,
      empresa: this.patient.empresa,
      planoSaude: this.patient.planoSaude,
      estCivil: this.patient.estCivil,
      alergias: this.patient.alergias,
      outrasInformacoes: this.patient.outrasInformacoes,
    };
    this.patientService.insert(this.patient).subscribe((patient) => {
      this.showToast('Paciente cadastrado com sucesso');

      this.id = patient.idPaciente;
      this.activeId = PatientTabEnum.COMPLEMENTAR;
      this.patient.idPaciente = patient.idPaciente;
      this.patient.idEndereco = patient['endereco']['idEndereco'];
      this.patient.idInfoComplementarPaciente = patient['endereco']['idInfoComplementarPaciente'];
      this.router.navigate(['/paciente/form/edit'], {queryParams: {id: this.id, tab: this.activeId}}).then();
    }, (error) => {
      if (error && error.error && error.error.message) {
        this.showErrorToast(error.error.message);

      } else {
        this.showErrorToast('Não foi possível concluir o cadastro');
      }
    });
  }

  onUpdate() {
    this.patient['endereco'] = {
      idEndereco: this.patient.idEndereco,
      cep: this.patient.cep,
      estado: this.patient.estado,
      uf: this.patient.uf,
      cidade: this.patient.cidade,
      bairro: this.patient.bairro,
      complemento: this.patient.complemento,
      logradouro: this.patient.logradouro,
      numero: this.patient.numero,
    };

    this.patient['infoComplementarPaciente'] = {
      idInfoComplementarPaciente: this.patient.idInfoComplementarPaciente,
      escolaridade: this.patient.escolaridade,
      raca: this.patient.raca,
      cuidador: this.patient.cuidador,
      foneCuidador: this.patient.foneCuidador,
      medicoPaciente: this.patient.medicoPaciente,
      foneMedicoPaciente: this.patient.foneMedicoPaciente,
      profissao: this.patient.profissao,
      empresa: this.patient.empresa,
      planoSaude: this.patient.planoSaude,
      estCivil: this.patient.estCivil,
      alergias: this.patient.alergias,
      outrasInformacoes: this.patient.outrasInformacoes,
    };

    this.patientService.update(this.patient).subscribe(patient => {
      this.showToast('Paciente alterado com sucesso');
      this.anexo = {
        descricao: null,
        farmaceutico: null,
        nmArquivo: null,
        dado: null
      };
      this.anexoSubmitted = false;
      this.selectedToDelete = null;
    }, (error) => {
      if (error && error.error && error.error.message) {
        this.showErrorToast(error.error.message);

      } else {
        this.showErrorToast('Não foi possível editar o cadastro');
      }
    });
  }

  save(form: NgForm) {
    if (form.valid) {

      if (!this.patient.dataNascimento.includes("/")) {
        this.patient.dataNascimento = this.patient.dataNascimento.substring(0, 2) + "/" + this.patient.dataNascimento.substring(2, 4) + "/" + this.patient.dataNascimento.substring(4, 10);
      }

      if(this.tpGenero) {
        this.patient.flagGenero = this.tpGenero !== 'Masculino';
      }

      if (this.patient.idPaciente !== undefined) {
        this.onUpdate();

      } else {
        this.onInsert();
      }

    } else {
      this.activeId = PatientTabEnum.PRINCIPAL;
    }
  }

  setIfNotEmptyByViacepAndEmptyByForm(data, column) {
    if (data !== undefined && data.length >= 1) {
      this.patient[column] = data;
    }
  }

  onCepChanged() {
    if (this.patient.cep !== undefined && this.patient.cep.length == 8) {
      this.viacepService.findByCep(this.patient.cep).subscribe((viacep) => {
        if (viacep['erro']) {
          this.showErrorToast('CEP não encontrado');
          return false;
        }

        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.uf, 'uf');
        this.setIfNotEmptyByViacepAndEmptyByForm(this.stateService.stateByUf(viacep.uf), 'estado');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.localidade, 'cidade');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.bairro, 'bairro');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.logradouro, 'logradouro');
        this.setIfNotEmptyByViacepAndEmptyByForm(viacep.complemento, 'complemento');
      });
    }
  }

  checkDataIsValid(value: any) {

    if (value != null) {
      let myList = [];

      if (value.includes("/")) {
        myList = value.split('/')
      } else {
        myList.push(value.substring(0, 2));
        myList.push(value.substring(2, 4));
        myList.push(value.substring(4, 10));
      }

      if (myList.length == 3) {
        let dt = new Date(myList[2], myList[1], myList[0]);
        return !isNaN(dt.getTime());
      }

    }
    return false;

  }

  checkDataIsAfterToday(value: any) {

    if (value != null) {

      let myList = [];

      if (value.includes("/")) {
        myList = value.split('/')
      } else {
        myList.push(value.substring(0, 2));
        myList.push(value.substring(2, 4));
        myList.push(value.substring(4, 10));
      }

      if (myList.length == 3) {
        let dt = new Date(myList[2], myList[1], myList[0]);
        return !isNaN(dt.getTime()) && dt.getTime() < new Date().getTime();
      }

    }
    return false;

  }

  gravarAnexo() {
    this.anexoSubmitted = true;
    if (this.anexo.descricao && this.files.length > 0) {
      for (let file of this.files) {
        file.descricao = this.anexo.descricao;
      }

      this.patient.anexos = this.patient.anexos.concat(this.files);
      this.onUpdate();
      this.anexoModal.hide();
    }
  }

  downloadPDF(name, pdf) {
    const downloadLink = document.createElement("a");

    downloadLink.href = pdf;
    downloadLink.download = name;
    downloadLink.click();
  }

  handleFileInput(files) {
    for (let file of files) {

      let anexo = {
        descricao: null,
        farmaceutico: null,
        nmArquivo: null,
        dado: null,
        dhCadastro: new Date()
      };

      anexo.nmArquivo = file.name;
      console.log(file)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        anexo.farmaceutico = this.farmaceuticoLogado.nome;
        anexo.dado = reader.result;
        this.files.push(anexo)
      };
    }

  }

  remover(anexo: any) {
    const index = this.patient.anexos.indexOf(anexo);
    if (index > -1) {
      this.patient.anexos.splice(index, 1);
    }
    this.onUpdate();
    this.confirmarDeleteModal.hide();

  }

  getDescrModalDelete() {
    if (this.selectedToDelete) {
      return `Deseja realmente deletar o anexo ${this.selectedToDelete.descricao}?`
    }
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  openModal() {
    this.anexo = {
      descricao: null,
      farmaceutico: null,
      nmArquivo: null,
      dado: null
    };
    this.anexoSubmitted = false;
    this.files = [];
    this.anexoModal.show();
    this.fileDropEl.nativeElement.value = '';
  }

  generatePdfDeclaracao() {
    let descricao = this.configGeral.dsDescricaoDeclaracaoComparecimentoDefault;

    descricao = descricao.replace("${nomePaciente}", this.patient.nome);
    descricao = descricao.replace("{$cpfDescription}", this.patient.cpf ? 'inscrito no CPF sob o nº ' + this.patient.cpf + ', ' : '');
    descricao = descricao.replace("{$data}", new Date().toLocaleDateString());

    const declaracao = {
      paciente: this.patient,
      farmaceutico: this.farmaceuticoLogado,
      dhCadastro: new Date(),
      descricao: descricao
    }

    this.prescriptionService.pdfByUrl(this.patientService.getPDFDeclaracaoComparecimentoUrl(), declaracao);
  }
}
