import {Component, OnInit} from '@angular/core';
import {PatientService} from '../../services/patient.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit {

  patientListConfig: any = {};

  constructor(private patientService: PatientService) {
  }

  ngOnInit() {
    this.patientListConfig = {
      ajax: (dataTablesParameters: any, callback) => {
        this.patientService.getAll().subscribe(patientList => {
          callback({
            recordsTotal: patientList.length,
            recordsFiltered: patientList.length,
            data: patientList
          });
        });
      },

      info: false,
      paging: true,
      searching: true,
      pageLength: 25,
      pagingType: 'full_numbers',

      language: {
        emptyTable: 'Nenhum paciente encontrado',
        info: '',
        infoEmpty: '',
        infoFiltered: '',
        infoPostFix: '',
        decimal: '',
        thousands: '',
        lengthMenu: '',
        loadingRecords: '',
        processing: '',
        search: 'Pesquisar',
        searchPlaceholder: '',
        zeroRecords: 'Nenhum paciente encontrado',
        url: '',

        paginate: {
          last: 'Último',
          next: 'Próximo',
          first: 'Primeiro',
          previous: 'Anterior',
        }
      },

      columns: [{
        title: 'Código',
        data: 'idPaciente'
      }, {
        title: 'Nome',
        data: 'nome'
      }, {
        title: 'CPF',
        data: 'cpf'
      }, {
        title: 'Telefone',
        searchable: false,
        data: 'telefoneCelular'
      }, {
        title: 'Status',
        searchable: false,
        render: function(data: any, type: any, full: any) {
          return full.flagAtivo ? 'Ativo' : 'Inativo';
        }
      }, {
        title: 'Ações',
        searchable: false,
        render: function(data: any, type: any, full: any) {
          return '<a class="btn btn-outline-primary btn-sm" href="paciente/form/edit?id=' + full.idPaciente + '">Editar</a>';
        }
      }],
    };
  }

}
