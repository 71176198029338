import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {EarringApplication} from '../models/earring-application';

@Injectable({
  providedIn: 'root'
})
export class EarringApplicationService {

  url = GlobalConstants.apiURL + 'aplicacao/brinco';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(earringApplication: EarringApplication): Observable<EarringApplication> {
    return this.httpClient.post<EarringApplication>(this.url, earringApplication, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getPDFUrlById(id: number) {
    return this.url + '/' + id + '/pdf';
  }

}
