<form #myForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Avaliação de Bioimpedância" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico"
                         [ngClass]="{'invalido': !farmaceutico.valid && myForm.submitted}" class="ng-select"
                         #farmaceutico="ngModel"></ng-select>

              <label *ngIf="!farmaceutico.valid && myForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select [ngClass]="{'invalido': !paciente.valid && myForm.submitted}" class="ng-select" required
                         [options]="patientListOption"
                         [(ngModel)]="patientOption" name="paciente"
                         #paciente="ngModel"></ng-select>

              <label *ngIf="!paciente.valid && myForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Peso em KG* <span style="font-size: 10px;">(ex.: 69.2)</span> </label>
              <input type="text" class="form-control" name="peso" [(ngModel)]="bioimpedanceEvaluation.peso"
                     [ngClass]="{'is-invalid': !peso.valid && (peso.dirty || myForm.submitted)}"
                     #peso="ngModel" required mask="0*.00" [dropSpecialCharacters]="false"
                     (change)="bioimpedanceEvaluation.peso = peso.value.replace(',', '.'); onIMCChange()" maxlength="5"
                     minlength="2">
              <label *ngIf="!peso.valid && (peso.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label> Altura em CM <span style="font-size: 10px;">(ex.: 170)</span> </label>
              <input type="text" class="form-control" name="altura" [(ngModel)]="bioimpedanceEvaluation.altura"
                     [ngClass]="{'is-invalid': !altura.valid && (altura.dirty || myForm.submitted)}"
                     #altura="ngModel" required
                     (change)="bioimpedanceEvaluation.altura = altura.value; onIMCChange()"
                     minlength="3" maxlength="3">
              <label *ngIf="!altura.valid && (altura.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label> IMC </label>
              <input type="text" class="form-control" name="imc" [(ngModel)]="imcAuto" #imc="ngModel"
                     disabled>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Cintura em CM* </label>
              <input type="text" class="form-control" name="cintura" [(ngModel)]="bioimpedanceEvaluation.cintura"
                     [ngClass]="{'is-invalid': !cintura.valid && (cintura.dirty || myForm.submitted)}"
                     (change)="bioimpedanceEvaluation.cintura = cintura.value.replace(',', '.');"
                     #cintura="ngModel" required pattern="[0-9]{1,3}[.,]{0,1}[0-9]{0,2}">
              <label *ngIf="!cintura.valid && (cintura.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label> Torax em CM* </label>
              <input type="text" class="form-control" name="torax" [(ngModel)]="bioimpedanceEvaluation.torax"
                     [ngClass]="{'is-invalid': !torax.valid && (torax.dirty || myForm.submitted)}"
                     (change)="bioimpedanceEvaluation.torax = torax.value.replace(',', '.');"
                     #torax="ngModel" required pattern="[0-9]{1,3}[.,]{0,1}[0-9]{0,2}">
              <label *ngIf="!torax.valid && (torax.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label> Quadril em CM* </label>
              <input type="text" class="form-control" name="quadril" [(ngModel)]="bioimpedanceEvaluation.quadril"
                     [ngClass]="{'is-invalid': !quadril.valid && (quadril.dirty || myForm.submitted)}"
                     (change)="bioimpedanceEvaluation.quadril = quadril.value.replace(',', '.');"
                     #quadril="ngModel" required pattern="[0-9]{1,3}[.,]{0,1}[0-9]{0,2}">
              <label *ngIf="!quadril.valid && (quadril.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Percentual de Gordura Comporal</label>
              <input type="text" class="form-control" name="peGorduraCorporal"
                     [(ngModel)]="bioimpedanceEvaluation.peGorduraCorporal"
                     [ngClass]="{'is-invalid': !peGorduraCorporal.valid && (peGorduraCorporal.dirty || myForm.submitted)}"
                     #peGorduraCorporal="ngModel" [dropSpecialCharacters]="false"
                     (change)="bioimpedanceEvaluation.peGorduraCorporal = peGorduraCorporal.value.replace(',', '.');"
                     maxlength="6" minlength="1" mask="percent" suffix="%">
              <label *ngIf="!peGorduraCorporal.valid && (peGorduraCorporal.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Percentual de Musculo Esquelético</label>
              <input type="text" class="form-control" name="peMusculoEsqueletico"
                     [(ngModel)]="bioimpedanceEvaluation.peMusculoEsqueletico" [dropSpecialCharacters]="false"
                     [ngClass]="{'is-invalid': !peMusculoEsqueletico.valid && (peMusculoEsqueletico.dirty || myForm.submitted)}"
                     #peMusculoEsqueletico="ngModel"
                     (change)="bioimpedanceEvaluation.peMusculoEsqueletico = peMusculoEsqueletico.value.replace(',', '.');"
                     maxlength="6" minlength="1" mask="percent" suffix="%">
              <label *ngIf="!peMusculoEsqueletico.valid && (peMusculoEsqueletico.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Gordura Visceral</label>
              <input type="text" class="form-control" name="gorduraVisceral"
                     [(ngModel)]="bioimpedanceEvaluation.gorduraVisceral"
                     [ngClass]="{'is-invalid': !gorduraVisceral.valid && (gorduraVisceral.dirty || myForm.submitted)}"
                     #gorduraVisceral="ngModel"
                     (change)="bioimpedanceEvaluation.gorduraVisceral = gorduraVisceral.value.replace(',', '.');"
                     maxlength="6" minlength="1" mask="percent" suffix="%">
              <label *ngIf="!gorduraVisceral.valid && (gorduraVisceral.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Metabolismo em Repouso</label>
              <input type="text" class="form-control" name="metabolismoRepouso"
                     [(ngModel)]="bioimpedanceEvaluation.metabolismoRepouso"
                     [ngClass]="{'is-invalid': !metabolismoRepouso.valid && (metabolismoRepouso.dirty || myForm.submitted)}"
                     #metabolismoRepouso="ngModel"
                     (change)="bioimpedanceEvaluation.metabolismoRepouso = metabolismoRepouso.value.replace(',', '.');"
                     maxlength="6" minlength="2">
              <label *ngIf="!metabolismoRepouso.valid && (metabolismoRepouso.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Idade Biológica</label>
              <input type="text" class="form-control" name="idadeBiologica"
                     [(ngModel)]="bioimpedanceEvaluation.idadeBiologica"
                     [ngClass]="{'is-invalid': !idadeBiologica.valid && (idadeBiologica.dirty || myForm.submitted)}"
                     #idadeBiologica="ngModel"
                     pattern="[0-9]{1,3}" minlength="1" maxlength="3">
              <label *ngIf="!idadeBiologica.valid && (idadeBiologica.dirty || myForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Dados
                incorretos</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="bioimpedanceEvaluation.observacao"
                        name="observacoes"
                        id="textAreaObservacoes" rows="8"
                        #observacoes="ngModel" maxlength="1024"></textarea>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="validateOpen(myForm)" matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Avaliação de Bioimpedância?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="bioimpedanceEvaluation.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="bioimpedanceEvaluation.servico.vlCobrado"
               [disabled]="!bioimpedanceEvaluation.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || myForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="bioimpedanceEvaluation.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(myForm)">Salvar</button>

  </div>
</app-ui-modal>


<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
