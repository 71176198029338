import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {CholesterolDeclaration} from '../../models/cholesterol-declaration';
import {PatientService} from '../../services/patient.service';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {CholesterolDeclarationService} from '../../services/cholesterol-declaration.service';
import {IOption} from 'ng-select';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-cholesterol-declaration',
  templateUrl: './cholesterol-declaration.component.html',
  styleUrls: ['./cholesterol-declaration.component.scss']
})
export class CholesterolDeclarationComponent implements OnInit {

  dataHora;

  plano;
  totalMes;
  @ViewChild('modalRetorno') modalRetorno;
  @ViewChild('modalCobrar') modalCobrar;
  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];
  cholesterolDeclaration = {
    paciente: {},
    farmaceutico: {},
    servico: {
      fgCobrado: false
    },
  } as CholesterolDeclaration;

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];
  pacienteByAgenda;

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private pharmaceuticalService: PharmaceuticalService,
    private cholesterolDeclarationService: CholesterolDeclarationService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });

      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });

    this.serviceTypeService.getOne(ServiceTypeEnum.DECLARACAO_COLESTEROL).subscribe((tpServico) => {
      this.cholesterolDeclaration.servico.vlCobrado = tpServico.vlServico;

      this.cholesterolDeclaration.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
      this.cholesterolDeclaration.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
    });
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  checkIsValid() {
    return this.cholesterolDeclaration.colesterol >= 0.00 || this.cholesterolDeclaration.hdlC >= 0.00 ||
      this.cholesterolDeclaration.ldlC >= 0.00 || this.cholesterolDeclaration.triglicerideos >= 0.00;
  }

  save(myForm: NgForm) {
    if (myForm.valid) {
      if (this.checkIsValid()) {
        if (!this.pharmaceuticalOption) {
          this.showErrorToast('Informe um farmacêutico');

        } else if (!this.patientOption) {
          this.showErrorToast('Informe um paciente');

        } else {
          this.cholesterolDeclaration.paciente.idPaciente = Number(this.patientOption);
          this.cholesterolDeclaration.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);

          this.cholesterolDeclarationService.insert(this.cholesterolDeclaration).subscribe((cholesterolDeclaration) => {

            this.cholesterolDeclaration.idDeclaracaoColesterol = cholesterolDeclaration.idDeclaracaoColesterol;
            if (this.cholesterolDeclaration.servico.fgSugerirRetorno) {

              var today = new Date();
              var tomorrow = new Date();
              tomorrow.setDate(today.getDate() + this.cholesterolDeclaration.servico.qtdDiasRetorno);
              this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
              this.modalRetorno.show({backdrop: 'static'});
            } else {
              this.redirectPaciente();
            }

          });
        }

      } else {
        this.showErrorToast('Dados incorretos');
      }
    }
  }

  validateOpen(form) {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.cholesterolDeclaration.farmaceutico.idFarmaceutico,
      idPaciente: this.cholesterolDeclaration.paciente.idPaciente,
      tpServico: ServiceTypeEnum.DECLARACAO_COLESTEROL,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }


  private redirectPaciente() {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: this.cholesterolDeclaration.paciente.idPaciente,
        pdf: this.cholesterolDeclaration.idDeclaracaoColesterol,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.DECLARACAO_COLESTEROL,
      }
    }).then();
  }

}
