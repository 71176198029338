import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PharmaceuticalRecordATF} from '../../models/pharmaceutical-record-atf';
import {PatientService} from '../../services/patient.service';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {PharmaceuticalRecordATFService} from '../../services/pharmaceutical-record-atf.service';
import {ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {MedicamentATF} from '../../models/medicament-atf';
import {MedicamentService} from '../../services/medicament.service';
import {ConductATF} from '../../models/conduct-atf';
import {AttentionATF} from '../../models/attention-atf';
import {IOption} from 'ng-select';
import {PrescriptionService} from '../../services/prescription.service';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {PrescricaoComplementarService} from "../../services/prescricao-complementar.service";
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {Subscription} from "rxjs";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";


@Component({
  selector: 'app-pharmaceutical-record-atf',
  templateUrl: './pharmaceutical-record-atf.component.html',
  styleUrls: ['./pharmaceutical-record-atf.component.scss']
})
export class PharmaceuticalRecordAtfComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalCobrar') modalCobrar;

  @ViewChild('modalRetorno') modalRetorno;
  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;
  id: number;
  activeId = PatientTabEnum.PRINCIPAL as string;
  pharmaceuticalRecordAtf = {
    paciente: {},
    farmaceutico: {},
    medicamentos: [],
    condutas: [],
    atencoes: [],
    servico: {
      fgCobrado: false
    },
  } as PharmaceuticalRecordATF;
  medicamentATF = {
    medicamento: {}
  } as MedicamentATF;
  conductATF = {
    dhRegistro: this.formatedDate(new Date())
  } as ConductATF;
  attetionATF = {
    dhRegistro: this.formatedDate(new Date())
  } as AttentionATF;
  dateHourMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  prescricaoComplementarFarmacologicaList = [];
  prescricaoComplementarNaoFarmacologicaList = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];
  pacienteSelecionado;

  medicamentOption = null;
  medicamentListOption: Array<IOption> = [];
  editMode = false as boolean;
  pacienteByAgenda;


  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private medicamentService: MedicamentService,
    private pharmaceuticalService: PharmaceuticalService,
    private pharmaceuticalRecordATFService: PharmaceuticalRecordATFService,
    private prescriptionService: PrescriptionService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private prescricaoComplementarService: PrescricaoComplementarService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    // activatedRoute.queryParams.subscribe(params => {
    //
    //   this.id = params.id;
    //   this.editMode = this.id !== undefined;
    // });

    activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });

    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  ngOnInit() {

    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });
    this.prescricaoComplementarService.getAllByType("FARMACOLOGICA").subscribe(result => {
      this.prescricaoComplementarFarmacologicaList = result;
    });

    this.prescricaoComplementarService.getAllByType("NAO_FARMACOLOGICA").subscribe(result => {
      this.prescricaoComplementarNaoFarmacologicaList = result;
    });
    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });

    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });


      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.medicamentService.getAllActives(true).subscribe((medicamentList) => {
      this.medicamentListOption = [];
      medicamentList.forEach((medicament) => {
        this.medicamentListOption.push({
          value: medicament.idMedicamento + '',
          label: medicament.descricao
        });
      });
    });

    if (this.id !== undefined) {
      this.pharmaceuticalRecordATFService.findById(this.id).subscribe((pharmaceuticalRecordATF) => {
        this.pacienteSelecionado = pharmaceuticalRecordATF.paciente;
        this.pharmaceuticalRecordAtf = pharmaceuticalRecordATF;
        this.patientOption = pharmaceuticalRecordATF.paciente.idPaciente + '';
        this.pharmaceuticalOption = pharmaceuticalRecordATF.farmaceutico.idFarmaceutico + '';

        this.pharmaceuticalRecordAtf.servico.fgSugerirRetorno = pharmaceuticalRecordATF.servico.tpServico.fgSugerirRetorno;
        this.pharmaceuticalRecordAtf.servico.qtdDiasRetorno = pharmaceuticalRecordATF.servico.tpServico.qtdDiasRetorno;
      });

    } else {
      this.serviceTypeService.getOne(ServiceTypeEnum.PROTOCOLO_ATF).subscribe((tpServico) => {
        this.pharmaceuticalRecordAtf.servico.vlCobrado = tpServico.vlServico;
        this.pharmaceuticalRecordAtf.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
        this.pharmaceuticalRecordAtf.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
      });
    }
  }

  getPacienteSelecionado() {
    this.patientService.getById(this.patientOption).subscribe(result => {
      this.pacienteSelecionado = result;
      this.getAge();
      this.pharmaceuticalRecordAtf.alergia = this.pacienteSelecionado.infoComplementarPaciente.alergias;
    });
  }

  getAge() {
    const dateFormated = new Date(this.pacienteSelecionado.dataNascimento.replace(/(\d{2}\/)(\d{2}\/)(\d{4})/, '$2$1$3'));
    var today = new Date();
    var birthDate = new Date(dateFormated);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.pacienteSelecionado.idade = age;
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  onInsert() {
    this.pharmaceuticalRecordATFService.insert(this.pharmaceuticalRecordAtf).subscribe((pharmaceuticalRecordAtf) => {
      this.modalCobrar.hide();
      this.showToast('Revisão de Farmacoterapia cadastrada com sucesso');
      this.pacienteSelecionado = pharmaceuticalRecordAtf.paciente;
      this.pharmaceuticalRecordAtf = pharmaceuticalRecordAtf;
      this.patientOption = pharmaceuticalRecordAtf.paciente.idPaciente + '';
      this.pharmaceuticalOption = pharmaceuticalRecordAtf.farmaceutico.idFarmaceutico + '';

      this.id = pharmaceuticalRecordAtf.idProtocoloATF;
      this.pharmaceuticalRecordAtf.idProtocoloATF = pharmaceuticalRecordAtf.idProtocoloATF;

      if (this.pharmaceuticalRecordAtf.servico.tpServico.fgSugerirRetorno) {

        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() + this.pharmaceuticalRecordAtf.servico.tpServico.qtdDiasRetorno);
        this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
        this.modalRetorno.show({backdrop: 'static'});
      } else {
        this.redirectPaciente();
      }

    });
  }

  onUpdate() {
    this.pharmaceuticalRecordATFService.update(this.pharmaceuticalRecordAtf).subscribe(() => {
      this.modalCobrar.hide();
      this.showToast('Revisão de Farmacoterapia alterada com sucesso');
      if (this.pharmaceuticalRecordAtf.servico.tpServico.fgSugerirRetorno) {

        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() + this.pharmaceuticalRecordAtf.servico.tpServico.qtdDiasRetorno);
        this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
        this.modalRetorno.show({backdrop: 'static'});
      }
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  save(myForm: NgForm) {

    this.pharmaceuticalRecordAtf.paciente = this.pacienteSelecionado;
    this.pharmaceuticalRecordAtf.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);
    this.pacienteSelecionado.infoComplementarPaciente.alergias = this.pharmaceuticalRecordAtf.alergia;
    if (this.pharmaceuticalRecordAtf.idProtocoloATF !== undefined) {
      this.onUpdate();

      this.patientService.update(this.pacienteSelecionado).subscribe(result => {
      })
    } else {
      this.onInsert();

      this.patientService.update(this.pacienteSelecionado).subscribe(result => {
      })
    }

  }

  onAddMedicament(medicamentATFForm: NgForm) {
    if (medicamentATFForm.valid) {

      this.medicamentATF.medicamento.idMedicamento = Number(this.medicamentOption);
      this.medicamentService.getById(this.medicamentATF.medicamento.idMedicamento).subscribe((medicament) => {
        this.medicamentATF.medicamento = medicament;

        if (this.medicamentATF.idMedicamentoATF === undefined) {
          this.medicamentATF.idMedicamentoATF = Math.random() * -1;
          this.pharmaceuticalRecordAtf.medicamentos.push(this.medicamentATF);
        }

        this.medicamentATF = {
          medicamento: {}
        } as MedicamentATF;
        medicamentATFForm.resetForm();
      });
    }
  }

  onRemoveMedication(id: number) {
    let medicamentATFList = [];
    this.pharmaceuticalRecordAtf.medicamentos.forEach((medicamento) => {
      if (medicamento.idMedicamentoATF != id) {
        medicamentATFList.push(medicamento);
      }
    });
    this.pharmaceuticalRecordAtf.medicamentos = medicamentATFList;
  }

  onAddConduct(conductATFForm: NgForm) {
    if (conductATFForm.valid) {
      this.pharmaceuticalRecordAtf.condutas.push(this.conductATF);
      this.conductATF = {
        dhRegistro: this.formatedDate(new Date())
      } as ConductATF;
      conductATFForm.resetForm();
    }
  }

  onAddAttention(attentionATFForm: NgForm) {
    if (attentionATFForm.controls['peso'].value || attentionATFForm.controls['altura'].value
      || attentionATFForm.controls['cintura'].value || attentionATFForm.controls['glicose'].value
      || attentionATFForm.controls['colesterol'].value || attentionATFForm.controls['trigliciredes'].value) {

      this.attetionATF.idAtencaoATF = Math.random() * -1;
      this.pharmaceuticalRecordAtf.atencoes.push(this.attetionATF);
      this.attetionATF = {
        dhRegistro: this.formatedDate(new Date())
      } as AttentionATF;
      attentionATFForm.reset({
        dhRegistro: this.formatedDate(new Date())
      });
    }
  }


  onIMCChange() {
    if (this.attetionATF.peso !== undefined && this.attetionATF.altura !== undefined && this.attetionATF.altura > 0 && this.attetionATF.peso > 0) {
      this.attetionATF.imc = (this.attetionATF.peso / ((this.attetionATF.altura * this.attetionATF.altura)
        / 10000)).toFixed(2);

    } else {
      this.attetionATF.imc = 0.00;
    }
  }

  checkZero(data: string) {
    if (data.length == 1) {
      data = '0' + data;
    }
    return data;
  }

  formatedDate(date: Date) {
    let day = this.checkZero(date.getDate() + '');
    let month = this.checkZero((date.getMonth() + 1) + '');
    let year = this.checkZero(date.getFullYear() + '');
    let hour = this.checkZero(date.getHours() + '');
    let minutes = this.checkZero(date.getMinutes() + '');

    return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes;
  }

  onEditMedication(medicamento: MedicamentATF) {
    this.medicamentATF = medicamento;
    this.medicamentOption = medicamento.medicamento.idMedicamento + "";
  }

  openPDF(conduta: ConductATF) {
    if (conduta.prescricaoFarmaceutica) {
      this.pharmaceuticalRecordATFService.pdfByUrl(conduta);
    }
  }

  validateOpen(form) {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }


  findChoices(list) {
    let listAux = list.map(item => {
      return item.nome;
    })
    return (searchText) => listAux.filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getChoiceLabel(list) {
    return (choice) => {
      let aux = list.filter(item => item.nome.toLowerCase().includes(choice.toLowerCase()))[0];
      return `${aux.descricao}`;
    }
  }

  downloadPdfCondutas(data: any) {

    const _data = Object.assign({}, data);
    _data.condutas = _data.condutas.filter(item => item.fgDownload);
    this.prescriptionService.pdfByUrl(this.pharmaceuticalRecordATFService.getPDFUrl(), _data);

  }

  shouldImprimirSelecionados(data) {

    const _data = Object.assign({}, data);
    _data.condutas = _data.condutas.filter(item => item.fgDownload);
    return _data.condutas.length == 0;
  }

  onRemoveConduta(conduta) {
    const index = this.pharmaceuticalRecordAtf.condutas.indexOf(conduta);
    if (index > -1) {
      this.pharmaceuticalRecordAtf.condutas.splice(index, 1);
    }
  }


  private redirectPaciente() {

    this.router.navigate(['/prontuario-atf'], {queryParams: {id: this.id}}).then();
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.pharmaceuticalRecordAtf.farmaceutico.idFarmaceutico,
      idPaciente: this.pharmaceuticalRecordAtf.paciente.idPaciente,
      tpServico: ServiceTypeEnum.PROTOCOLO_ATF,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }
}
