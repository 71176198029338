import {Component, OnInit} from '@angular/core';
import {Earring} from '../../models/earring';
import {EarringService} from 'src/app/services/earring.service';
import {NgForm} from '@angular/forms';
import {ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {IOption} from 'ng-select';

@Component({
  selector: 'app-earring-form',
  templateUrl: './earring-form.component.html',
  styleUrls: ['./earring-form.component.scss']
})
export class EarringFormComponent implements OnInit {

  editMode = false as boolean;
  earring = {flagAtivo: 1} as Earring;

  id: number;
  earringListOption: Array<IOption> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private earringService: EarringService,
    private toastyService: ToastyService,
    private router: Router
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });
  }

  ngOnInit() {
    if (this.id !== undefined) {
      this.earringService.getById(this.id).subscribe((earring) => {
        this.earring = earring;
      });
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  onInsert() {
    this.earringService.insert(this.earring).subscribe((marcabrinco) => {
      this.showToast('Marca de brinco cadastrado com sucesso!');

      this.id = marcabrinco.idMarcaBrinco;
      this.earring.idMarcaBrinco = marcabrinco.idMarcaBrinco;
      this.router.navigate(['/brinco/form/edit'], {queryParams: {id: this.id}}).then();
    });
  }

  onUpdate() {
    this.earringService.update(this.earring).subscribe(() => {
      this.showToast('Marca de brinco alterado com sucesso');
    });
  }

  save(form: NgForm) {
    if (form.valid) {
      if (this.earring.idMarcaBrinco !== undefined) {
        this.onUpdate();
      } else {
        this.onInsert();
      }
    }
  }

  search() {
    this.earringService.getAll().subscribe((earrings) => {
      this.earringListOption = [];
      earrings.forEach((medicament) => {
        this.earringListOption.push({
          value: medicament.idMarcaBrinco + '',
          label: medicament.descricaoMarca
        });
      });
    });
  }

  edit() {
    if (this.id) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/brinco/form/edit'], {queryParams: {id: this.id}}).then();
      });

    } else {
      this.showErrorToast('Selecione uma marca de brinco');
    }
  }
}
