<form #testeCovidForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Teste rápido de COVID-19" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select class="ng-select" [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico" required
                         [ngClass]="{'invalido': !farmaceutico.valid && testeCovidForm.submitted}"
                         #farmaceutico="ngModel"></ng-select>
              <label *ngIf="!farmaceutico.valid && testeCovidForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select class="ng-select" [options]="patientListOption"

                         [ngClass]="{'invalido': !paciente.valid && testeCovidForm.submitted}"
                         [(ngModel)]="patientOption" name="paciente" required
                         #paciente="ngModel"></ng-select>

              <label *ngIf="!paciente.valid && testeCovidForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Solicitante</label>
              <input type="text" class="form-control" [(ngModel)]="testeCovid.solicitante" name="solicitante"
                     [ngClass]="{'is-invalid': (!solicitante.valid) && (solicitante.dirty || testeCovidForm.submitted)}"
                     #solicitante="ngModel" minlength="1" maxlength="200">
              <label *ngIf="!solicitante.valid && (solicitante.dirty || testeCovidForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Solicitante
                inválido</label>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label> Inicio dos Sintomas </label>
              <input type="text" class="form-control" [(ngModel)]="testeCovid.inicioSintoma" name="inicioSintomas"
                     [ngClass]="{'is-invalid': (!inicioSintoma.valid) && (inicioSintoma.dirty || testeCovidForm.submitted)}"
                     #inicioSintoma="ngModel" minlength="1" maxlength="200">
              <label *ngIf="!inicioSintoma.valid && (inicioSintoma.dirty || testeCovidForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Inicio dos Sintomas
                inválido</label>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="sintomasRelatados">Sintomas Relatados</label>
              <textarea type="text" class="form-control" [(ngModel)]="testeCovid.sintomasRelatados" required
                        name="sintomasRelatados" id="sintomasRelatados" rows="10" #sintomasRelatados="ngModel"
                        maxlength="800"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Teste realizado</label>
              <input type="text" class="form-control" [(ngModel)]="testeCovid.testeRealizado" name="testeRealizado"
                     [ngClass]="{'is-invalid': !testeRealizado.valid && (testeRealizado.dirty || testeCovidForm.submitted)}"
                     #testeRealizado="ngModel" minlength="1" maxlength="100" required>
              <label *ngIf="!testeRealizado.valid && (testeRealizado.dirty || testeCovidForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Teste realizado
                inválido</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label> Tipo de dispositivo e metodologia </label>
              <input type="text" class="form-control" [(ngModel)]="testeCovid.tpDispositivoMetodologia"
                     name="tpDispositivoMetodologia"
                     [ngClass]="{'is-invalid': !tpDispositivoMetodologia.valid && (tpDispositivoMetodologia.dirty || testeCovidForm.submitted)}"
                     #tpDispositivoMetodologia="ngModel" minlength="1" maxlength="100">
              <label
                *ngIf="!tpDispositivoMetodologia.valid && (tpDispositivoMetodologia.dirty || testeCovidForm.submitted)"
                class="error jquery-validation-error small form-text invalid-feedback">Tipo de dispositivo e metodologia
                inválido</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label> Lote </label>
              <input type="text" class="form-control" [(ngModel)]="testeCovid.lote" name="lote"
                     [ngClass]="{'is-invalid': !lote.valid && (lote.dirty || testeCovidForm.submitted)}"
                     #lote="ngModel" minlength="1" maxlength="100">
              <label *ngIf="!lote.valid && (lote.dirty || testeCovidForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Lote inválido</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label> Validade</label>
              <input type="text" class="form-control" [(ngModel)]="testeCovid.validade" name="validade"
                     [ngClass]="{'is-invalid': (!validade.valid ) && (validade.dirty || testeCovidForm.submitted)}"
                     #validade="ngModel" minlength="1" maxlength="100" required
                     mask="d0/M0/0000" [leadZeroDateTime]="true"
                     [dropSpecialCharacters]="false">
              <label *ngIf="!validade.valid && (validade.dirty || testeCovidForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Validade inválida</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label> Resultado </label>
              <input type="text" class="form-control" [(ngModel)]="testeCovid.resultado" name="resultado"
                     [ngClass]="{'is-invalid': !resultado.valid && (resultado.dirty || testeCovidForm.submitted)}"
                     #resultado="ngModel" minlength="1" maxlength="100" required>
              <label *ngIf="!resultado.valid && (resultado.dirty || testeCovidForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Resultado inválido</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaorientacao">Orientação</label>
              <textarea type="text" class="form-control" [(ngModel)]="testeCovid.orientacao"
                        name="orientacao" id="textAreaorientacao" rows="10" #orientacao="ngModel"
                        maxlength="1000"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="testeCovid.observacao"
                        name="observacoes" id="textAreaObservacoes" rows="10" #observacoes="ngModel"
                        maxlength="800"></textarea>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
  <button mat-fab color="primary" class="add-button" (click)="validateOpen(testeCovidForm)" matTooltip="Salvar">
    <i
      class="material-icons">check</i></button>
</form>

<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Teste rápido de COVID-19?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="testeCovid.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="testeCovid.servico.vlCobrado"
               [disabled]="!testeCovid.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || testeCovidForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="testeCovid.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(testeCovidForm)">Salvar</button>

  </div>
</app-ui-modal>

<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
