import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {PrescricaoComplementar} from '../models/prescricao';
import {GlobalConstants} from '../globals/global-constants';

@Injectable({
  providedIn: 'root'
})
export class PrescricaoComplementarService {

  url = GlobalConstants.apiURL + 'prescricao-complementar';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(prescricao: PrescricaoComplementar): Observable<PrescricaoComplementar> {
    return this.httpClient.post<PrescricaoComplementar>(this.url, prescricao, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  update(prescricao: PrescricaoComplementar): Observable<PrescricaoComplementar> {
    return this.httpClient.put<PrescricaoComplementar>(this.url + '/' + prescricao.id, prescricao, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(): Observable<PrescricaoComplementar[]> {
    return this.httpClient.get<PrescricaoComplementar[]>(this.url, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllByType(type): Observable<PrescricaoComplementar[]> {
    return this.httpClient.get<PrescricaoComplementar[]>(`${this.url}/tipo/${type}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllActives(): Observable<PrescricaoComplementar[]> {
    return this.httpClient.get<PrescricaoComplementar[]>(this.url + '/active', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number): Observable<PrescricaoComplementar> {
    return this.httpClient.get<PrescricaoComplementar>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
