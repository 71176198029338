import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {CholesterolDeclaration} from '../models/cholesterol-declaration';

@Injectable({
  providedIn: 'root'
})
export class CholesterolDeclarationService {

  url = GlobalConstants.apiURL + 'declaracao/colesterol';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(cholesterolDeclaration: CholesterolDeclaration): Observable<CholesterolDeclaration> {
    return this.httpClient.post<CholesterolDeclaration>(this.url, cholesterolDeclaration, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  getPDFUrlById(id: number) {
    return this.url + '/' + id + '/pdf';
  }

}
