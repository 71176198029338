<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Agenda" cardClass="elite-calendar" [options]="false">
      <div class='app' *ngIf="!notSupported">
        <div class='app-calendar' *ngIf="calendarVisible">
          <full-calendar #calendar [locales]="locales" defaultView="dayGridMonth" [header]="{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            }" [plugins]="calendarPlugins" [weekends]="calendarWeekends" [events]="calendarEvents"
                         (dateClick)="handleDateClick($event)" (eventClick)="updateDateClick($event)"></full-calendar>
        </div>
      </div>
      <div class="col-sm-12 text-center" *ngIf="notSupported">
        <div class="alert alert-danger"><code>css-animator</code> does not support in IE.</div>
      </div>
    </app-card>
  </div>
</div>

<app-ui-modal #gridSystemModal>
  <div class="app-modal-header">
    <h5 class="modal-title">Agendamento</h5>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label> Farmacêutico* </label>
          <ng-select [ngClass]="'ng-select'" [options]="pharmaceuticalListOption" [(ngModel)]="pharmaceuticalOption"
                     name="farmaceutico" #farmaceutico="ngModel"></ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label> Paciente* </label>
          <ng-select [ngClass]="'ng-select'" [options]="patientListOption" [(ngModel)]="patientOption" name="paciente"
                     #paciente="ngModel"></ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label> Serviço* </label>
          <ng-select [ngClass]="'ng-select'" [options]="serviceListOption" [(ngModel)]="serviceOption" name="servico"
                     #servico="ngModel"></ng-select>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>

  </div>
  <div class="app-modal-footer">

    <button type="button" class="btn btn-primary" (click)="remove()"
            style="margin-right: 8px; background-color: #fff; border-color: #4e6f94; color:black">Remover
    </button>
    <button type="button" class="btn btn-primary" (click)="redirect()"
            style="margin-right: 8px; background-color: #4e6f94; border-color: #4e6f94;">Realizar serviço
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">Confirmar</button>
  </div>
</app-ui-modal>
