import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ServiceTypeService} from '../services/service-type.service';
import {ServiceType} from '../models/service-type';
import {ToastyService} from 'ng2-toasty';
import {ConfigGeral} from '../models/config-geral';
import {ConfigGeralService} from '../services/config-geral.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from "angular-datatables";


@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})
export class ConfigsComponent implements OnInit {

  constructor(
    private configGeralService: ConfigGeralService,
    private serviceTypeService: ServiceTypeService,
    private toastyService: ToastyService,
  ) {
  }

  items = ['Amsterdam', 'Washington', 'Sydney', 'Beijing', 'Cairo'];
  @ViewChild('modalPrecificacao') modalPrecificacao;
  editingService: any = {};
  servicetListConfig: any = {};
  serviceTypeList: ServiceType[] = [];
  configGeral: ConfigGeral = {
    fgImprimeViaFarmaceutico: false,
    fgDesconsideraServicoAbonadoCalculoTicketMedio: true,
    dsObservacaoAplicaoBrincoDefault: '',
    dsDescricaoDeclaracaoComparecimentoDefault: '',
    dsOrientacaoCovid19Default: ''
  };
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtInstance: Promise<DataTables.Api>;

  ngOnInit() {
    this.getTable();
    this.configGeralService.getOne().subscribe((configGeral) => {
      this.configGeral = configGeral;
    });
  }

  getTable() {

    this.servicetListConfig = {
      ajax: (dataTablesParameters: any, callback) => {
        this.serviceTypeService.getAll().subscribe((serviceTypeList) => {
          this.serviceTypeList = serviceTypeList;
          callback({
            recordsTotal: serviceTypeList.length,
            recordsFiltered: serviceTypeList.length,
            data: serviceTypeList
          });
        });
      },

      info: false,
      paging: true,
      searching: true,
      pageLength: 25,
      pagingType: 'full_numbers',
      drawCallback: () => {
        $('.classe-para-click').on('click', (e) => {  //clicent on button with class `buttonClass`
          const id = e.target.getAttribute("id-servico");
          var newArray = this.serviceTypeList.filter(function (el) {
            return el.idTipoServico + "" == id;
          });
          this.editingService = newArray[0];
          this.edit();
        });
      },
      language: {
        emptyTable: 'Nenhum serviço encontrado',
        info: '',
        infoEmpty: '',
        infoFiltered: '',
        infoPostFix: '',
        decimal: '',
        thousands: '',
        lengthMenu: '',
        loadingRecords: '',
        processing: '',
        search: 'Pesquisar',
        searchPlaceholder: '',
        zeroRecords: 'Nenhum serviço encontrado',
        url: '',

        paginate: {
          last: 'Último',
          next: 'Próximo',
          first: 'Primeiro',
          previous: 'Anterior',
        }
      },

      columns: [{
        title: 'Nome',
        data: 'dsServico'
      }, {
        title: 'Valor do Serviço',
        data: 'vlServico'
      }, {
        title: 'Ações',
        searchable: false,
        render: function (data: any, type: any, full: any) {
          return '<a class="btn btn-outline-primary btn-sm classe-para-click" id-servico="' + full.idTipoServico + '">Editar</a>';
        }
      }],
    };
  }

  edit() {
    console.log(this.editingService)
    this.modalPrecificacao.show({disableClose: true});

  }

  save(configsForm: NgForm) {
    if (configsForm.valid) {
      this.configGeralService.save(this.configGeral).subscribe(() => {
        this.showToast('Configurações alteradas com sucesso');
      });
    }
  }

  updateServico(servico) {
    this.configGeralService.save(this.configGeral).subscribe(() => {
    });
    this.serviceTypeService.update(servico).subscribe(() => {
      this.showToast('Serviço ' + servico.dsServico + ' alterado com sucesso');
      this.modalPrecificacao.hide();
      this.reloadInfos();
    });
  }

  cancel() {
    this.reloadInfos();
    this.modalPrecificacao.hide();
  }

  private reloadInfos() {

    this.configGeralService.getOne().subscribe((configGeral) => {
      this.configGeral = configGeral;
    });
    this.serviceTypeService.getAll().subscribe((serviceTypeList) => {
      this.serviceTypeList = serviceTypeList;
      this.servicetListConfig.recordsTotal = serviceTypeList.length,
        this.servicetListConfig.recordsFiltered = serviceTypeList.length,
        this.servicetListConfig.data = serviceTypeList;

      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload()
      });
    });
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

}
