import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Pharmaceutical} from '../models/pharmaceutical';
import {GlobalConstants} from '../globals/global-constants';
import {ChangePassword} from '../models/change-password';

@Injectable({
  providedIn: 'root'
})
export class PharmaceuticalService {

  url = GlobalConstants.apiURL + 'farmaceutico';
  urlOauth = GlobalConstants.oauthURL + 'usuario';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(pharmaceutic: Pharmaceutical): Observable<Pharmaceutical> {
    return this.httpClient.post<Pharmaceutical>(this.url, pharmaceutic, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  changePassword(changePassword: ChangePassword): Observable<void> {
    return this.httpClient.post<void>(this.urlOauth + '/change/password', changePassword, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  update(pharmaceutic: Pharmaceutical): Observable<Pharmaceutical> {
    return this.httpClient.put<Pharmaceutical>(this.url + '/' + pharmaceutic.idFarmaceutico, pharmaceutic, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(): Observable<Pharmaceutical[]> {
    return this.httpClient.get<Pharmaceutical[]>(this.url, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  count(): Observable<Pharmaceutical[]> {
    return this.httpClient.get<Pharmaceutical[]>(`${this.url}/count`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number): Observable<Pharmaceutical> {
    return this.httpClient.get<Pharmaceutical>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
