<form #pharmaceuticalForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Farmacêutico" [options]="false">
        <h5>Dados pessoais</h5>
        <hr>
        <div class="row">
          <div class="col-md-1">
            <div class="form-group">
              <label> Código </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.idFarmaceutico" name="idFarmaceutico"
                     disabled>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label> Nome* </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.nome" name="name"
                     [ngClass]="{'is-invalid': !name.valid && (name.dirty || pharmaceuticalForm.submitted)}"
                     #name="ngModel" minlength="2"
                     maxlength="250"
                     required>
              <label *ngIf="!name.valid && (name.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Nome incorreto</label>
            </div>
          </div>
          <div class="custom-control custom-switch switch-ativo col-md-1" >
            <input type="checkbox" class="custom-control-input" id="customswitch1" [(ngModel)]="pharmaceutical.flagAtivo"
                   name="fgAtivo">
            <label class="custom-control-label" for="customswitch1">Ativo</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> CRF </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.crf" name="crf"
                     #crf="ngModel" >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label> Telefone </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.contato" name="contato"
                     [ngClass]="{'is-invalid': !contato.valid && (contato.dirty || pharmaceuticalForm.submitted)}"
                     #contato="ngModel"
                     minlength="15"
                     [textMask]="{mask: phoneMask}"
                     maxlength="255">
            </div>
          </div>
        </div>

        <h5 class="mt-5">Endereço</h5>
        <hr>
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label> CEP* </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.cep" name="cep"
                     (ngModelChange)="onCepChanged()"
                     [ngClass]="{'is-invalid': !cep.valid && (cep.dirty || pharmaceuticalForm.submitted)}"
                     #cep="ngModel" minlength="8"
                     maxlength="8" pattern="[0-9]*"
                     required>
              <label *ngIf="!cep.valid && (cep.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">CEP incorreto</label>
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
              <label> Estado* </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.estado" name="estado"
                     [ngClass]="{'is-invalid': !estado.valid && (estado.dirty || pharmaceuticalForm.submitted)}"
                     #estado="ngModel"
                     minlength="4"
                     maxlength="19"
                     required>
              <label *ngIf="!estado.valid && (estado.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um estado</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label> Cidade* </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.cidade" name="cidade"
                     [ngClass]="{'is-invalid': !cidade.valid && (cidade.dirty || pharmaceuticalForm.submitted)}"
                     #cidade="ngModel"
                     minlength="2"
                     maxlength="64"
                     required>
              <label *ngIf="!cidade.valid && (cidade.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe uma cidade</label>
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
              <label> Logradouro* </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.logradouro" name="logradouro"
                     [ngClass]="{'is-invalid': !logradouro.valid && (logradouro.dirty || pharmaceuticalForm.submitted)}"
                     #logradouro="ngModel"
                     minlength="2"
                     maxlength="128"
                     required>
              <label *ngIf="!logradouro.valid && (logradouro.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um
                logradouro</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <div class="form-group">
              <label> Bairro* </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.bairro" name="bairro"
                     [ngClass]="{'is-invalid': !bairro.valid && (bairro.dirty || pharmaceuticalForm.submitted)}"
                     #bairro="ngModel"
                     minlength="2"
                     maxlength="128"
                     required>
              <label *ngIf="!bairro.valid && (bairro.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um bairro</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label> Número* </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.numero" name="numero"
                     [ngClass]="{'is-invalid': !numero.valid && (numero.dirty || pharmaceuticalForm.submitted)}"
                     #numero="ngModel"
                     minlength="0"
                     maxlength="16"
                     required>
              <label *ngIf="!numero.valid && (numero.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Informe um número</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label> Complemento </label>
              <input type="text" class="form-control" [(ngModel)]="pharmaceutical.complemento" name="complemento"
                     [ngClass]="{'is-invalid': !complemento.valid && (complemento.dirty || pharmaceuticalForm.submitted)}"
                     #complemento="ngModel"
                     minlength="0"
                     maxlength="128">
            </div>
          </div>
        </div>

        <h5 class="mt-5" *ngIf="!editMode || ( editMode && isCurrentAuth )">Acesso ao sistema</h5>
        <hr *ngIf="!editMode">
        <div class="row" *ngIf="!editMode || ( editMode && isCurrentAuth )">
          <div class="col-md-6">
            <div class="form-group">
              <label> E-mail </label>
              <input type="email" class="form-control" [(ngModel)]="pharmaceutical.email" name="email"
                     [ngClass]="{'is-invalid': !email.valid && !editMode && (email.dirty || pharmaceuticalForm.submitted)}"
                     #email="ngModel"
                     [disabled]="editMode"
                     minlength="5"
                     maxlength="128">
              <label *ngIf="!email.valid &&  !editMode && (email.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">E-mail incorreto</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label> Senha </label>
              <input type="password" class="form-control" [(ngModel)]="pharmaceutical.senha" name="password"
                     [ngClass]="{'is-invalid': !password.valid && (password.dirty || pharmaceuticalForm.submitted)}"
                     #password="ngModel" minlength="6" maxlength="24">
              <label *ngIf="!password.valid && (password.dirty || pharmaceuticalForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">
                Senha deve possuir no mínimo 6 caracteres
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label> Confirme a senha </label>
              <input type="password" class="form-control" [(ngModel)]="changePassword.passwordConfirm"
                     name="passwordConfirm" minlength="6" maxlength="24">
            </div>
          </div>
        </div>
        <div class="row" *ngIf="editMode && isCurrentAuth">
          <div class="col-md-6" *ngIf="editMode">
            <div class="form-group">
              <label> Senha atual </label>
              <input type="password" class="form-control" [(ngModel)]="changePassword.passwordCurrent"
                     name="passwordCurrent"
                     #passwordCurrent="ngModel" minlength="6" maxlength="24">
            </div>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-primary" (click)="onChangePassword()" style="float: right;" type="button">Gravar nova senha</button>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" matTooltip="Salvar" (click)="save(pharmaceuticalForm)"><i
    class="material-icons">check</i></button>

</form>
<ng2-toasty position="top-right"></ng2-toasty>
