import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {PharmaceuticalRecordATF} from '../models/pharmaceutical-record-atf';

@Injectable({
  providedIn: 'root'
})
export class PharmaceuticalRecordATFService {

  url = GlobalConstants.apiURL + 'protocolo/atf';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  getPDFUrlById(id: number): string {
    return this.url + '/' + id + '/pdf';
  }

  getPDFUrl() {
    return this.url + '/pdf';
  }

  insert(pharmaceuticalRecord: PharmaceuticalRecordATF): Observable<PharmaceuticalRecordATF> {
    return this.httpClient.post<PharmaceuticalRecordATF>(this.url, pharmaceuticalRecord, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  update(pharmaceuticalRecord: PharmaceuticalRecordATF): Observable<PharmaceuticalRecordATF> {
    return this.httpClient.put<PharmaceuticalRecordATF>(this.url, pharmaceuticalRecord, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  findByIdPatient(id: number): Observable<PharmaceuticalRecordATF[]> {
    return this.httpClient.get<PharmaceuticalRecordATF[]>(this.url + '/by/paciente/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<PharmaceuticalRecordATF> {
    return this.httpClient.get<PharmaceuticalRecordATF>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  pdfByUrl(data) {
    return this.httpClient.post(this.url + "/pdf", data).subscribe((response) => {
      (response as any).pdfs.forEach((element) => {
        this.downloadPDF(element.name, element.content);
      });
    });
  }

  downloadPDF(name, pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
  }

}
