<form #earringApplicationForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Aplicação de Brinco" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Farmacêutico* </label>
              <ng-select class="ng-select" [options]="pharmaceuticalListOption"
                         [(ngModel)]="pharmaceuticalOption" name="farmaceutico" required
                         [ngClass]="{'invalido': !farmaceutico.valid && earringApplicationForm.submitted}"
                         #farmaceutico="ngModel"></ng-select>
              <label *ngIf="!farmaceutico.valid && earringApplicationForm.submitted"
                     class="error">Farmacêutico é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Paciente* </label>
              <ng-select class="ng-select" [options]="patientListOption"

                         [ngClass]="{'invalido': !paciente.valid && earringApplicationForm.submitted}"
                         [(ngModel)]="patientOption" name="paciente" required
                         #paciente="ngModel"></ng-select>

              <label *ngIf="!paciente.valid && earringApplicationForm.submitted"
                     class="error">Paciente é obrigatório</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Equipamento* </label>
              <ng-select class="ng-select" [options]="equipmentListOption" required
                         [ngClass]="{'invalido': !equipamento.valid && earringApplicationForm.submitted}"
                         [(ngModel)]="equipmentOption" name="equipamento"
                         #equipamento="ngModel"></ng-select>
              <label *ngIf="!equipamento.valid && earringApplicationForm.submitted"
                     class="error">Equipamento é obrigatório</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label> Marca* </label>
              <ng-select class="ng-select" [options]="earringListOption" required
                         [ngClass]="{'invalido': !earring.valid && earringApplicationForm.submitted}"
                         [(ngModel)]="earringOption" name="earring"
                         #earring="ngModel"></ng-select>
              <label *ngIf="!earring.valid && earringApplicationForm.submitted"
                     class="error">Marca é obrigatória</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label> Lote* </label>
              <input type="text" class="form-control" [(ngModel)]="earringApplication.lote" name="lote"
                     [ngClass]="{'is-invalid': !lote.valid && (lote.dirty || earringApplicationForm.submitted)}"
                     #lote="ngModel" minlength="1" maxlength="64" required>
              <label *ngIf="!lote.valid && (lote.dirty || earringApplicationForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Lote
                inválido</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label> Orelha* </label>
              <select class="form-control form-control-default" [(ngModel)]="earringApplication.tipoAplicacao"
                      [ngClass]="{'is-invalid': !tipoAplicacao.valid && (tipoAplicacao.dirty || earringApplicationForm.submitted)}"
                      name="tipoAplicacao" #tipoAplicacao="ngModel" required>
                <option value="0">Esquerda</option>
                <option value="1">Direita</option>
                <option value="2">Ambas</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaObservacoes">Observações</label>
              <textarea type="text" class="form-control" [(ngModel)]="earringApplication.observacao"
                        name="observacoes" id="textAreaObservacoes" rows="10" #observacoes="ngModel"
                        maxlength="1024"></textarea>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
  <button mat-fab color="primary" class="add-button" (click)="validateOpen(earringApplicationForm)" matTooltip="Salvar">
    <i
      class="material-icons">check</i></button>
</form>

<ng2-toasty position="top-right"></ng2-toasty>

<app-ui-modal #modalCobrar dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Cobrar Aplicação de Brinco?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row modal-cobrar">
      <div class="custom-control custom-switch switch-ativo col-md-2">
        <input type="checkbox" class="custom-control-input" id="customswitch1"
               [(ngModel)]="earringApplication.servico.fgCobrado"
               name="fgCobrado" #fgCobrado="ngModel">
        <label class="custom-control-label" for="customswitch1">Cobrado</label>
      </div>
      <div class="col-md-4">
        <label> Preço* </label>
        <input type="text" class="form-control" name="vlCobrado" [(ngModel)]="earringApplication.servico.vlCobrado"
               [disabled]="!earringApplication.servico.fgCobrado"
               [ngClass]="{'is-invalid': fgCobrado.value && !vlCobrado.valid && (vlCobrado.dirty || earringApplicationForm.submitted)}"
               #vlCobrado="ngModel" [required]="fgCobrado.value"
               (change)="earringApplication.servico.vlCobrado = vlCobrado.value.replace(',', '.');"
               minlength="1" maxlength="7" pattern="[0-9]{1,4}[.,]{0,1}[0-9]{0,2}">

      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()" style="margin-right: 8px">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="save(earringApplicationForm)">Salvar</button>

  </div>
</app-ui-modal>

<app-ui-modal #modalRetorno dialogClass="modal-dialog-centered" data-backdrop="static">
  <div class="app-modal-header">
    <h5 class="modal-title">Criar agendamento de retorno?</h5>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label">Data e hora</label>
        <input [textMask]="{mask: maskDateHour}" [(ngModel)]="dataHora" type="text" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelRetorno()" style="margin-right: 8px">
      Não
    </button>
    <button type="button" class="btn btn-primary" (click)="agendarRetorno()">Sim</button>

  </div>
</app-ui-modal>
