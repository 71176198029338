import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PharmaceuticalRecordSoap} from '../../models/pharmaceutical-record-soap';
import {PatientService} from '../../services/patient.service';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {PharmaceuticalRecordService} from '../../services/pharmaceutical-record.service';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {PrescriptionService} from '../../services/prescription.service';
import {IOption} from 'ng-select';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {PrescricaoComplementarService} from "../../services/prescricao-complementar.service";
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-pharmaceutical-record',
  templateUrl: './pharmaceutical-record-soap.component.html',
  styleUrls: ['./pharmaceutical-record-soap.component.scss']
})
export class PharmaceuticalRecordSoapComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalCobrar') modalCobrar;
  @ViewChild('modalRetorno') modalRetorno;
  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;
  id: number;
  view = false as boolean;
  pharmaceuticalRecord = {
    servico: {
      fgCobrado: false
    },
  } as PharmaceuticalRecordSoap;

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];
  prescricaoComplementarFarmacologicaList = [];
  prescricaoComplementarNaoFarmacologicaList = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];
  pacienteByAgenda;

  constructor(
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private prescriptionService: PrescriptionService,
    private pharmaceuticalService: PharmaceuticalService,
    private pharmaceuticalRecordService: PharmaceuticalRecordService,
    private toastyService: ToastyService,
    private router: Router,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private prescricaoComplementarService: PrescricaoComplementarService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    // activatedRoute.queryParams.subscribe(params => {
    //   this.id = params.id;
    //   this.view = this.id !== undefined;
    // });

    activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.view = this.id !== undefined;
    });
    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });
    this.prescricaoComplementarService.getAllByType("FARMACOLOGICA").subscribe(result => {
      this.prescricaoComplementarFarmacologicaList = result;
    });

    this.prescricaoComplementarService.getAllByType("NAO_FARMACOLOGICA").subscribe(result => {
      this.prescricaoComplementarNaoFarmacologicaList = result;
    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });

      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });

    if (this.id !== undefined) {
      this.pharmaceuticalRecordService.findById(this.id).subscribe((pharmaceuticalRecord) => {
        this.pharmaceuticalRecord = pharmaceuticalRecord;
        this.pharmaceuticalRecord.idPaciente = pharmaceuticalRecord['paciente']['idPaciente'];
        this.pharmaceuticalRecord.idFarmaceutico = pharmaceuticalRecord['farmaceutico']['idFarmaceutico'];
        this.pharmaceuticalRecord.servico.fgSugerirRetorno = pharmaceuticalRecord.servico.tpServico.fgSugerirRetorno;
        this.pharmaceuticalRecord.servico.qtdDiasRetorno = pharmaceuticalRecord.servico.tpServico.qtdDiasRetorno;
      });

    } else {
      this.serviceTypeService.getOne(ServiceTypeEnum.PRONTUARIO_SOAP).subscribe((tpServico) => {
        this.pharmaceuticalRecord.servico.vlCobrado = tpServico.vlServico;
        this.pharmaceuticalRecord.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
        this.pharmaceuticalRecord.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
      });
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  save(pharmaceuticalRecordForm: NgForm) {
    if (pharmaceuticalRecordForm.valid) {
      if (!this.pharmaceuticalOption) {
        this.showErrorToast('Informe um farmacêutico');

      } else if (!this.patientOption) {
        this.showErrorToast('Informe um paciente');

      } else {
        this.pharmaceuticalRecord.idPaciente = Number(this.patientOption);
        this.pharmaceuticalRecord.idFarmaceutico = Number(this.pharmaceuticalOption);

        this.pharmaceuticalRecordService.insert(this.pharmaceuticalRecord).subscribe((pharmaceuticalRecord) => {
          this.modalCobrar.hide();
          this.pharmaceuticalRecord.prescricaoFarmaceutica = pharmaceuticalRecord.prescricaoFarmaceutica;
          this.showToast('Prontuário cadastrado com sucesso');
          if (this.pharmaceuticalRecord.servico.fgSugerirRetorno) {

            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + this.pharmaceuticalRecord.servico.qtdDiasRetorno);
            this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
            this.modalRetorno.show({backdrop: 'static'});
          } else {
            this.redirectPaciente();
          }

        });
      }
    }
  }

  openPDF() {
    if (this.pharmaceuticalRecord.prescricaoFarmaceutica) {
      const id = this.pharmaceuticalRecord.prescricaoFarmaceutica.idPrescricaoFarmaceutica;
      const url = this.prescriptionService.getPDFUrlById(id);
      this.prescriptionService.pdfById(url, 'prescricao-farmaceutica', this.id);
    }
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  validateOpen(form) {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }

  findChoices(list) {
    let listAux = list.map(item => {
      return item.nome;
    })
    return (searchText) => listAux.filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getChoiceLabel(list) {
    return (choice) => {
      let aux = list.filter(item => item.nome.toLowerCase().includes(choice.toLowerCase()))[0];
      return `${aux.descricao}`;
    }
  }


  private redirectPaciente() {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: this.pharmaceuticalRecord.idPaciente,
        pdf: this.pharmaceuticalRecord.prescricaoFarmaceutica.idPrescricaoFarmaceutica,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.PRONTUARIO_SOAP,
      }
    }).then();
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.pharmaceuticalRecord.idFarmaceutico,
      idPaciente: this.pharmaceuticalRecord.idPaciente,
      tpServico: ServiceTypeEnum.PRONTUARIO_SOAP,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }

}
