<div class="auth-wrapper" style="background: #0095da !important;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="text-center">
          
      <img id="main-logo" src="assets/images/EVOX-logo.png" alt="" style="width: 60%;">
          <h2 class="my-4" style="color: white;">Oops! Seu período de demonstração expirou!</h2>
          <h4 class="my-4" style="color: white;">Entre em contato com o administrador do sistema.</h4>
        </div>
      </div>
    </div>
  </div>
</div>
