import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {MedicamentService} from 'src/app/services/medicament.service';
import {Medicament} from 'src/app/models/medicament';
import {IOption} from 'ng-select';

@Component({
  selector: 'app-medicament-form',
  templateUrl: './medicament-form.component.html',
  styleUrls: ['./medicament-form.component.scss']
})
export class MedicamentFormComponent implements OnInit {

  buscarAtivos = true;

  medicament = {
    flagAtivo: true
  } as Medicament;
  editMode = false as boolean;

  id: number;
  medicamentListOption: Array<IOption> = [];

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private activatedRoute: ActivatedRoute,
    private medicamentService: MedicamentService,
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.editMode = this.id !== undefined;
    });
  }

  ngOnInit() {
    if (this.id !== undefined) {
      this.medicamentService.getById(this.id).subscribe((medicament) => {
        this.medicament = medicament;
      });
    }
  }

  onInsert() {
    this.medicamentService.insert(this.medicament).subscribe((medicament) => {
      this.showToast('Medicamento cadastrado com sucesso!');

      this.id = medicament.idMedicamento;
      this.medicament.idMedicamento = medicament.idMedicamento;
      this.router.navigate(['/medicamento/form/edit'], {queryParams: {id: this.id}}).then();
    });
  }

  onUpdate() {
    this.medicamentService.update(this.medicament).subscribe(() => {
      this.showToast('Medicamento alterado com sucesso');
    });
  }

  save(form: NgForm) {
    if (form.valid) {
      if (this.medicament.idMedicamento !== undefined) {
        this.onUpdate();

      } else {
        this.onInsert();
      }
    }
  }

  search() {
    this.medicamentService.getAllActives(this.buscarAtivos).subscribe((medicaments) => {
      this.medicamentListOption = [];
      medicaments.forEach((medicament) => {
        this.medicamentListOption.push({
          value: medicament.idMedicamento + '',
          label: medicament.descricao + '' + (medicament.codigoBarras == null ? '' : ' - ' + medicament.codigoBarras)
        });
      });
    });
  }

  edit() {
    if (this.id) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/medicamento/form/edit'], {queryParams: {id: this.id}}).then();
      });

    } else {
      this.showErrorToast('Selecione um Medicamento');
    }
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }
}
