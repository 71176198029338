import {Company} from '../models/company';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  url = GlobalConstants.apiURL + 'empresa';
  urlOauth = GlobalConstants.apiURL + 'evox-control/empresa';

  oauthURL = GlobalConstants.apiURL + 'me';

  constructor(private httpClient: HttpClient) {

  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  update(company: Company): Observable<Company> {
    return this.httpClient.put<Company>(this.url + '/' + company.idEmpresa, company, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  insert(company: Company): Observable<Company> {
    return this.httpClient.post<Company>(this.url, company, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getOne(): Observable<Company> {
    return this.httpClient.get<Company>(this.url, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getOneByOauth(): Observable<any> {
    console.log('aaa')
    return this.httpClient.get<Company>(this.oauthURL, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  async validateDemo(): Promise<boolean> {
    const empresa: Company = await this.getOne().toPromise();

    if(empresa && empresa.flagIsDemo && empresa.flagDemoVencida){

      return false;
    }

    return true;
  }

  handleError(error: HttpErrorResponse) {
    console.log(error)
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

}
