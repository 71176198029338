<div class="row">
  <div class="col-sm-12">
    <app-card [hidHeader]="true" cardClass="tab-card">
      <h6 class="mb-3">Relatório de Atendimentos</h6>
      <hr>

      <h6 class="mb-3">Filtros</h6>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label> Paciente </label>
            <ng-select
              class="ng-select" required [options]="patientListOption"
              [(ngModel)]="filtros.paciente"></ng-select>

          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label> Farmacêutico </label>
            <ng-select class="ng-select" required [options]="pharmaceuticalListOption"
                       [(ngModel)]="filtros.farmaceutico"></ng-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label> Serviço </label>
            <ng-select class="ng-select" required [options]="typeServiceListOption"
                       [(ngModel)]="filtros.tipoServico"></ng-select>
          </div>
        </div>
        <div class="col-md-2">
          <a class="btn btn-outline-primary btn-sm" style="float: right;"
             (click)="buscar()">Filtrar</a>
        </div>
      </div>
    </app-card>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="" cardClass="card-datatable" [options]="false">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table id="testeTable" datatable [dtOptions]="patientListConfig"
                   class="table table-striped table-bordered table-hover"></table>
          </div>
        </div>
      </div>

    </app-card>
  </div>
</div>
