import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {BloodPressureStatement} from '../models/blood-pressure-statement';

@Injectable({
  providedIn: 'root'
})
export class BloodPressureStatementService {

  url = GlobalConstants.apiURL + 'declaracao/pressao/arterial';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  insert(bloodPressureStatement: BloodPressureStatement): Observable<BloodPressureStatement> {
    return this.httpClient.post<BloodPressureStatement>(this.url, bloodPressureStatement, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  getPDFUrlById(id: number) {
    return this.url + '/' + id + '/pdf';
  }

  getPDFUrl() {
    return this.url + '/pdf';
  }

  update(bloodPressureStatement: BloodPressureStatement): Observable<BloodPressureStatement> {
    return this.httpClient.put<BloodPressureStatement>(this.url, bloodPressureStatement, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<BloodPressureStatement> {
    return this.httpClient.get<BloodPressureStatement>(this.url + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

}
