import {Component, OnInit} from '@angular/core';
import {PrescricaoComplementarService} from "../../services/prescricao-complementar.service";

@Component({
  selector: 'app-prescricao-list',
  templateUrl: './prescricao-complementar-list.component.html',
  styleUrls: ['./prescricao-complementar-list.component.scss']
})
export class PrescricaoComplementarListComponent implements OnInit {

  prescricaoComplementarListConfig: any = {};

  constructor(private prescricaoComplementarService: PrescricaoComplementarService) {
  }

  ngOnInit() {
    this.prescricaoComplementarListConfig = {
      ajax: (dataTablesParameters: any, callback) => {
        this.prescricaoComplementarService.getAll().subscribe(prescricaoComplementarList => {
          callback({
            recordsTotal: prescricaoComplementarList.length,
            recordsFiltered: prescricaoComplementarList.length,
            data: prescricaoComplementarList
          });
        });
      },

      info: false,
      paging: true,
      searching: true,
      pageLength: 25,
      pagingType: 'full_numbers',

      language: {
        emptyTable: 'Nenhuma prescrição complementar encontrada',
        info: '',
        infoEmpty: '',
        infoFiltered: '',
        infoPostFix: '',
        decimal: '',
        thousands: '',
        lengthMenu: '',
        loadingRecords: '',
        processing: '',
        search: 'Pesquisar',
        searchPlaceholder: '',
        zeroRecords: 'Nenhuma prescrição complementar encontrada',
        url: '',

        paginate: {
          last: 'Último',
          next: 'Próximo',
          first: 'Primeiro',
          previous: 'Anterior',
        }
      },

      columns: [{
        title: 'Código',
        data: 'id'
      }, {
        title: 'Nome',
        data: 'nome'
      }, {
        title: 'Tipo',
        render: function (data: any, type: any, full: any) {
          return full.tipoPrescricao == 'FARMACOLOGICA' ? 'Farmacológica' : 'Não Farmacológica';
        }
      }, {
        title: 'Ações',
        searchable: false,
        render: function (data: any, type: any, full: any) {
          return '<a class="btn btn-outline-primary btn-sm" href="prescricao-complementar/form/edit?id=' + full.id + '">Editar</a>';
        }
      }],
    };
  }

}
