<form #prescriptionForm="ngForm" class="editForm" novalidate>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Prescrição" [options]="false">
        <hr>
        <div class="row">
          <div class="col-md-1">
            <div class="form-group">
              <label> Código </label>
              <input type="text" class="form-control" [(ngModel)]="prescricao.id" name="id" disabled>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label> Nome* </label>
              <input type="text" class="form-control" [(ngModel)]="prescricao.nome" name="nome"
                     [ngClass]="{'is-invalid': !nome && (nome.dirty || prescriptionForm.submitted)}"
                     #nome="ngModel" minlength="2"
                     maxlength="64"
                     required>
              <label *ngIf="nome.valid && (nome.dirty || prescriptionForm.submitted)"
                     class="error jquery-validation-error small form-text invalid-feedback">Nome é obrigatório</label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Tipo</label>

              <div style="display: flex">
                <div class="custom-control custom-radio" style="padding-right: 15px; padding-top: 5px;">
                  <input type="radio" class="custom-control-input" id="customControlValidation2"
                         required [(ngModel)]="prescricao.tipoPrescricao" [ngModelOptions]="{standalone: true}"
                         [value]="'FARMACOLOGICA'">
                  <label class="custom-control-label" for="customControlValidation2">Farmacológica </label>
                </div>
                <div class="custom-control custom-radio mb-3" style="padding-top: 5px;">
                  <input type="radio" [(ngModel)]="prescricao.tipoPrescricao"
                         class="custom-control-input" id="customControlValidation3"
                         name="radio-stacked" [ngModelOptions]="{standalone: true}"
                         required [value]="'NAO_FARMACOLOGICA'">
                  <label class="custom-control-label" for="customControlValidation3">Não Farmacológica</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="textAreaPrescricao">
                Descrição
                <br>
              </label>
              <textarea type="text" class="form-control" [(ngModel)]="prescricao.descricao" name="descricao"
                        id="textAreaPrescricao" rows="20"
                        [ngClass]="{'is-invalid': !descricao.valid && (descricao.dirty || prescriptionForm.submitted)}"
                        #descricao="ngModel" maxlength="1024" required></textarea>

              <label *ngIf="!descricao.valid && prescriptionForm.submitted"
                     class="error"> Descrição é obrigatória</label>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <button mat-fab color="primary" class="add-button" (click)="save(prescriptionForm)" matTooltip="Salvar">
    <i class="material-icons">check</i></button>
</form>
<ng2-toasty position="top-right"></ng2-toasty>
