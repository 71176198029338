import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {BioimpedanceEvaluation} from '../models/bioimpedance-evaluation';

@Injectable({
  providedIn: 'root'
})
export class BioimpedanceService {

  url = GlobalConstants.apiURL + 'bioimpedancia';

  constructor(private httpClient: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  getPDFUrlById(id: number): string {
    return this.url + '/' + id + '/pdf';
  }

  insert(bioimpedance: BioimpedanceEvaluation): Observable<BioimpedanceEvaluation> {
    return this.httpClient.post<BioimpedanceEvaluation>(this.url, bioimpedance, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

}
