import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';
import {Pharmaceutical} from '../models/pharmaceutical';
import {Auth} from '../models/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = GlobalConstants.apiURL + 'auth';

  urlMe = GlobalConstants.apiURL + 'me';

  constructor(private httpClient: HttpClient) {

  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  getToken(): string {
    return sessionStorage.getItem('jwttoken');
  }

  getSchema(): string {
    return sessionStorage.getItem('schema') || '';
  }

  hasToken(): boolean {
    let token = this.getToken();
    return token != undefined && token.length > 0;
  }

  doLogin(pharmaceutical: Pharmaceutical): Observable<Auth> {
    return this.httpClient.post<Auth>(this.url, pharmaceutical, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAuthPharmaceutical(): Observable<any> {
    console.log(this.urlMe)
    return this.httpClient.get<Pharmaceutical>(this.urlMe, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    if (error.error) {
      errorMessage = error.error.message;

    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  removeToken() {
    sessionStorage.removeItem('jwttoken');
    sessionStorage.removeItem('schema');
  }
}
