import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MedicationManagement} from '../../models/medication-management';
import {PatientService} from '../../services/patient.service';
import {PharmaceuticalService} from '../../services/pharmaceutical.service';
import {MedicineConsumed} from '../../models/medicine-consumed';
import {PatientTabEnum} from '../../enums/patient-tab.enum';
import {ServiceTypeEnum} from '../../enums/service-type.enum';
import {MedicationManagementService} from '../../services/medication-management.service';
import {Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {MedicamentService} from '../../services/medicament.service';
import {IOption} from 'ng-select';
import {AuthService} from '../../services/auth.service';
import {ServiceTypeService} from '../../services/service-type.service';
import {PharmaceuticalServiceService} from "../../services/pharmaceutical-service.service";
import {AgendamentoService} from "../../services/agendamento.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-medication-management',
  templateUrl: './medication-management.component.html',
  styleUrls: ['./medication-management.component.scss']
})
export class MedicationManagementComponent implements OnInit {

  plano;
  totalMes;
  @ViewChild('modalCobrar') modalCobrar;
  @ViewChild('modalRetorno') modalRetorno;
  public maskDateHour = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];

  dataHora;
  medicationManagement = {
    paciente: {},
    farmaceutico: {},
    medicamentos: [],
    servico: {
      fgCobrado: false
    },
  } as MedicationManagement;
  medicineConsumed = {
    medicamento: {}
  } as MedicineConsumed;

  pharmaceuticalOption = null;
  pharmaceuticalListOption: Array<IOption> = [];

  patientOption = null;
  patientListOption: Array<IOption> = [];

  medicamentListOption: Array<IOption> = [];
  pacienteByAgenda;

  constructor(
    private router: Router,
    private toastyService: ToastyService,
    private patientService: PatientService,
    private pharmaceuticalService: PharmaceuticalService,
    private medicamentService: MedicamentService,
    private medicationManagementService: MedicationManagementService,
    private authService: AuthService,
    private serviceTypeService: ServiceTypeService,
    private pharmaceuticalServiceService: PharmaceuticalServiceService,
    private agendaService: AgendamentoService
  ) {
    if (this.router.getCurrentNavigation().extras.state != null) {
      this.pacienteByAgenda = this.router.getCurrentNavigation().extras.state.paciente;
    }
  }

  ngOnInit() {
    this.pharmaceuticalServiceService.countByMonth().subscribe(qtd => {
      this.totalMes = qtd;
    });
    this.patientService.getAllActives().subscribe((patientList) => {
      this.patientListOption = [];
      patientList.forEach((patient) => {
        this.patientListOption.push({
          value: patient.idPaciente + '',
          label: patient.nome
        });
      });
      if (this.pacienteByAgenda != null && this.pacienteByAgenda != undefined) {
        this.patientOption = this.pacienteByAgenda;
      }
    });

    this.authService.getAuthPharmaceutical().subscribe((usuario) => {
      this.plano = usuario.empresa.plano;
      this.pharmaceuticalService.getAll().subscribe((pharmaceuticalList) => {
        this.pharmaceuticalListOption = [];
        pharmaceuticalList.forEach((pharmaceutical) => {
          if (pharmaceutical.cdUsuario === usuario.idUsuario) {

            this.pharmaceuticalOption = pharmaceutical.idFarmaceutico + '';
          }

          this.pharmaceuticalListOption.push({
            value: pharmaceutical.idFarmaceutico + '',
            label: pharmaceutical.nome
          });
        });

      });
    });
    this.medicamentService.getAllActives(true).subscribe((medicamentList) => {
      this.medicamentListOption = [];
      medicamentList.forEach((medicament) => {
        this.medicamentListOption.push({
          value: medicament.idMedicamento + '',
          label: medicament.descricao
        });
      });
    });

    this.serviceTypeService.getOne(ServiceTypeEnum.GESTAO_MEDICACAO).subscribe((tpServico) => {
      this.medicationManagement.servico.vlCobrado = tpServico.vlServico;

      this.medicationManagement.servico.fgSugerirRetorno = tpServico.fgSugerirRetorno;
      this.medicationManagement.servico.qtdDiasRetorno = tpServico.qtdDiasRetorno;
    });
  }

  private showToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.success({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  private showErrorToast(text: string) {
    this.toastyService.clearAll();
    this.toastyService.error({
      title: undefined,
      msg: text,
      showClose: true,
      timeout: 3000,
    });
  }

  save(myForm: NgForm) {
    if (myForm.valid) {
      if (this.medicationManagement.medicamentos.length >= 1) {
        if (!this.pharmaceuticalOption) {
          this.showErrorToast('Informe um farmacêutico');

        } else if (!this.patientOption) {
          this.showErrorToast('Informe um paciente');

        } else {
          this.medicationManagement.paciente.idPaciente = Number(this.patientOption);
          this.medicationManagement.farmaceutico.idFarmaceutico = Number(this.pharmaceuticalOption);

          this.medicationManagementService.insert(this.medicationManagement).subscribe((medicationManagement) => {
            this.showToast('Gestão de medicamento cadastrada com sucesso');
            this.modalCobrar.hide();

            this.medicationManagement.idGestaoMedicacao = medicationManagement.idGestaoMedicacao;
            if (this.medicationManagement.servico.fgSugerirRetorno) {

              var today = new Date();
              var tomorrow = new Date();
              tomorrow.setDate(today.getDate() + this.medicationManagement.servico.qtdDiasRetorno);
              this.dataHora = formatDate(tomorrow, 'dd/MM/yyy hh:mm', 'pt-BR');
              this.modalRetorno.show({backdrop: 'static'});
            } else {
              this.redirectPaciente();
            }

          });
        }

      } else {
        this.showErrorToast('Informe no mínimo um medicamento');
      }
    }
  }

  onAddMedication(medicineConsumedForm: NgForm) {
    if (medicineConsumedForm.valid) {
      if (!this.medicineConsumed.medicamento.idMedicamento) {
        this.showErrorToast('Informe um medicamento');

      } else if (!this.isStringOk(this.medicineConsumed.manhaAntes)
        && !this.isStringOk(this.medicineConsumed.manhaDepois)
        && !this.isStringOk(this.medicineConsumed.almocoAntes)
        && !this.isStringOk(this.medicineConsumed.almocoDepois)
        && !this.isStringOk(this.medicineConsumed.tardeAntes)
        && !this.isStringOk(this.medicineConsumed.tardeDepois)
        && !this.isStringOk(this.medicineConsumed.noiteAntes)
        && !this.isStringOk(this.medicineConsumed.noiteDepois)
      ) {
        this.showErrorToast('Informe pelo menos um campo');

      } else {
        this.medicamentService.getById(this.medicineConsumed.medicamento.idMedicamento).subscribe((medicament) => {
          this.medicineConsumed.idMedicamentoConsumido = Math.random() * -1;
          this.medicineConsumed.medicamento = medicament;
          this.medicationManagement.medicamentos.push(this.medicineConsumed);
          this.medicineConsumed = {
            medicamento: {}
          } as MedicineConsumed;
          medicineConsumedForm.resetForm();
        });
      }
    }
  }

  isStringOk(text: string) {
    return text !== null && text !== undefined && text.trim() != '';
  }

  onRemoveMedication(id: number) {
    let medicineConsumedList = [];
    this.medicationManagement.medicamentos.forEach((medicamento) => {
      if (medicamento.idMedicamentoConsumido != id) {
        medicineConsumedList.push(medicamento);
      }
    });
    this.medicationManagement.medicamentos = medicineConsumedList;
  }

  validateOpen(form) {
    if (Number(this.totalMes) >= Number(this.plano.qtdServicos)) {
      this.showErrorToast('A quantidade de serviços permitidos por mês do seu plano foi atingida');
      return;
    }
    if (form.valid) {

      if (this.medicationManagement.medicamentos.length < 1) {
        this.showErrorToast('Informe no mínimo um medicamento');
        return;
      }
      this.modalCobrar.show();
    }
  }

  cancel() {
    this.modalCobrar.hide();
  }

  private redirectPaciente() {
    this.router.navigate(['/paciente/form/edit'], {
      queryParams: {
        id: this.medicationManagement.paciente.idPaciente,
        pdf: this.medicationManagement.idGestaoMedicacao,
        tab: PatientTabEnum.SERVICO,
        servico: ServiceTypeEnum.GESTAO_MEDICACAO,
      }
    }).then();
  }


  cancelRetorno() {
    this.modalRetorno.hide();
    this.redirectPaciente();
  }

  agendarRetorno() {
    console.log('retorno')
    this.agendaService.insertByReagendamento({
      idFarmaceutico: this.medicationManagement.farmaceutico.idFarmaceutico,
      idPaciente: this.medicationManagement.paciente.idPaciente,
      tpServico: ServiceTypeEnum.GESTAO_MEDICACAO,
      data: this.dataHora
    }).subscribe(ret => {
      console.log('sucesso ao criar agendamento futuro')
      this.showToast("Sucesso ao criar agendamento futuro")
      this.modalRetorno.hide();
      this.redirectPaciente();
    })
  }
}
